import React from "react";
import domain from "../../Domain";
import SimpleTable from "../tables/SimpleTable";
import {formatLot, formatUSDate, formatUSDateWithSlash, numberWithCommas, parseDate} from "../../util/FormatUtil";
import {Column} from "../tables/TableBase";
import {buildDisplayString} from "../tables/TableUtil";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import FBGrid from "./ProductionAnalysis";

interface QCReportState {
   GridData;
   show_loading;
    direction: string;
}

export default class QCReport extends React.Component<any, QCReportState>{
    constructor(props) {
        super(props);
        this.state = {
            GridData: [],
            show_loading: true,
            direction: 'asc',
        }


        this.onExport = this.onExport.bind(this);
    }

    componentDidMount(): void {
        fetch(domain + "/api/qcreport/report").then(res => res.json())
            .then(data => {
                //console.log(data);
                this.setState({GridData: data})
            });

    }

    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "qcreport.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/qcreport/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send();
        })
    }

    sortTable(hdr, tableData) {
        let rows = tableData;
        //console.log(JSON.stringify(rows))
        if (rows != null) {
            rows.sort((a, b) => {
                let first = (typeof a[hdr] === 'string') ? a[hdr].toLowerCase() : a[hdr];
                let second = (typeof b[hdr] === 'string') ? b[hdr].toLowerCase() : b[hdr];
                if (first < second) {
                    return this.state.direction == 'asc' ? -1 : 1;
                }
                if (first > second) {
                    return this.state.direction == 'asc' ? 1 : -1;
                }
                return 0;
            });
            this.setState({GridData: rows})
            if(this.state.direction == 'asc') {
                this.setState({direction: 'desc'})
            }
            else if(this.state.direction == 'desc'){
                this.setState(({direction: 'asc'}))
            }
        }
    }


    render() {

        let tableData = JSON.parse(JSON.stringify(this.state.GridData));
        //console.log("tableData: " + JSON.stringify(tableData))
        return (
            <div className={"container-fluid tableFixHead"}>
                <div className={'row pt-4'}>
                    <div className={"col-lg-12"}>
                        <div className="card shadow-lg primary-black-bg white">
                            <div className="card-header green-border ">
                                <h4 className="d-inline-block">QC Report</h4>
                                <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                        onClick={this.onExport}>Export to Excel
                                </button>
                            </div>

                            <div className={"card-body p-0 m-0"}>
                                <SimpleTable table_data={tableData} columns={
                                    [
                                        {
                                            label: "Job",
                                            key: "Job",
                                            headerOnClick: () => this.sortTable('Job', tableData)
                                        },
                                        {
                                            label: "Item",
                                            key: "Item",
                                            headerOnClick: () => this.sortTable('Item', tableData)
                                        },
                                        {
                                            label: "Lot",
                                            key: "Lot",
                                            headerOnClick: () => this.sortTable('Lot', tableData)
                                        },
                                        {
                                            label: "To Testing",
                                            key: "To Testing",
                                            headerOnClick: () => this.sortTable('To Testing', tableData)
                                        },
                                        {
                                            label: "To Inspection",
                                            key: "To Inspection",
                                            headerOnClick: () => this.sortTable('To Inspection', tableData)
                                        },
                                        {
                                            label: "Anticipated End Date",
                                            key: "Anticipated End Date",
                                            formatFunc:parseDate,
                                            headerOnClick: () => this.sortTable('Anticipated End Date', tableData)
                                        },
                                        {
                                            label: "Current Op",
                                            key: "Current Op",
                                            headerOnClick: () => this.sortTable('Current Op', tableData)
                                        },
                                        {
                                            label: "Current Assigned",
                                            key: "Current Assigned",
                                            headerOnClick: () => this.sortTable('Current Assigned', tableData)
                                        },
                                        {
                                            label: "Next Op",
                                            key: "Next Op",
                                            headerOnClick: () => this.sortTable('Next Op', tableData)
                                        },
                                        {
                                            label: "Next Assigned",
                                            key: "Next Assigned",
                                            headerOnClick: () => this.sortTable('Next Assigned', tableData)
                                        },
                                        {
                                            label: "Qty in Progress",
                                            key: "Qty in Progress",
                                            headerOnClick: () => this.sortTable('Qty in Progress', tableData)
                                        },
                                        {
                                            label: "FG Stock",
                                            key: "FG Stock",
                                            headerOnClick: () => this.sortTable('FG Stock', tableData)
                                        },
                                        {
                                            label: "FG Allocated",
                                            key: "FG Allocated",
                                            headerOnClick: () => this.sortTable('FG Allocated', tableData)
                                        },
                                        {
                                            label: "FG Available",
                                            key: "FG Available",
                                            headerOnClick: () => this.sortTable('FG Available', tableData)
                                        },
                                        {
                                            label: "WIP Stock",
                                            key: "WIP Stock",
                                            headerOnClick: () => this.sortTable('WIP Stock', tableData)
                                        },
                                        {
                                            label: "WIP Allocated",
                                            key: "WIP Allocated",
                                            headerOnClick: () => this.sortTable('WIP Allocated', tableData)
                                        },
                                        {
                                            label: "WIP Available",
                                            key: "WIP Available",
                                            headerOnClick: () => this.sortTable('WIP Available', tableData)
                                        },
                                        {
                                            label: "Months on Hand",
                                            key: "Months on Hand",
                                            headerOnClick: () => this.sortTable('Months on Hand', tableData)
                                        },
                                        {
                                            label: "Back Order",
                                            key: "Back Order",
                                            headerOnClick: () => this.sortTable('Back Order', tableData)
                                        }
                                    ]
                                } rowBuilder={(table_data, columns) => {
                                    return (table_data ? table_data.map((val: any, val_index: number) => {

                                        let className = "m-0 p-0 " + (((val_index - 1) % 2) === 0 ? "dark-row-override-even" : "dark-row-override-odd");

                                        return (<React.Fragment>
                                            <tr className={className}>{

                                                columns.map((col: Column, index) => {
                                                    if (index === 0) {
                                                        return (
                                                            <td>

                                                                {buildDisplayString(col, val)}
                                                            </td>
                                                        )
                                                    }
                                                    return (<td>{buildDisplayString(col, val)}</td>)
                                                })
                                            }</tr>
                                        </React.Fragment>)
                                    }) : null);
                                }}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}