import React, {useEffect} from "react";
import Table from "../tables/Table";
import {formatMoney, numberWithCommas, parseDate} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import domain from "../../Domain";
import SimpleTable from "../tables/SimpleTable";
import cookie from 'react-cookie';
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import {onAuthStateChanged, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult} from "firebase/auth";
import {auth} from "../../FirebaseConfig";

interface ExpenseReportState {
    table_data: any[]
    show_loading: boolean
    modal_data: any
    password?: string
    filter?: {}
    employees: any[]
}

export default class ExpenseReport extends React.Component<any, ExpenseReportState> {

    constructor(props) {
        super(props);
        this.state = {show_loading: false, table_data: [], modal_data: null, employees: []};
        this.onExport = this.onExport.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    // loadExpenses() {
    //     fetch(domain + "/api/concur/expense").then(res => res.json())
    //         .then(data => this.setState({
    //             table_data: data,
    //             showLoading: false
    //         }))
    //         .catch(e => {
    //             this.setState({showLoading: false});
    //         });
    // }


    private async onSubmit(e: React.MouseEvent<HTMLButtonElement>) {
        this.setState({show_loading: true}, async () => {
            let response = await fetch(domain + "/api/concur/expense", {
                method: 'POST',
                body: JSON.stringify({filter: this.state.filter, password: cookie.load("password")}),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            let obj: any = await response.json();
            // ts-ignore
            this.setState({table_data: obj.expenses, show_loading: false})
        })
    }

    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "expense_report.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/concur/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify({filter: this.state.filter, password: cookie.load('password')}));
        })
    }


    componentDidMount(): void {

        onAuthStateChanged(auth, (user) => {
            if (user) {
                let uId = user.providerData[0].uid;

                console.log(user.providerData[0].uid)

                fetch(domain + "/api/firebase/checkGroupConcur", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({uId: uId})
                }).then(res => res.json())
                    .then(data => {
                            console.log("105" + JSON.stringify(data))
                            if (data.CanView) {
                                fetch(domain + "/api/concur/expense").then(res => res.json())
                                    .then(data => {
                                            console.log(data)
                                            if (data.expenses) {
                                                this.setState({
                                                    table_data: data.expenses,
                                                    show_loading: false
                                                }, () => {
                                                    console.log(this.state.table_data)
                                                })

                                            } else if (!data.authenticated && data.redirect) {
                                                alert("Please sign in")
                                                window.location.replace('/api/microsoft/signin')
                                            } else if (!data.authenticated && !data.redirect) {
                                                alert("You do not have permission to view this.")
                                            }
                                        }
                                    ).catch(e => {
                                    this.setState({show_loading: false});
                                    console.log(e)
                                    console.log("Catch block");
                                });
                            } else {
                                alert("You do not have access to this page!")
                            }
                        }
                    ).catch(e => {
                    this.setState({show_loading: false});
                    console.log(e)
                    console.log("Catch block");
                });
            } else {
                console.log("No user found")
                // User is not signed in.
                // ...
            }
        });

        fetch(domain + "/api/employees/users").then(res => res.json())
            .then(data => {
                console.log("Data", data);
                this.setState({employees: data})
            })
            .catch(console.log)

    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // console.log(this.state.table_data);
        return (
            <React.Fragment>
                <Overlay showLoading={this.state.show_loading}/>
                <div
                    className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                    <div className={"row"}>
                        <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Expense Filtering</h4>
                                </div>
                                <div className={"card-body"}>
                                    <FilterCard
                                        fields={[
                                            {key: "start_date", label: "Start Date", type: FIELD_TYPE.DATE},
                                            {key: "end_date", label: "End Date", type: FIELD_TYPE.DATE},
                                            {
                                                key: "Owner",
                                                label: "Owner Name",
                                                type: FIELD_TYPE.SELECT,
                                                options: this.state.employees,
                                                isMapped: true,
                                                isMulti: false
                                            },

                                        ]}
                                        filterChanged={(filter) => {
                                            console.log("Filter changed", filter)
                                            this.setState({filter: filter})
                                        }}
                                    />
                                </div>
                                <div className="card-footer green-border-top">
                                    <button className={"btn btn-outline-info"} onClick={(e) => this.onSubmit(e)}>Apply
                                    </button>
                                    <button className={"btn btn-outline-danger float-right"}
                                            onClick={() => window.location.reload()}>Clear
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className={"col-12 m-0 pt-3 tableFixHead min-vh-100"}>
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4 className="d-inline-block">Approved Expense Reports</h4>
                                    <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                            onClick={this.onExport}>Export to Excel
                                    </button>
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                    <SimpleTable columns={[
                                        {
                                            label: "Name", key: "Name", onClick: (expenseReport) => {
                                                this.setState({show_loading: true})
                                                fetch(domain + "/api/concur/details", {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        expense: expenseReport,
                                                        password: cookie.load('password')
                                                    }),
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                }).then(res => res.json()).then(details => {
                                                    console.log(details)
                                                    details.ExpenseEntriesList.type = "expense"
                                                    this.setState({
                                                        modal_data: details.ExpenseEntriesList, show_loading: false
                                                    }, () => this.props.open_modal(details.ExpenseEntriesList))
                                                }).catch(e => {
                                                    this.setState({show_loading: false})
                                                })

                                            }
                                        },
                                        {label: "Owner", key: "OwnerName"},
                                        {label: "Submitted", key: "SubmitDate", formatFunc: parseDate},
                                        {label: "Total", key: "Total", formatFunc: formatMoney},
                                    ]} table_data={this.state.table_data}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}