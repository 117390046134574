export function getCurrentFYStart():string{
    return "" + getCurrentFYStartYear() + "-07-01";
}

export function getCurrentFYStartYear():number{
    let today = new Date();
    let year = today.getFullYear()
    if(today.getMonth() < 6) // before july
        year -= 1;
    return year;
}
export function getCurrentFYEndYear():number{
    let today = new Date();
    let year = today.getFullYear()
    if(today.getMonth() >= 6) // July or beyond
        year += 1;
    return year;
}

export function getCurrentFYEnd():string{
    return "" + getCurrentFYEndYear() + "-06-30";
}

export function todayLastFY():string{
    let today = new Date();
    let thisYear = today.getFullYear();
    return thisYear - 1 + "-" + (today.getMonth() + 1).toString().padStart(2, '0') + "-" + today.getDate().toString().padStart(2, '0')
}