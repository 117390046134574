import React from "react";
import Select from "react-select";

import QuerySearch from "./query_search/QuerySearch";
export const FIELD_TYPE = {
    SELECT:"select",
    DATE:"date",
    DATETIME: "datetime",
    TEXT:"text",
    SEARCH_RESULT:"search_result",
    TEXT_AREA:"text_area",
    RADIO: "radio"
}
export interface ReactSelect{
    label:string
    value:any
}

interface FilterPart{
    key:string
    label:string
    default?:any
    options?:ReactSelect[] | any[]
    isMapped?:boolean
    placeholder?:string
    isSearchable?:boolean
    isMulti?:boolean
    noOpMessage?:string
    result_types?:string[]
    rows?:number
    readOnly? :boolean
    type:string
    callback?:(any)=>void
}

interface FilterProps{
    fields:FilterPart[]
    filterChanged:(FilterState:any)=>void
}
interface FilterState{

}
export default class FilterCard extends React.Component<FilterProps,FilterState>{

    constructor(props){
        super(props)
        this.state = {};
        let fields = this.props.fields ? this.props.fields : [];
        let state = {};
        console.log("Fields, ", fields)
        fields.forEach(val =>{
            if(val.default) {
                state[val.key] = val.default
                console.log("Setting default", state[val.key], val.default)
            }
        });
        this.state = state;
        this.props.filterChanged(this.state)
    }

    select_changed(data, e){
        console.log("Select Changed", e)
        if(!e) {
            e = [];
            this.filterChanged(data.key, e)
            return;
        }

        e = Array.isArray(e) ? e.map(b => b.value) : [e.value]
        this.filterChanged(data.key, e)
    }

    filterChanged(key, e){

        let state = {}
        state[key] = e;
        this.setState(state,() =>{
            this.props.filterChanged(this.state)
            this.props.fields.forEach(f =>{
                if(f.key === key && f.callback)
                    f.callback(e);
            })
        })
    }

    buildText(data:FilterPart){
        console.log("Building TEXT")
        return(
            <div className="form-group row">
                <label htmlFor={data.key} className="col-sm-4 col-form-label">{data.label}</label>
                <div className="col-sm-8 p-0 m-0">

                    <input type="search" className="form-control" id={data.key} placeholder={data.placeholder}
                           value={this.state[data.key]}
                           readOnly={data.readOnly}
                           autoComplete="off"
                    onChange={(e) => this.filterChanged(data.key, e.target.value)}/>
                </div>
            </div>
        )
    }

    buildTextArea(data:FilterPart){
        return <div className={"form-group row"}>
            <label htmlFor={data.key} className="col-sm-4 col-form-label">{data.label}</label>
            <div className="col-sm-8 p-0 m-0">
                <textarea rows={data.rows} className="form-control" id={data.key} placeholder={data.placeholder}
                          defaultValue={data.default}
                          readOnly={data.readOnly}
                       onChange={(e) => this.filterChanged(data.key, e.target.value)}/>
            </div>

        </div>
    }


    buildSelect(data:FilterPart){
        // @ts-ignore
        let options = data.options ? (data.isMapped ? data.options :  data.options.map((value, index) => {
            return (
                {label:value, value:value});
        })) : [];


        return(
            <div className="form-group row">
                <label  className="col-sm-4 col-form-label">{data.label}</label>
                <div className="col-sm-8  p-0 m-0">
                    <Select
                        isSearchable={data.isSearchable ? data.isSearchable : true}
                        placeholder={data.placeholder}
                        noOptionsMessage={()=> data.noOpMessage ? data.noOpMessage : "Failed to load."}
                        defaultValue={data.default}
                        isMulti={data.isMulti}
                        onChange={(e)=> this.select_changed(data, e)}
                        className={"state_select"}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    buildSearchResult(val:FilterPart) {
        return ( <div className="form-group row">
            <label className="col-sm-4 col-form-label">{val.label}</label>
            <div className="col-sm-8  p-0 m-0">
                <QuerySearch default={val.default} resultTypes={val.result_types} isMulti={val.isMulti} onChange={(data) =>this.filterChanged(val.key, data)}/>
            </div>
        </div>);
    }

    buildDate(val){
        return(<div className="form-group row">
        <label className="col-sm-4 col-form-label">{val.label}</label>
        <div className="col-sm-8  p-0 m-0">
            <input type="date" name="start" max="3000-12-31"
                   value={this.state[val.key]}
        min="1000-01-01" className="form-control datepicker" onChange={(e)=> this.filterChanged(val.key, e.target.value)}/>
        </div></div>)
    }

    buildDateTime(val){
        return(<div className="form-group row">
            <label className="col-sm-4 col-form-label">{val.label}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type="datetime-local" name="start" max="3000-12-31 00:00"
                       value={this.state[val.key]}
                       min="1000-01-01 00:00" className="form-control datepicker" onChange={(e)=> this.filterChanged(val.key, e.target.value)}/>
            </div></div>)
    }

    buildRadio(val){
        return(<div className="form-group row">
            <label className="col-sm-4 col-form-label">{val.label}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type={"radio"} value={val.options? val.options[0] : "true"} name={"bool"}
                       onChange={(e) => this.filterChanged(val.key, e.target.value)} /> {val.options? val.options[0] : "True"}
                <input type={"radio"} value={val.options? val.options[1] : "false"} name={"bool"} style={{marginLeft: 20}}
                       onChange={(e) => this.filterChanged(val.key, e.target.value)} /> {val.options? val.options[1] : "False"}
            </div></div>)
    }


    render() {
        let fields = this.props.fields ? this.props.fields : [];
        return (
            <form id={'filterForm'} autoComplete={"off"}>

                {fields.map((val, index) =>{


                    if(val.type === FIELD_TYPE.SELECT){
                        return this.buildSelect(val)
                    }else if(val.type === FIELD_TYPE.SEARCH_RESULT){
                        return this.buildSearchResult(val);
                    }else if(val.type === FIELD_TYPE.DATE){
                        return this.buildDate(val)
                    }else if(val.type === FIELD_TYPE.DATETIME){
                        return this.buildDateTime(val)
                    }else if(val.type === FIELD_TYPE.TEXT){
                        return this.buildText(val)
                    }else if(val.type === FIELD_TYPE.TEXT_AREA){
                        return this.buildTextArea(val);
                    }else if(val.type === FIELD_TYPE.RADIO){
                        return this.buildRadio(val);
                    }
                })}

            </form>
        )
    }
}


