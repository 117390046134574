import React from 'react';
import domain from "../../Domain";
import {numberWithCommas} from "../../util/FormatUtil"
import OpenOrder from "../../types/OpenOrder";
import Overlay from "../Overlay";

interface OpenOrdersState{
    openOrders:OpenOrder[]
    show_loading
    filter:{
        countries:string[]
        products
        start_date
        end_date
    }
}

export default class OpenOrders extends React.Component<any, OpenOrdersState>{
    constructor(props) {
        super(props);
        let startDate = new Date();
        let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
        let month = (startDate.getMonth() + 1).toString();
        this.state = {
            openOrders:[],
            show_loading: true,
            filter: {products: [],  countries: [], start_date:startDate.getFullYear() + "-"+month+"-"+"01", end_date:endDate.getFullYear().toString() +"-" + month + "-" + endDate.getDate().toString()},
        }
        this.onExport = this.onExport.bind(this);
    }

    componentDidMount() {
        this.setState({show_loading: true})
        fetch(domain+"/api/orders/open").then(res => res.json())
            .then(data => this.setState({openOrders: data}, () => {
                this.setState({show_loading: false})
            }))
            .catch(console.log)
    }

    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "open_order_report.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/orders/openexport");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }

    render() {
        console.log(this.state.openOrders)
        return(
            <React.Fragment>
                <Overlay showLoading={this.state.show_loading}/>
                <div className={"  tableFixHead min-vh-100"}>
                    <div className={"card shadow-lg primary-black-bg white"}>
                        <div className="card-header green-border ">
                            <h4 className="d-inline-block">Open Orders</h4>
                            <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                    onClick={this.onExport}>Export to Excel
                            </button>
                        </div>
                        <div className={"card-body table-responsive-lg  p-0 m-0"}>
                            <table className={"skip-stripe table table-dark table-striped p-0 m-0"}>
                                <thead>
                                <tr>
                                    <th scope="col">Order #</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Sch. Fulfillment Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Order Date</th>


                                </tr>
                                </thead>
                                <tbody>
                                {this.state.openOrders.map((order, index) =>{
                                    let reason = order.Hold === 1 ? (<p>Reason: {order.Reason}</p>) : null;
                                    let displayDate = order["Sch. Fulfillment"] ? order["Sch. Fulfillment"].slice(0,10).split('-') : "";
                                    let orderDate = order["Sch. Fulfillment"] ? new Date(Date.parse(order["Sch. Fulfillment"].slice(0,10).split('-'))) : new Date(0);
                                    let today = new Date(Date.parse(new Date().toString()));
                                    let custOrderDate;

                                    if(order["Order Date"] === null || order["Order Date"] === "null"){
                                        custOrderDate = "NULL"
                                    }
                                    else{
                                        custOrderDate = order["Order Date"].replace('Z', '').replace('T', ' ');
                                    }



                                    console.log(custOrderDate)



                                    today.setHours(0,0,0,0)
                                    let isBackordered =  orderDate.getTime() < today.getTime();
                                    let backOrderedColor = "";
                                    if(isBackordered && (reason !== null || order.notes !== "")){backOrderedColor=" table-yellow"}else if(isBackordered && reason === null){backOrderedColor = " table-red"}
                                    displayDate = displayDate === "" ? "" : displayDate[1] + '-' + displayDate[2] + '-' + displayDate[0];
                                    return (
                                        <React.Fragment>
                                            <tr className={"collapsed " + (((index - 1)  % 2)  === 0 ? "dark-row-override-even" : "dark-row-override-odd")
                                                + backOrderedColor}>
                                                <td><a data-toggle={"collapse"} role={"button"} href={"#" + order.co_num}>{order.co_num}</a></td>
                                                <td><a href="#!" onClick={() => this.props.open_modal(order.customer)}>{order.customer ? order.customer.name : ""}</a></td>
                                                <td>{displayDate}</td>
                                                <td>{order.Status}</td>
                                                <td>$ {numberWithCommas(order.Price)}</td>
                                                <td>{custOrderDate}</td>

                                            </tr>
                                            <tr className={"m-0 p-0 collapse"} id={order.co_num}>
                                                <td className="p-0 m-0" colSpan={5}>
                                                    <div className="w-100" >
                                                        {reason}
                                                        <p>Notes: {order.notes}</p>
                                                        <table className={"p-0 m-0 w-50"} style={{ textAlign: 'center' }}>
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">Product</th>
                                                                <th scope="col">Qty</th>
                                                                <th scope="col">Unit Price</th>
                                                                <th scope="col">Total Price</th>
                                                                <th scope="col">Sellable</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>

                                                            {order.items ? order.items.map(item =>{
                                                                return (
                                                                    <tr>
                                                                        <td>{item.Product}</td>
                                                                        <td>{item.QTY}</td>
                                                                        <td>$ {numberWithCommas(item["Unit Price"])}</td>
                                                                        <td>$ {numberWithCommas(item["Total Price"])}</td>
                                                                        <td>{numberWithCommas(item.Sellable)}</td>
                                                                        <td>{item.Status}</td>
                                                                    </tr>
                                                                )
                                                            }) : null}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

