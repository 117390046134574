import React from "react";
import {Vaccine} from "../../types/immylabs/VaccineType";


class WeatherPanel extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }

    public static display(){
        window["$"]("#weather_panel").modal('show');
    }

    render() {
        let weatherData = this.props.data;
        console.log(weatherData)

        return (
            <React.Fragment>

                {Object.keys(weatherData).length > 0 ?

                    <div className="modal fade form_modal" id={"weather_panel"} tabIndex={-1} role="dialog"
                         aria-labelledby="result_modal_label"
                         aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="result_modal_label">{"Weather Forecast"}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body ">
                                    <div className={"card shadow-sm white"} style={{backgroundColor: '#87CEEB'}}>
                                        <div className="card-header">
                                            <div className={"card-body"}>
                                                <p><b><h4>{weatherData.city.name}, {weatherData.city.country} </h4></b>
                                                </p>
                                                <div className="d-flex justify-content-around flex-row">
                                                    <div className="p-4" style={{fontSize: '18px'}}>
                                                        <p>Today <img
                                                            src={"https://openweathermap.org/img/w/" + weatherData.list[0].weather[0].icon + ".png"}/>
                                                        </p>
                                                        <p>
                                                            Min: {weatherData.list[0].temp.min},
                                                            Max: {weatherData.list[0].temp.max}</p>
                                                        <p> Weather: {weatherData.list[0].weather[0].main} ({weatherData.list[0].weather[0].description}) </p>
                                                    </div>

                                                    <div className="p-4" style={{fontSize: '18px'}}>
                                                        <p>Tomorrow <img
                                                            src={"https://openweathermap.org/img/w/" + weatherData.list[1].weather[0].icon + ".png"}/>
                                                        </p>
                                                        <p>
                                                            Min: {weatherData.list[1].temp.min},
                                                            Max: {weatherData.list[1].temp.max}</p>
                                                        <p> Weather: {weatherData.list[1].weather[0].main} ({weatherData.list[1].weather[0].description}) </p>

                                                    </div>
                                                    <div className="p-4" style={{fontSize: '18px'}}>
                                                        <p>Next Day <img
                                                            src={"https://openweathermap.org/img/w/" + weatherData.list[2].weather[0].icon + ".png"}/>
                                                        </p>
                                                        <p>
                                                            Min: {weatherData.list[2].temp.min},
                                                            Max: {weatherData.list[2].temp.max}</p>
                                                        <p> Weather: {weatherData.list[2].weather[0].main} ({weatherData.list[2].weather[0].description}) </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : <div></div>}
            </React.Fragment>
        )
    }
}


export default WeatherPanel;
