import React from "react";
import {numberWithCommas, formatMoney, parseDate, toPercentage, getPageOptions, toLocalDate} from "../../util/FormatUtil";
import $ from 'jquery';
import Select from 'react-select'
import domain from "../../Domain"
import Table from "../tables/Table"
import {sortByValue, getAllItems} from "../../util/ProductMapUtils";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import Overlay from "../Overlay";
import {HorizontalBar} from "react-chartjs-2";
import {HorizontalTable} from "../tables/HorizontalTable";
import SimpleTable from "../tables/SimpleTable";
import Result_Modal from "../modals/Modals";
interface OrderState{
    filter:{
        countries:string[]
        products
        start_date
        end_date
    }
    fyOptions:ReactSelect[]
    modal_data
    table_data
    show_loading
    sale_totals: {
        total_shipped_sales: number,
        total_shipped_margin: number,
        total_shipped_cost: number,
        current_month_sales: number,
        shipping_total:number
    },
    location_filters: {
        states: {country:string, state:string, description:string}[],
        countries: {country:string}[]
    },
    product_data
    family_code:ReactSelect[]
    items:ReactSelect[]
    sales_reps:ReactSelect[]
    page_options:ReactSelect[]
    start_date:string,
    end_date:string
    selected_page?
}


class Orders extends React.Component<any, OrderState> {

    constructor(props) {
        super(props);
        let startDate = new Date();
        let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
        let month = (startDate.getMonth() + 1).toString();
        month = month.length === 1 ? "0"+month : month;

        let date = new Date();
        let fyOptions = []
        for(let i = date.getFullYear(); i > 2013; i--){
            fyOptions.push({label:"FY" + i, value:i})
        }
       // dateString = dateString
        this.state = {
            fyOptions:fyOptions,
            modal_data: null,
            table_data: null,
            show_loading: false,
            sale_totals: {
                total_shipped_sales: 0,
                total_shipped_margin: 0,
                total_shipped_cost: 0,
                current_month_sales: 0,
                shipping_total:0
            },
            location_filters: {
                states: [],
                countries: []
            },
            product_data: [],
            items: [],
            family_code: [] /*[
                {label: "MISC", value: "MISC"},
                {label: "S&H", value: "S&H"},
                {label:"LFA", value:"LFA"},
                {label:"EIA", value:"EIA"},
                {label:"Myco DDR", value:"Myco DDR"},
                {label:"ID Plates", value: "ID Plates"},
                {label:"ID Reagent", value: "ID Reagent"},
                {label:"CF Reagent", value: "CF Reagent"},
                {label:"Latex", value: "Latex"},
                {label:"SQUARE", value: "SQUARE"}
            ]*/,
            sales_reps: [
                {label: "Demy Belinskey", value: "Belinskey, Demy"},
                {label: "Kylie Smith", value: "Smith, Kylie"},
                {label:"Keegan Nees", value:"Nees, Keegan"},
                {label:"Hannah Garcia", value:"Garcia, Hannah"},
                {label:"Shadrack Were", value:"Were, Shadrack"},
                {label:"Diego Caceres Contreras", value: "Caceres Contreras, Diego"},
                {label:"Robyn Crow", value: "Crow, Robyn O."},
                {label:"Hana Bahounkova", value: "Bahounkova, Hana"},
                {label:"Melissa Copas", value: "Copas, Melissa"},
                {label:"Phaninder Koppula", value: "Koppula, Phaninder A."}
            ],
            filter: {products: [],  countries: [], start_date:startDate.getFullYear() + "-"+month+"-"+"01", end_date:endDate.getFullYear().toString() +"-" + month + "-" + endDate.getDate().toString()},
            page_options: [{value: "1", label: "1"}],
            start_date:startDate.getFullYear() + "-"+month+"-"+"01",
            end_date:endDate.getFullYear().toString() +"-" + month + "-" + endDate.getDate().toString()
        }

        // this.pageRef = React.createRef();
        this.htmlEscape = this.htmlEscape.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onExport = this.onExport.bind(this);
        this.shortcutSelected = this.shortcutSelected.bind(this);

    }

    componentDidMount() {
        this.queryOrders(this.state.filter, 1)

        fetch(domain + "/api/orders/productLines").then(res => res.json())
            .then(data =>
            //    console.log(data)
                this.setState({
                        family_code: data
                }, () => {
                    console.log(this.state.family_code)
                }))
            .catch(e => alert("Failed to fetch product lines"));

        fetch(domain + "/api/orders/lineItem").then(res => res.json())
            .then(data =>
             //       console.log(data)
                this.setState({
                    items: data
                }, () => {
                    console.log(this.state.items)
                }))
            .catch(e => alert("Failed to fetch items"));


        fetch(domain + "/api/location/all").then(res => res.json())
            .then(data => this.setState({
                location_filters: {
                    states: data.states,
                    countries: data.countries
                },
            }))
            .catch(e => alert("Failed to fetch locations"));
    }


    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "order_report.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/orders/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }


    htmlEscape(value) {
        return (value).replace(/[^a-z0-9]/i, '_');
    }


    onSubmit(e) {
        this.queryOrders(this.state.filter, 1);
        // this.setState({selected_page:{value: 1, label: 1}})
        // this.pageRef.current.setState({value: 1, label: 1})
        return false;
    }

    queryOrders(fil, page) {

        const filter = JSON.parse(JSON.stringify(fil));

        filter.customers =  filter.customers ? filter.customers.map(val => val.value ? val.value : val) : [];

        this.setState({show_loading: true}, ()=>{

            fetch(domain + "/api/orders/filter?page=" + page, {
                method: 'POST',
                body: JSON.stringify(filter),
                headers: {
                    'Content-Type': 'application/json'
                }}).then(res=>res.json()).then(data=>{
                console.log(data.product_data)
                this.setState({
                    table_data: data.table_data,
                    sale_totals: {
                        total_shipped_sales: data.total_shipped_sales,
                        total_shipped_cost: data.total_shipped_cost,
                        total_shipped_margin: data.total_shipped_margin,
                        shipping_total:data.shipping_total
                    },
                    product_data: data.product_data,
                    page_options: getPageOptions(data.num_pages),
                    start_date:filter.start_date,
                    end_date:filter.end_date,
                    filter:filter,
                    show_loading:false
                } as OrderState)
            }).catch(e => {
                console.error(e);
                this.setState({show_loading:false});
            })
        })
    }


    getItemTotals(product_data, product, item) {
        return product_data[product][item]
    }
    public updateShowModal(data){
        this.setState({
            modal_data: data,
        }, () => {
            window['$']("#orderModal").modal('show');
        })
    }
    render() {
        console.log(this.state.table_data)
        console.log("Order state", this.state.filter)
        let countries = this.state.location_filters.countries.map((value) => value.country);
        let states = [];
        if (this.state.filter.countries)
            this.state.location_filters.states.forEach(state => {
                this.state.filter.countries.some(c => {
                    if (state.country === c) {
                        states.push({label: state.description, value: state.state});
                        return true;
                    }
                })
            });

      //  let filteredItems = this.state.items ? getAllItems(this.state.items, this.state.product_data) : [];
        let product_data = this.state.product_data ? sortByValue(this.state.product_data) : [];
        let sortedProductEntries = this.state.family_code  ? Object.keys(this.state.family_code) : [];
        //product_data = sortByValue(product_data);
        return (
            <React.Fragment>
                    <Overlay showLoading={this.state.show_loading}/>
                <Result_Modal selected_data={this.state.modal_data} id={'orderModal'} load_result_from_result={this.updateShowModal}/>
                    <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                        <div className={"row"}>
                            <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                                <div className={"card shadow-lg primary-black-bg white"}>
                                    <div className="card-header green-border ">
                                        <h4>Order Filtering</h4>
                                    </div>
                                    <div className={"card-body"}>
                                        <FilterCard
                                            fields={[
                                                 {key: "customers", label: "Customer", isMulti:true, type: FIELD_TYPE.SEARCH_RESULT, result_types:["bill_to", "ship_to"]},
                                                {
                                                    key: "products",
                                                    label: "Product Line",
                                                    type: FIELD_TYPE.SELECT,
                                                    isMulti:true,
                                                    options: this.state.family_code,
                                                    isMapped: true
                                                },
                                                {
                                                    key: "items",
                                                    label: "Item",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: this.state.items,
                                                    isMulti:true,
                                                    isMapped: true
                                                  //  noOpMessage: "Select a Product Line First"
                                                },
                                                {
                                                    key: "sales_reps",
                                                    label: "Sales Rep.",
                                                    type: FIELD_TYPE.SELECT,
                                                    isMulti:true,
                                                    options: this.state.sales_reps,
                                                    isMapped: true
                                                },
                                                {key: "start_date", label: "Start Shipped Date", type: FIELD_TYPE.DATE, default:this.state.start_date},
                                                {key: "end_date", label: "End Shipped Date", type: FIELD_TYPE.DATE, default:this.state.end_date},
                                                {
                                                    key: "countries",
                                                    label: "Country",
                                                    isMulti:true,
                                                    type: FIELD_TYPE.SELECT,
                                                    options: countries
                                                },
                                                {
                                                    key: "states",
                                                    label: "State",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: states,
                                                    isMapped: true,
                                                    isMulti:true,
                                                    noOpMessage: "Select a Country"
                                                },{
                                                    key: "lot",
                                                    label: "Lot #",
                                                    type: FIELD_TYPE.TEXT,
                                                },{
                                                    key: "co_num",
                                                    label: "Order Number",
                                                    type: FIELD_TYPE.TEXT,
                                                }
                                            ]}
                                            filterChanged={(filter) => {
                                                console.log("Filter changed", filter)
                                                // @ts-ignore
                                                this.setState({filter: filter})}}
                                        />
                                        <div className="form-group row">
                                            <label className="col-sm-4 col-form-label">Shortcuts</label>
                                            <div className="col-sm-8 p-0 m-0">
                                                <Select
                                                    isSearchable={false}
                                                    placeholder={"None"}
                                                    noOptionsMessage={()=> "Failed to load."}
                                                    isMulti={false}
                                                    onChange={(e)=> this.shortcutSelected( e)}
                                                    className={"state_select"}
                                                    options={this.state.fyOptions}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer green-border-top">
                                        <button className={"btn btn-outline-info"} onClick={(e) =>this.onSubmit(e)}>Apply
                                        </button>
                                        <button className={"btn btn-outline-danger float-right"}
                                                onClick={() => window.location.reload()}>Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                                <div className={"card shadow-lg primary-black-bg white"}>
                                    <div className="card-header green-border ">
                                        <h4>Shipped Product Totals</h4>
                                    </div>
                                    <div className={"card-body p-0 m-0"}>
                                        <table className={"table table-borderless p-0 m-0"}>
                                            <tbody>
                                            {this.state.product_data ? product_data.map((value, index) => {
                                                let product_key = value[0];
                                                let items = value[1];
                                                let total = this.state.product_data[product_key].total //Already sorted so total is at the top
                                                let total_units = this.state.product_data[product_key].total_units //Already sorted so total is at the top
                                                return (<React.Fragment>
                                                    <tr>
                                                        <td><a role={"button"} data-toggle={"collapse"}
                                                               href={"#" + this.htmlEscape(value[0])}> {value[0]}</a>
                                                        </td>
                                                        <td>{formatMoney(total)}</td>
                                                        <td>{toPercentage(total, this.state.sale_totals.total_shipped_sales)}</td>
                                                        <td>{numberWithCommas(total_units)} units</td>
                                                    </tr>
                                                    {items.map((item, index) => {
                                                        let item_name = item[0]
                                                        if (item_name === "total" || item_name === "total_units") {
                                                            return null
                                                        }
                                                        let total_sale = this.getItemTotals(this.state.product_data, product_key, item_name).total;
                                                        let total_unit = this.getItemTotals(this.state.product_data, product_key, item_name).units;
                                                        return (<React.Fragment>
                                                            <tr className="collapse"
                                                                id={this.htmlEscape(value[0])}>
                                                                <td>{item_name}</td>
                                                                <td>{formatMoney(total_sale)}</td>
                                                                <td>{toPercentage(total_sale, this.state.sale_totals.total_shipped_sales)}</td>
                                                                <td>{numberWithCommas(total_unit)} units</td>
                                                            </tr>
                                                        </React.Fragment>)
                                                    })}
                                                </React.Fragment>)
                                            }) : null}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                                <div className={"card shadow-lg primary-black-bg white"}>
                                    <div className="card-header green-border ">
                                        <h4>Shipped Sales Totals</h4>
                                    </div>
                                    <div className={"card-body p-0 m-0 "}>
                                        <HorizontalTable columns={[{
                                            label: "Total Product Sales: ",
                                            key: "total_shipped_sales",
                                            formatFunc: formatMoney
                                        },
                                            {
                                                label: "Total S&H: ",
                                                key: "shipping_total",
                                                formatFunc: formatMoney,
                                            },
                                            {
                                                label: "Total Cost: ",
                                                key: "total_shipped_cost",
                                                formatFunc: formatMoney,
                                                // extraCol: [{raw: toPercentage(this.state.sale_totals.total_shipped_cost, this.state.sale_totals.total_shipped_sales)}]
                                            },
                                            {
                                                label: "Total Margin: ",
                                                key: "total_shipped_margin",
                                                formatFunc: formatMoney,
                                                // extraCol: [{raw: toPercentage(this.state.sale_totals.total_shipped_margin, this.state.sale_totals.total_shipped_sales)}],
                                                isBreakPoint:true
                                            },
                                            // {
                                            //     label: "Current Month Sales: ",
                                            //     key: "current_month_sales",
                                            //     formatFunc: formatMoney,
                                            //     extraCol: [{raw: toPercentage(this.state.sale_totals.current_month_sales, this.state.sale_totals.total_shipped_sales)}]
                                            // }
                                            ]}
                                               table_data={this.state.sale_totals ? this.state.sale_totals : null}
                                               table_style={"table-borderless"}/>
                                    </div>
                                    <p className={"TEXT-center"}>Results for: {this.state.start_date ? parseDate(this.state.start_date) :"All"} to {this.state.end_date ? parseDate(this.state.end_date) : "All"}</p>
                                </div>
                            </div>

                            {/*Table*/}
                            <div className={"col-12 pt-3 m-0 tableFixHead min-vh-100"}>
                                <div className={"card shadow-lg primary-black-bg white"}>
                                    <div className="card-header green-border ">
                                        <h4 className="d-inline-block">Shipped Orders</h4>
                                        <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                                onClick={this.onExport}>Export to Excel
                                        </button>

                                        <div className=" align-middle float-right pages">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "Failed to Load"}
                                                inputValue={this.state.selected_page}
                                                // ref={this.pageRef}
                                                onChange={(e:ReactSelect) => this.queryOrders(this.state.filter, e.value)}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div>
                                        <h5 className="d-inline-block float-right align-middle pr-2">Page </h5>
                                    </div>
                                    <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                        <SimpleTable columns={[
                                            {label: "Order #", key: "Order", onClick: (data) =>{
                                                this.updateShowModal(data)
                                                }},
                                            {label: "Bill-To", key: "Bill To Name"},
                                            {label: "Ship-To", key: "Ship To Location"},
                                            {label: "Order Date", key: "OrderDateTime", formatFunc: toLocalDate},
                                            {label: "Shipped Date", key: "Ship Date", formatFunc: parseDate},
                                            {label: "Sales Person", key: "Sales Person"},
                                            {label: "Item", key: "Item"},
                                            {label: "Unit Price", key: "Unit Price", formatFunc: formatMoney},
                                            {label: "Qty. Ordered", key: "Qty Ordered", formatFunc: numberWithCommas},
                                            // {label:"Net Price", key:"Net Price", formatFunc:formatMoney},
                                            {label: "Qty. Shipped", key: "Qty Shipped", formatFunc: numberWithCommas},
                                            {
                                                label: "Total Shipped Sales",
                                                key: "Total Shipped Sales",
                                                formatFunc: formatMoney
                                            },
                                        ]}table_data={this.state.table_data}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </React.Fragment>

        );
    }

    shortcutSelected(e) {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        filter.start_date = String(e.value - 1) + "-07-01"
        filter.end_date = "" + e.value + "-06-30";
        this.setState({filter:filter, start_date:filter.start_date, end_date:filter.end_date})
    }
}

export default Orders;