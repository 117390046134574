import React from "react";
import Select from "react-select";
import domain from "../../Domain";
import {formatMoney, parseDate} from "../../util/FormatUtil";
import SimpleTable from "../tables/SimpleTable";
import {Column} from "../tables/TableBase";
import {buildDisplayString} from "../tables/TableUtil";
import {Opportunity} from "../../../../CSI-API/types/Opportunity";

interface DashboardState{
    selected_slsman:string
    sls_options:[];
    value_opp:Opportunity[];
    due_opp:Opportunity[];
}

export class RepDashboard extends React.Component<any, DashboardState>{
    constructor(props) {
        super(props);
        this.state = {selected_slsman:null, sls_options:[], value_opp:[], due_opp:[]}
    }
    componentDidMount(): void {
        fetch(domain + "/api/employees/sales").then(res => res.json())
            .then(data => {
                console.log("Data", data)
                data = data.map(d => {
                    d.label = d.name;
                    d.value = d.name;
                    return d;
                })
                this.setState({sls_options: data})
            })
            .catch(console.log)
    }

    loadRepPage(rep){
        console.log(rep)
        fetch(domain+ "/api/opportunities/top/rep", {
            method: 'POST',
            body: JSON.stringify(rep),
            headers: {'Content-Type': 'application/json'}}).then(res => res.json()).then(data =>{
            this.setState({value_opp:data.byValue, due_opp:data.byDue})
        }).catch(console.log)
    }

    buildOppTable(table_data:Opportunity[], title:string, id:string){
        return (
            <React.Fragment>
            <div className={"col-6 pt-3 m-0 min-vh-100"}>
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <h4 className="d-inline-block">{title}</h4>
                    </div>
                    <div className={"card-body table-responsive-lg  p-0 m-0"}>
                        <table className={"skip-stripe table table-dark table-striped p-0 m-0"}>
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Customer</th>
                                <th scope="col">Contact</th>
                                <th scope="col">Item</th>
                                <th scope="col">Status</th>
                                <th scope="col">Due Date</th>
                                <th scope="col">Est. Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {table_data ? table_data.map((opp, index) =>{
                                return(<React.Fragment>
                                        <tr className={"collapsed " + (((index - 1)  % 2)  === 0 ? "dark-row-override-even" : "dark-row-override-odd")}>

                                            <td><a data-toggle={"collapse"}
                                                   role={"button"}
                                                   href={"#"+id + index}>
                                                {opp.name}
                                            </a></td>
                                            <td>{opp.customer ? <a href="#!" onClick={() => this.props.open_modal(opp.customer)}>{opp.customer.name}</a> : null}</td>
                                            <td>{opp.contact ?  <a href="#!" onClick={() => this.props.open_modal(opp.contact)}>{opp.contact.name}</a>  : null}</td>
                                            <td>{opp.item ? opp.item.item : null}</td>
                                            <td>{opp.opp_status}</td>
                                            <td>{parseDate(opp.projected_close_date)}</td>
                                            <td>{formatMoney(opp.est_value)}</td>
                                            <td> <button className={"btn btn-outline-light ml-3 d-inline-block"}
                                                         onClick={(e) => this.editOpp(opp)}>Edit
                                            </button></td>
                                        </tr>
                                        <tr className={"collapse "} id={id + index}>
                                            <td className="p-0  m-0 " colSpan={12}>
                                                <div className={"row p-0 m-0"}>
                                                    <SimpleTable columns={[
                                                        {label:"Task #", key:"opp_task_num"},
                                                        {label:"Description", key:"description"},
                                                        {label:"Type", key:"opp_task_type"},
                                                        {label:"Status", key:"status", formatFunc: (str) => str === "O" ? "Open" : "Closed"},
                                                        {label:"Created", key:"CreateDate", formatFunc:parseDate},
                                                        {label:"Due", key:"due_date", formatFunc:parseDate},
                                                        {label:"Completed", key:"complete_date", formatFunc:parseDate},
                                                        {label:"", key:"edit"}
                                                    ]}
                                                     table_data={opp.tasks}
                                                     rowBuilder={((table_data, columns) =>{
                                                         if(!table_data)
                                                             return;
                                                         return table_data.map((val:any)  => {

                                                             return (<tr>{
                                                                 columns.map((col:Column) => {
                                                                     if(col.key === "edit"){
                                                                         return (<button onClick={(e)=>this.editTask(val)} className={"btn btn-outline-light"}>Edit Task</button>)
                                                                     }
                                                                     return (<td>{buildDisplayString(col, val, null)}</td>)
                                                                 })
                                                             }</tr>)
                                                         })
                                                     })}/>
                                                </div>
                                            </td>

                                        </tr>
                                    </React.Fragment>
                                )
                            }): null}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<div className="container-fluid secondary-slate-bg   pt-5">
            <div className={"row"}>
                <div className={"col-12 pb-2"}>
                    <div className="card shadow-lg primary-black-bg white">
                        <div className="card-header">
                            <h4 className="d-inline-block">Rep Dashboard</h4>
                            <div className=" align-middle float-right min-250">
                                <Select
                                    isSearchable={true}
                                    noOptionsMessage={() => "Failed to Load"}
                                    onChange={(e) => this.loadRepPage(e)}
                                    className={"state_select"}
                                    options={this.state.sls_options}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {this.buildOppTable(this.state.value_opp, "Top Opps by Value", "opp_val")}
                {this.buildOppTable(this.state.due_opp, "Top Opps by Due Date", "opp_date")}
            </div>
        </div>);
    }

    private editTask(val: any) {

    }
    private editOpp(val:any){

    }
}