import React from "react";

export interface SalesAnalysisState{

}

export class SalesAnalysis extends React.Component<any, SalesAnalysisState>{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return super.render();
    }
}