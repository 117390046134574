import React from "react";
import Overlay from "../Overlay";
import { TrainingModuleModal } from "../modals/TrainingModuleModal";
import { Course, TrainingType } from "../../types/TrainingTypes";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE } from "../FilterCard";
import domain from "../../Domain";
import { Employee } from "../../types/Employee";
import { TrainingModal } from "../modals/TrainingModal";
import { parseDate } from "../../util/FormatUtil";
import Select from "react-select";
import { defaultCipherList } from "constants";
import Swal from 'sweetalert2';
import {TrainingEditModal} from "../immytrack/TrainingEditModal";
import axios from "axios";
import {onAuthStateChanged, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult} from "firebase/auth";
import {auth} from "../../FirebaseConfig";
interface OrdersState {
  orderChanged?
  previousOrder?
  showLoading;
  records?;
  selectedRecord?: TrainingType;
  employees: { label: string; value: string }[];
  products: { label: string; value: string }[];
  uom: string;
  jobs: [];
  DeliveryLocations: [];
  FamilyCode: string;
  currUom: string;
  order?;
  modules: Course[];
  filter?;
  courseArray: any;
  userView: string;
  orders?;
  delivInfo?;
  userEmail?,
  contentView:boolean
  userName: string
}

export class Fulfillment extends React.Component<any, OrdersState> {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      orderChanged: false,
      previousOrder: {},
      showLoading: false,
      records: [],
      employees: [],
      products: [],
      uom: null,
      jobs: null,
      DeliveryLocations: null,
      FamilyCode: null,
      currUom: null,
      order: {
        id: null,
        refNum: null,
        quantity: null,
        uom: null,
        line: null,
        dateEntered: null
      },
      delivInfo: {
        delivLocation: null,
        jobNum: null,
        empName: null,
        notes: null,
      },
      modules: [],
      courseArray: [],
      userView: "",
      orders: [],
      contentView: false,
      userName: null
    };

    this.queryRecord = this.queryRecord.bind(this);
    this.onFulfill = this.onFulfill.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }

  componentDidMount() {

    onAuthStateChanged(auth, (user) => {
      if (user) {
        let uId = user.providerData[0].uid;
        console.log(user.providerData[0].uid)
        this.setState({userEmail: user.providerData[0].email})


    this.setState({ showLoading: true }, () => {
      fetch(
        domain + (this.props.route ? this.props.route : "/api/employees/all"),
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((data) => {
         // console.log(data);
          this.setState({ employees: data }, () => {
            console.log(this.state.employees);
          });
        })
        .catch(() => {
          alert("Failed Employees");
        });
      fetch(domain + (this.props.route ? this.props.route : "/api/items/IZ"), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ products: data[0] }, () => {
       //     console.log(this.state.products, "Products");
          });
          this.setState({ uom: data[1] }, () => {
            console.log(this.state.uom);
          });
          this.setState({ jobs: data[2] });
          this.setState({ DeliveryLocations: data[3] });
        })
        .catch(() => {
          alert("Failed Employees");
        });
      fetch(
        domain +
          (this.props.route ? this.props.route : "/api/firebase/checkGroupIMMYZon"),
        {
          method: "POST",
          body: JSON.stringify({uId: uId}),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log("can view:" + JSON.stringify(data));
          if(data.CanView === "false"){
            Swal.fire({
              title: 'Uh Oh!',
              text: "You don't have access to this page! Please contact IT Support!",
              icon: 'error',
              showConfirmButton: true,
            }).then((response) => {
              if(response.isConfirmed){
                window.location.replace(window.location.origin);
              }
          })}
          this.setState({ userView: "true" }, function () {
            console.log("153")
            fetch(
              domain +
                (this.props.route
                  ? this.props.route
                  : "/api/fulfillment/records"),
              {
                method: "POST",
                body: JSON.stringify({ service: data.CanView }),
                headers: { "Content-Type": "application/json" },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                this.setState({ records: data[0] },()=>{
                  console.log("Records 168: " + this.state.records)
                });
                this.setState({ orders: data[1] });
              })
              .catch((err) => {
                alert(err);
              })
              .finally(() => this.setState({ showLoading: false }));
          });
        });
    });
      }
    });
  }

  queryRecord(orderNum) {
    fetch(
      domain +
        (this.props.route ? this.props.route : "/api/fulfillment/select"),
      {
        method: "POST",
        body: JSON.stringify({ order: orderNum }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          order: {
            id : data[0].ID,
            status: data[0].Status,
            refNum: data[0].ReferenceNumber,
            quantity: data[0].Quantity,
            uom: {label: data[0].UOM, value: data[0].UOM},
            line: data[0].Type,
            dateEntered: data[0].DateEntered
          },
        });
        this.setState({
          delivInfo: {
            delivLocation: data[0].DeliveryLocation,
            jobNum: data[0].WorkOrder,
            emp: data[0].EmployeeName,
            notes: data[0].Notes,
          },
        });
        this.setState({
          userEmail: data[0].UserEntered !== null ? data[0].UserEntered : null,
        });
      })
      .catch(() => {
        alert("Failed Employees");
      });
  }

  onFulfill(e) {
    let keys = Object.keys(this.state.previousOrder).length ? Object.keys(this.state.previousOrder) : null;
    if(this.state.order.id !== null){
    if(this.state.orderChanged === false){
      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure you want to fulfill this order?',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true
      }).then((result) => {
        if(result.isConfirmed){
          this.setState({ showLoading: true }, () => {
          fetch(
            domain + (this.props.route ? this.props.route : "/api/fulfillment/fulfill"),
            {
              method: "PATCH",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({refNum : this.state.order.refNum, qty: this.state.order.quantity, ID: this.state.order.id, Email: this.state.userEmail, order: this.state.order, delivInfo: this.state.delivInfo, user: this.state.userEmail})
            }
          ).then((res) => res.json())
            .then((data) => {
              this.setState({showLoading: false})
              console.log(data)
            if(this.state.userEmail === null){
              Swal.fire({
                title: 'Alert!',
                text: "The User that placed this order doesn't seem to have an email attached to their OKTA account so no email will be sent!",
                icon: 'info',
                showConfirmButton: true,
                showCancelButton: true
              }).then((response) => {
                if(response.isConfirmed){
                  window.location.reload();
                }
              })
            } else if(data.success === true){
              Swal.fire({
                title: 'Success!',
                text: "Order has been Fulfilled and Email has been sent!",
                icon: 'info',
                showConfirmButton: true,
                showCancelButton: true
              }).then((response) => {
                if(response.isConfirmed){
                  window.location.reload();
                }
              })
            } else if(data.success === false){
              Swal.fire({
                title: 'Oops!',
                text: "Order has not been Fulfilled and Email has not been sent! Please try again! Error: " + data.error,
                icon: 'error',
                showConfirmButton: true,
                showCancelButton: true
              }).then((response) => {
                if(response.isConfirmed){
                  window.location.reload();
                }
            })
          }})
          
          })} else {}
      })
    
    } else if(this.state.orderChanged === true){
      Swal.fire({
        title: 'Confirm',
        html: `<p>There are changes to this order!</p> 
               <p>Ref #: ${this.state.previousOrder.refNum}</p>
               <p>Quantity: ${this.state.previousOrder.qty}
               <p>To: </p>
               <p>Ref #: ${this.state.order.refNum}</p>
               <p>Quantity: ${this.state.order.quantity}</p>
               <p>Are you sure you want to alter and fulfill this order?</p>`,
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true
      }).then((result) => {
        if(result.isConfirmed){
          this.setState({ showLoading: true }, () => {
            fetch(
              domain + (this.props.route ? this.props.route : "/api/fulfillment/fulfill"),
              {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({refNum : this.state.order.refNum, qty: this.state.order.quantity, ID: this.state.order.id, Email: this.state.userEmail, order: this.state.order, delivInfo: this.state.delivInfo})
              }
            ).then((res) => res.json())
              .then((data) => {
                this.setState({showLoading: false})
                console.log(data)
              if(this.state.userEmail === null){
                Swal.fire({
                  title: 'Alert!',
                  text: "The User that placed this order doesn't seem to have an email attached to their OKTA account so no email will be sent!",
                  icon: 'info',
                  showConfirmButton: true,
                  showCancelButton: true
                }).then((response) => {
                  if(response.isConfirmed){
                    window.location.reload();
                  }
                })
              } else if(data.success === true){
                Swal.fire({
                  title: 'Success!',
                  text: "Order has been Fulfilled and Email has been sent!",
                  icon: 'info',
                  showConfirmButton: true,
                  showCancelButton: true
                }).then((response) => {
                  if(response.isConfirmed){
                    window.location.reload();
                  }
                })
              } else if(data.success === false){
                Swal.fire({
                  title: 'Oops!',
                  text: "Order has not been Fulfilled and Email has not been sent! Please try again! Error: " + data.error,
                  icon: 'error',
                  showConfirmButton: true,
                  showCancelButton: true
                }).then((response) => {
                  if(response.isConfirmed){
                    window.location.reload();
                  }
              })
            }})
            
            }
      )} else {}
      })
//window.confirm("There are changes to this order! \n\nRef #: " + this.state.previousOrder.refNum + "\nQuantity: " + this.state.previousOrder.qty + "\nTo: \nRef #: " + this.state.order.refNum + "\nQuantity: " + this.state.order.quantity + "\n\n Are you sure you want to alter and fulfill this order?") == true
    }
    
    return;
  } else {
    Swal.fire({
      title: 'Error',
      text: 'Please select an order to fulfill!',
      icon: 'error',
    })
  }

  }

  handleDelete(r) {
    console.log("R: " +r)
    console.log("R: " +JSON.stringify(r))
    if(window.confirm("Are you sure you want to delete this record?") == true) {
      this.setState({showLoading: true}, () => {
        fetch(domain + (this.props.route ? this.props.route : "/api/fulfillment/delete"), {
          method: 'POST',
          body: JSON.stringify({record: r, user: this.state.userEmail}),
          headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(data => {
              if (data.success) {
                alert("Deleted");
                window.location.reload();
              } else {
                alert("Failed to delete record.");
              }
              this.setState({showLoading: false});
            });
      })
    } else {

    }
  }

  onUpdate(e){
    console.log(this.state)
    if(this.state.order.id !== null){
      this.setState({showLoading: true})
      if(window.confirm("This will alter this order! Are you sure?") == true){
        fetch(
          domain + (this.props.route ? this.props.route : "/api/fulfillment/update"),
          {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ID: this.state.order.id, Status: this.state.order.status, refNum: this.state.order.refNum, qty: this.state.order.quantity,
              notes: this.state.delivInfo.notes, user: this.state.userName})
          }
        ).then(res => res.json())
            .then(data => {
              if (data.success) {
                alert("Updated");
                window.location.reload();
              } else {
                alert("Failed to Update");
              }
              this.setState({showLoading: false});
            })
          .catch(() => {
            this.setState({showLoading: false});
            alert("Failed to update order");
          });
      }
      //window.location.reload();
      return;
    } else {
      window.alert("Please select an Order to fulfill!")
    }
  
  }

  render() {
    
    return (
      <div>
      { this.state.userView != "Unauthorized" &&
      <React.Fragment>
        <Overlay showLoading={this.state.showLoading} />
        <div className="container-fluid secondary-slate-bg min-vh-100 white pt-5">
          <div className="immyzonHeader ">
            <div
              className={"card shadow-lg primary-black-bg white fulfillFilter"}
            >
              <div className="card-header green-border ">
                <h4>Filtering</h4>
              </div>
              <div className={"card-body"}>
                <FilterCard
                  fields={[
                    {
                      key: "selectOrder",
                      label: "Select Order/ Entry:",
                      type: FIELD_TYPE.SELECT,
                      options: this.state.orders,
                      isMapped: true,
                    },
                  ]}
                  filterChanged={(e) => {
                    if (Object.keys(e).length > 0) {
                      this.queryRecord(e.selectOrder[0]);
                    }
                  }}
                />
              </div>
              <div className="card-body green-border-top">
                <FilterCard
                  fields={[
                    {
                      key: "status",
                      label: "Status:",
                      type: FIELD_TYPE.SELECT,
                      placeholder: this.state.order.status ? this.state.order.status : null,
                      options: ["In Progress", "On Order", "Out of Stock"],
                    },
                    {
                      key: "refNum",
                      label: "Reference #: ",
                      type: FIELD_TYPE.SELECT,
                      options: this.state.products,
                      placeholder: this.state.order.refNum ? this.state.order.refNum : null,
                      isMapped: true,
                    },
                    {
                      key: "quantity",
                      label: "Quantity: ",
                      type: FIELD_TYPE.TEXT,
                      placeholder: this.state.order.quantity ? this.state.order.quantity : null,
                    },
                    {
                      key: "uom",
                      label: "UOM: ",
                      type: FIELD_TYPE.TEXT,
                      placeholder: this.state.order.uom
                        ? this.state.order.uom.label
                        : null,
                      readOnly: true,
                    },
                    {
                      key: "line",
                      label: "Line: ",
                      type: FIELD_TYPE.TEXT,
                      placeholder: this.state.order.line
                        ? this.state.order.line
                        : null,
                      readOnly: true,
                    },
                  ]}
                  filterChanged={(e) => {
                    console.log('inside function', e)
                    if (Object.keys(e).length) {
                      this.setState({orderChanged : true, previousOrder : {refNum : this.state.order.refNum, qty : this.state.order.quantity}})
                      console.log('inside if state');
                      if(e.refNum){
                      fetch(
                        domain +
                          (this.props.route
                            ? this.props.route
                            : "/api/items/FamCode"),
                        {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({ code: e.refNum[0] }),
                        }
                      )
                        .then((res) => res.json())
                        .then((data) => {
                          console.log(data[0].description);
                          this.setState(
                            {
                              order: {
                                id: this.state.order.id,
                                refNum:  e.refNum ? e.refNum[0] : this.state.order.refNum,
                                status: e.status ? e.status : this.state.order.status,
                                quantity: e.quantity ? e.quantity : this.state.order.quantity,
                                uom: this.state.uom[
                                  this.state.products.findIndex(
                                    (elem) => elem.label === e.refNum[0]
                                  )
                                ],
                                line: data[0].description,
                                dateEntered: this.state.order.dateEntered
                              },
                            },
                            () => console.log(this.state.order.uom.label)
                          );
                        })
                        .catch((err) => {
                          alert(err);
                        });
                      } else {
                        console.log('inside else state')
                        this.setState(
                          {
                            order: {
                              id: this.state.order.id,
                              refNum:  e.refNum ? e.refNum[0] : this.state.order.refNum,
                              status: e.status ? e.status : this.state.order.status,
                              quantity: e.quantity ? e.quantity : this.state.order.quantity,
                              uom: e.refNum ? this.state.uom[
                                this.state.products.findIndex(
                                  (elem) => elem.label === e.refNum[0]
                                )
                              ] : this.state.order.uom,
                              line: this.state.order.line,
                            }
                          },
                          () => console.log(this.state.order.uom.label)
                        );
                      }
                    } 
                  }}
                />
              </div>
              <div className="card-body green-border-top">
                <FilterCard
                  fields={[
                    {
                      key: "delivLocation",
                      label: "Delivery Location: ",
                      type: FIELD_TYPE.TEXT,
                      placeholder: this.state.delivInfo.delivLocation ? this.state.delivInfo.delivLocation : null,
                      readOnly: true
                    },
                    {
                      key: "job",
                      label: "Job #: ",
                      type: FIELD_TYPE.TEXT,
                      placeholder: this.state.delivInfo.jobNum ? this.state.delivInfo.jobNum : null,
                      readOnly: true
                    },
                    {
                      key: "emp",
                      label: "Employee Name: ",
                      type: FIELD_TYPE.TEXT,
                      placeholder: this.state.delivInfo.emp ? this.state.delivInfo.emp : null,
                      readOnly: true
                    },
                    {
                      key: "notes",
                      label: "Notes: ",
                      type: FIELD_TYPE.TEXT_AREA,
                      placeholder: this.state.delivInfo.notes? this.state.delivInfo.notes : null,
                      default: this.state.delivInfo.notes? this.state.delivInfo.notes : null,
                    //  readOnly: false
                    },
                  ]}
                  filterChanged={(e) => {
                    if(e.notes != this.state.delivInfo.notes){
                      this.setState({
                        delivInfo: {
                          delivLocation: e.delivLocation ? e.delivLocation : this.state.delivInfo.delivLocation,
                          jobNum: e.jobNum ? e.jobNum : this.state.delivInfo.jobNum,
                          emp: e.emp ? e.emp : this.state.delivInfo.emp,
                          notes: e.notes ? e.notes : this.state.delivInfo.notes
                        }
                      })
                    }
                    if (e.refNum && e.refNum != this.state.order.product) {
                      console.log(e.refNum);
                      fetch(
                        domain +
                          (this.props.route
                            ? this.props.route
                            : "/api/items/FamCode"),
                        {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({ code: e.refNum[0] }),
                        }
                      )
                        .then((res) => res.json())
                        .then((data) => {
                          console.log(data[0].description);
                          this.setState(
                            {
                              order: {
                                product: e.refNum[0],
                                uom: this.state.uom[
                                  this.state.products.findIndex(
                                    (elem) => elem.label === e.refNum[0]
                                  )
                                ],
                                line: data[0].description,
                              }
                            },
                            () => console.log(this.state.order.uom.label)
                          );
                          // this.setState({products: data[0]}, () => {
                          //     console.log(this.state, "Products")
                          // })
                          // this.setState({uom: data[1]}, () => {
                          //     console.log(this.state.uom)
                          // })
                          // this.setState({jobs: data[2]})
                          // this.setState({DeliveryLocations: data[3]})
                        })
                        .catch((err) => {
                          alert(err);
                        });
                    }
                  }}
                />
              </div>
              <div className="card-footer green-border-top">
                <button
                  className={"btn btn-outline-warning"}
                  onClick={(e) => this.onUpdate(e)}
                >
                  Update
                </button>
                <button
                  className={"btn btn-outline-success float-right"}
                  onClick={(e) => this.onFulfill(e)}
                  disabled={this.state.orderChanged}
                >
                  Fulfill
                </button>
              </div>
            </div>
            <div className="fulfillLogo">
              {this.state.userView === "QFC" ? (
                <img src="/images/QFC.png" alt="" />
              ) : (
                <div></div>
              )}
              {this.state.userView === "FB" ? (
                <img src="/images/Fishbucket.png" alt="" />
              ) : (
                <div></div>
              )}
              {this.state.userView === "HUT" ? (
                <img src="/images/Immyhut.png" alt="" />
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12 pt-3  m-0 tableFixHead"}>
              <div className={"card shadow-lg primary-black-bg white"}>
                <div className="card-header green-border ">
                  <div className="row">
                    <div className={"col-2"}>
                      <h4 className="d-inline-block">Orders</h4>
                    </div>
                    <div className={"col-2"}></div>
                  </div>
                </div>
                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                  <SimpleTable
                    columns={[
                      { label: "Order", key: "OrderNumber" },
                      { label: "Type", key: "Type" },
                      { label: "Part", key: "ReferenceNumber" },
                      { label: "Qty", key: "Quantity" },
                      { label: "UOM", key: "UOM" },
                      { label: "WO", key: "WorkOrder" },
                      { label: "Delivery Location", key: "DeliveryLocation" },
                      { label: "Employee", key: "EmployeeName" },
                      {
                        label: "Date",
                        key: "DateEntered",
                      },
                      { label: "Time", key: "TimeEntered" },
                      { label: "Status", key: "Status" },
                      {
                        label: "",
                        key: "",
                        rawFormat: (val) => <React.Fragment></React.Fragment>,
                      },
                      {
                        label: "", key: "", rawFormat: (val) => <React.Fragment>
                                            <span id={"rowButtons"}>
                                              <button className={"btn btn-outline-danger ml-2"} onClick={() => this.handleDelete(val)}>Delete</button>
                                            </span>
                        </React.Fragment>
                      },
                    ]}
                    table_data={this.state.records}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
                  }
                  </div>
    );
  }
}
