import React from "react";
import {Bar, Pie} from "react-chartjs-2";
import domain from "../../Domain";
import Chart, {ChartColor} from "chart.js";
import Select from "react-select";
import palette from "google-palette"
import {BarChart} from "./BarChart";
import CustomerPricing from "./CustomerPricing";

interface CustomerState{
    fy:any[]
    all_time:any
    info:any
    selectedPieBreakdown:number
    colors:ChartColor[]
}
export default class Customer extends React.Component<any, CustomerState>{
    constructor(props) {
        super(props);
        let colors = palette('mpn65', 30);
        colors = colors.map(c => "#"+c);
        this.state ={all_time: undefined, fy: [], info: undefined, selectedPieBreakdown:2020, colors:colors}
    }


    componentDidMount(): void {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('custseq')
        console.log(token)//123
        console.log(this.props.location.query)
        // C000001
        fetch(domain + "/api/orders/customer",{
            method: 'POST',
            body: JSON.stringify({
                "cust_num":query.get('custnum'),
                "cust_seq":parseInt(query.get('custseq'))
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => this.setState({
                info:data.info,
                all_time:data.all_time,
                fy:data.fy.reverse()
            }))
            .catch(e => alert("Failed to fetch locations"));
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let barData = [];
        let barLabels = [];
        let pieData = {};
        let fyData = this.state.fy;
        let selectLabels = [];

        if(fyData){
            fyData.forEach(yearly =>{
                let families = Object.keys(yearly.totals).sort();
                let total = 0;
                pieData[yearly.year] = {labels:[], data:[]};
                families.forEach(family =>{
                    total += yearly.totals[family].total
                    pieData[yearly.year].labels.push(family);
                    pieData[yearly.year].data.push(yearly.totals[family].total)
                })

                selectLabels.push({label:"FY " + yearly.year, value:yearly.year})
                barData.push(total)
                barLabels.push(yearly.year)
            })
        }
        const query = new URLSearchParams(this.props.location.search);
        return (
            <React.Fragment>
                <div className="container-fluid secondary-slate-bg min-vh-100   pt-5">
                    <div className={"row"}>
                        <div className={"col-12 pb-2"}>
                            <div className="card shadow-lg primary-black-bg white">
                                <div className="card-header ">
                                    <h4>{this.state.info ? this.state.info.name : null}</h4>
                                </div>
                            </div>
                        </div>
                        <div className={"col-xl-4 col-lg-6 col-md-6  col-12"}>
                            <div className="card shadow-lg primary-black-bg white">
                                <div className="card-header green-border ">
                                    <h4>Fiscal Year Total Sales</h4>
                                </div>
                                <div className="card-body">
                                    <BarChart datasets={[{data:barData,  backgroundColor: "#1EB980", label:"Total Sales",}]} labels={barLabels}/>
                                </div>
                            </div>
                        </div>
                        <div className={"col-xl-8 col-lg-6 col-md-6  col-12"}>
                            <div className="card shadow-lg primary-black-bg">
                                <div className="card-header green-border ">
                                    <div className={"row"}>
                                        <div className={"col-7 white"}>
                                            <h4>Fiscal Year Total Sales</h4>
                                        </div>
                                        <div className={"col-5 "}>
                                            <Select className={"state_select"}
                                                    options={selectLabels}
                                                    onChange={e => this.setState({selectedPieBreakdown:e.value})}
                                                    defaultValue={{label:"FY " + new Date().getFullYear(), value:new Date().getFullYear()}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                <Pie data={{
                                    datasets:[{data:pieData[this.state.selectedPieBreakdown] ? pieData[this.state.selectedPieBreakdown].data : [], backgroundColor:this.state.colors,  label:"Total Sales",
                                    }],
                                    labels: pieData[this.state.selectedPieBreakdown] ? pieData[this.state.selectedPieBreakdown].labels : []
                                }} options={{
                                    legend:{
                                        labels:{
                                            fontColor:"#fff"
                                        }
                                    },
                                    tooltips:{
                                        callbacks:{
                                            label:function (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                                                // @ts-ignore
                                                return data.labels[tooltipItem.index] + ': $ ' + Intl.NumberFormat().format((data.datasets[0].data[tooltipItem.index]));
                                            }
                                        }
                                    },
                                }}/>

                                </div>

                            </div>
                        </div>
                        <CustomerPricing cust_num={query.get('custnum')}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}