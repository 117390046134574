import React from "react";
import Table from "../tables/Table";
import {ModalBodyProps} from "./Modals";
import {formatMoney, parseDate} from "../../util/FormatUtil";

export default class OpportunityModal extends React.Component<ModalBodyProps, {}>{

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log("Rendering complaint", this.props.data)
        return (
            <Table
                isHorizontal={true}
                columns={[
                    {label: "Description", key: "description"},
                    {label: "Status", key: "opp_status_desc"},
                    {label: "Item", key: "item.item"},
                    {label: "Sales Rep", key: "sales_name"},
                    {label: "Customer", key: "customer.name", modal_data: {cust_num:this.props.data.customer.cust_num, cust_seq:this.props.data.customer.cust_seq}},
                    {label: "Created", key: "CreateDate", formatFunc: parseDate},
                    {label: "Projected Close", key: "projected_close_date", formatFunc: parseDate},
                    {label: "Closed Date", key: "close_date", formatFunc: parseDate},
                    {label: "Estimated Value", key: "est_value", formatFunc:formatMoney},
                    ]}
                load_modal={this.props.load_modal}
                modal_cb={this.props.modal_cb}
                table_data={this.props.data}
                isMap={true}
                table_style={"table-striped text-left m-0 p-0"}
                header_style={"fw-700"}
            />
        )
    }
}