import React from "react";
import domain from "../../Domain";
import {FIELD_TYPE} from "../FilterCard";
import ModalForm from "./ModalForm";
import {formatMultiselect} from "../../util/FormatUtil";

export interface OppTaskState{
    ID:string
    task_types:string[]
}
interface OppTaskProps{
    ID:string
    opp_data:any
    callback:()=>void
}

export default class OppTaskFormModal extends React.Component<OppTaskProps, OppTaskState>{

    constructor(props) {
        super(props);
        this.state = {ID: "", task_types: []};
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        fetch(domain + "/api/opportunities/tasks/info").then(res => res.json()).then(data => this.setState({
            task_types:data.statuses,
        })).catch(e => console.log("Failed to fetch opp info"));
    }

    onSubmit(opp){
        console.log(this.props.opp_data)
        if(!opp.opp_status) {
            alert("Must have a status selected");
            return
        }
        opp.opp_status = opp.opp_status ? opp.opp_status[0] : null
        fetch(domain + "/api/opportunities/tasks", {
            method: 'POST',
            body: JSON.stringify({opp:this.props.opp_data, task:opp}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>{
            if(response.status === 200){
                let id = this.props.ID;
                alert("Success");
                // @ts-ignore
                window.$("#" + id).modal('hide');
                if(this.props.callback)
                    this.props.callback();
            }else{
                alert("Failed to upload opportunity.")
            }
        })
    }


    render() {

        let data = this.props.opp_data ? this.props.opp_data : {};
        return (
            <ModalForm
                key={this.props.opp_data ? this.props.opp_data.name : "oppForm"}
                fields={[
                    {key: "description", label: "Description", type: FIELD_TYPE.TEXT},
                     {key: "opp_task_type", label: "Task Type",  type: FIELD_TYPE.SELECT, options:  [{label:"Email" , value:"E"}, {label:"Phone", value:"P"}, {label:"Site Visit", value:"S"}], isMapped:true},
                    {key: "opp_status", label: "Status",  type: FIELD_TYPE.SELECT, isMapped:true, options:[{label:"Open", value:"O"}, {label:"Closed", value:"C"}], },
                    {key: "due_date", label: "Due Date",  type: FIELD_TYPE.DATE},
                    {key: "complete_date", label: "Complete Date",  type: FIELD_TYPE.DATE},
                ]}
                ID={this.props.ID}
                title={"Opportunity Task Form"}
                onSubmit={this.onSubmit}
            />)
    }
}