import React from "react";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import {Gage} from "../../types/Gage";
import {EquipmentRecord} from "../../types/EquipmentRecord";
import {EquipmentRecordModal} from "../modals/EquipmentRecordModal";
import SimpleTable from "../tables/SimpleTable";
import {getTaskTeams, parseDate, toTrueFalse} from "../../util/FormatUtil";
import domain from "../../Domain";
import {RecurringTaskModal} from "../modals/RecurringTaskModal";


interface TaskState{
    showOverlay:boolean
    gageCategories
    filter?
    selectedItemWrapper
    selectedRecord
    selectedItem:Gage
    equipment:any[]
    allTasks
    selectedTeamWrapper?
    team?
}


export class RecurringTaskManager extends React.Component<any, TaskState>{
    constructor(props) {
        super(props);
        this.state ={
            equipment:[],
            gageCategories:[],
            filter:{}, selectedRecord:{} as EquipmentRecord,
            allTasks:[]
        } as TaskState
    }


    private queryItemRecord() {
        this.setState({showOverlay:true}, () =>{
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/recurring"), {
                method: 'POST',
                body: JSON.stringify({item: this.state.selectedItem, team:this.state.team}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    this.setState({allTasks:data.tasks})
                }).finally(() =>this.setState({showOverlay:false}));
        })
    }


    componentDidMount() {
        this.setState({showOverlay:true}, () =>{
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/"), {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    this.setState({ showOverlay:false, equipment: data.gages.map(i => ({label:i.gage_num + " - " + i.description, value:i}))})
                }).catch(() =>{
                alert("Failed");
                this.setState({showOverlay:false});
            });
        });

        fetch(domain + (this.props.route ? this.props.route : "/api/equipment/categories"), {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                this.setState({gageCategories: data.types})
            });
    }

    private submitTask(val) {
        fetch(domain + (this.props.route ? this.props.route : "/api/equipment/create/recurring"), {
            method: 'POST',
            body: JSON.stringify(val),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(data => {
                alert("Saved");
                this.queryItemRecord()
            });
    }

    render() {
        let items = JSON.parse(JSON.stringify(this.state.equipment));
        console.log(this.state.selectedItem)
        if(this.state.filter.category && this.state.filter.category.length > 0)
            items = items.filter(i => this.state.filter.category.includes(i.value.gagetype))
        let selectedItem = this.state.selectedItem;
        return <React.Fragment>
            <Overlay showLoading={this.state.showOverlay}/>
            <RecurringTaskModal onSubmit={(val) => this.submitTask(val)} equipmentRecord={this.state.selectedRecord}/>
            <div className={"col-12 pt-3 m-0 tableFixHead"}>
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <h4 className="d-inline-block">Recurring Tasks</h4>

                    </div>
                    <div className="card shadow-lg primary-black-bg white">
                        <div className="card-header">

                            <div className={"col-6 "}>
                                <FilterCard
                                    fields={[
                                        {
                                            key: "category",
                                            label: "Category",
                                            type: FIELD_TYPE.SELECT,
                                            isMulti:true,
                                            options: this.state.gageCategories,
                                            noOpMessage:"Select a product family"
                                        }]} filterChanged={(e)=> this.setState({filter:e})}/>
                                <div className="form-group row">
                                    <label  className="col-sm-4 col-form-label">Items</label>
                                    <div className="col-sm-8  p-0 m-0">
                                        <Select
                                            placeholder={"All"}
                                            noOptionsMessage={()=> "Failed to load."}
                                            value={this.state.selectedItemWrapper}
                                            onChange={(e)=> {this.setState({selectedItemWrapper:e, selectedItem:e ? e.value as unknown as  Gage : null}, () => this.queryItemRecord())}}
                                            className={"state_select"}
                                            options={items}/>

                                    </div>

                                </div>
                                <div className="form-group row">
                                    <label  className="col-sm-4 col-form-label">Team</label>
                                    <div className="col-sm-8  p-0 m-0">
                                        <Select
                                            placeholder={"All"}
                                            noOptionsMessage={()=> "Failed to load."}
                                            value={this.state.selectedTeamWrapper}
                                            onChange={(e)=> {this.setState({selectedTeamWrapper:e, team:e.value}, () => this.queryItemRecord())}}
                                            className={"state_select"}
                                            options={getTaskTeams()}/>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card-body table-responsive-lg  p-0 m-0"}>
                        <div className={"col-12"}>
                            <button className={"btn btn-outline-primary"} onClick={() =>
                                this.setState({selectedRecord:{EquipmentID: this.state.selectedItem ? this.state.selectedItem.gage_num : null,
                                    Active:true} as any}, () => {

                                RecurringTaskModal.display()
                            })}>Create Task</button>
                            {selectedItem ?
                                <React.Fragment>
                                    <p><b>Type:</b> {selectedItem.gagetype}</p>
                                    <p><b>Model:</b> {selectedItem.modelnum}</p>
                                    <p><b>Serial:</b> {selectedItem.serialnum}</p>
                                    <p><b>Asset Num:</b> {selectedItem.assetnum}</p>
                                    <p><b>Location:</b> {selectedItem.location}</p>
                                    <p><b>Dept:</b> {selectedItem.deptdescription}</p>
                                    <p><b>Manufacturer:</b> {selectedItem.manufacturer}</p>
                                </React.Fragment>

                                : null}
                        </div>
                        <SimpleTable columns={[
                            {label: "Notes", key: "Notes"},
                            {label: "Days", key: "DaysBetween"},
                            {label: "Active", key: "Active", formatFunc:toTrueFalse},
                            {label: "Category", key: "IntervalCategory", formatFunc:toTrueFalse},
                            {label:"", key:"", rawFormat:(val) =><React.Fragment>
                                    <button className={"btn btn-outline-primary"} onClick={() => this.setState({selectedRecord:val},
                                        () => RecurringTaskModal.display())}>Edit</button>
                                </React.Fragment>}
                        ]}
                                     table_data={this.state.allTasks}/>

                    </div>
                </div>
            </div>


        </React.Fragment>
    }


}