import {DefaultState} from "../App";
import React from "react";
import {getPageOptions, parseDate} from "../../util/FormatUtil";
import domain from "../../Domain";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import Table from "../tables/Table";

interface QualityState extends DefaultState{
    customers:[]
    table_data:[]
    filter:{}
    page_options:any[];
    selected_page:any
}
class Quality extends React.Component<any, QualityState>{
    private pageRef: React.RefObject<unknown>;

    constructor(props) {
        super(props);
        this.state = {customers: [], table_data: [], filter:{}, page_options: getPageOptions(1), selected_page:"1"};
        this.pageRef = React.createRef();
        this.querySamples = this.querySamples.bind(this);
    }

    querySamples(page){
        let self = this;
        this.setState({show_loading: true}, ()=> {
            fetch(domain + (this.props.route ? this.props.route : "/api/samples/expiring?page=" + page), {
                method: 'POST',
                body: JSON.stringify(this.state.filter),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.table_data) {
                        self.setState({
                            table_data: data.table_data,
                            page_options: getPageOptions(data.num_pages)
                        })
                    } else if (!data.authenticated && data.redirect) {
                        alert("Please sign in")
                        window.location.replace('/api/microsoft/signin')
                    } else if (!data.authenticated && !data.redirect) {
                        alert("You do not have permission to view this.")
                    }
                    self.setState({show_loading: false})
                });
        });
    }

    componentDidMount(): void {
        let self = this;
        this.querySamples(1);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <Overlay showLoading={this.state.show_loading}/>
                <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-1 ">
                    <div className={"row"}>
                        {/*<div className="col-xl-4 col-lg-6 col-md-6  col-12">*/}
                        {/*    <div className={"card shadow-lg primary-black-bg white"}>*/}
                        {/*        <div className="card-header green-border ">*/}
                        {/*            <h4>Sample Filtering</h4>*/}
                        {/*        </div>*/}
                        {/*        <div className={"card-body"}>*/}
                        {/*            <FilterCard*/}

                        {/*                fields={[*/}
                        {/*                    {key: "customers", label: "Customer", isMulti:true, type: FIELD_TYPE.SELECT, options:this.state.customers, isMapped: true},*/}
                        {/*                    {key: "TestType", label: "Test", type: FIELD_TYPE.SELECT, options: [{label:"PCR", value:"2019 Novel Coronavirus (COVID-19) PCR Test"}, {label:"EIA", value:"COVID-19 Antibody EIA Test (Test Code: COV2Ab)"}], noOpMessage: "Select a Test", isMapped:true, isMulti:true},*/}
                        {/*                    {key: "result", label: "Result", type: FIELD_TYPE.SELECT, options: [{label:"Positive", value:"Positive"},{label:"Negative", value:"Negative"},{label:"Not Detected", value:"Not Detected"}, {label:"Inconclusive", value:"Inconclusive"}], noOpMessage: "Select a Result", isMapped:true, isMulti:true},*/}
                        {/*                    {key: "gender", label: "Gender", type: FIELD_TYPE.SELECT, options: [{label:"Male", value:"Male"},{label:"Female", value:"Female"}], noOpMessage: "Select a Gender", isMapped:true, isMulti:true},*/}
                        {/*                    {key: "result_start_date", label: "Start Result Date", type: FIELD_TYPE.DATE},*/}
                        {/*                    {key: "result_end_date", label: "End Result Date", type: FIELD_TYPE.DATE},*/}
                        {/*                    {key: "first_name", label: "First Name", type: FIELD_TYPE.TEXT},*/}
                        {/*                    {key: "last_name", label: "Last Name", type: FIELD_TYPE.TEXT},*/}

                        {/*                ]}*/}
                        {/*                filterChanged={(filter) => {*/}
                        {/*                    // console.log("Filter changed", filter)*/}
                        {/*                    this.setState({filter: filter})*/}
                        {/*                }*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="card-footer green-border-top">*/}
                        {/*            <button className={"btn btn-outline-info"} onClick={(e) =>this.querySamples(1)}>Apply*/}
                        {/*            </button>*/}
                        {/*            <button className={"btn btn-outline-danger float-right"}*/}
                        {/*                    onClick={() => window.location.reload()}>Clear*/}
                        {/*            </button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        <div className="col-12 pb-2 pt-2">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Samples
                                        <div className=" align-middle float-right pages">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "Failed to Load"}
                                                // inputValue={this.state.selected_page}
                                                // ref={this.pageRef}
                                                onChange={(e) =>{
                                                    this.setState({selected_page:e.value},()=>
                                                        this.querySamples(e.value))
                                                }}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div></h4>
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                    <Table columns={[
                                        {label: "Name", key: "name",},
                                        {label: "DOB", key: "PatientDOB", formatFunc:parseDate},
                                        {label: "Sample Origin", key: "customer.FacilityName", isOwnModalData:true, modal_data:true},
                                        {label: "Sample Type", key: "SampleType",},
                                        {label: "Collection Date", key: "CollectionDate", formatFunc:parseDate},
                                        {label: "IMMY UID", key: "IMMYUID",},
                                        {label: "Specimen UID", key: "SpecimenUID",},
                                        {label: "Test", key: "TestType", formatFunc: (data) => {
                                                if(data === "2019 Novel Coronavirus (COVID-19) PCR Test") {
                                                    return "PCR"
                                                } else if(data === "COVID-19 Antibody EIA Test (Test Code: COV2Ab)"){}
                                                return "EIA"
                                            }},
                                        {label:"Time", key:"time", formatFunc:(data) => {
                                                let hours = data / 60 / 60
                                                let minutes = data / 60 % 60
                                                return Math.floor(data/60/60) + " Hours" + " " + Math.floor(minutes) + " minutes"
                                            }}
                                    ]}
                                           table_data={this.state.table_data ? this.state.table_data : []}
                                           load_modal={this.props.open_modal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
}

export default Quality;