
export const getItems = (productLine:string, map) =>{
    return Object.keys(map[productLine]).filter(k=> k !== "total" && k !== "total_units");
}


export const getAllItems = (productLines, map) =>{
    let items = [];
    if(productLines)
        productLines.forEach(l =>{
            items = items.concat(getItems(l, map))
        })
    return items;
}

export const sortByValue = (product_data) =>{
    function comp_total(a,b){
        return (a[1].total > b[1].total) ? 1 : ((b[1].total > a[1].total) ? -1 : 0)
    }

    product_data = Object.entries(product_data).sort(comp_total).reverse()
    product_data.forEach((val, index) => {  //Sort nested items
        product_data[index][1] = Object.entries(product_data[index][1]).sort(comp_total).reverse();
    });
    return product_data;
}

export const getItemTotals = (product_data, product, item):{total, units} => {
    if(!product_data[product])
        return {total:0, units:0}
    if(!product_data[product][item])
        return {total:0, units:0}
    return product_data[product][item]
}