import React from "react"
import Modal_Header from "./Modals"
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import domain from "../../Domain"

interface ModalFormState{
    filter?:any
}

interface ModalFormProps{
    title:string
    ID:string
    onSubmit:(any) => void
    fields:any[]
}

class ModalForm extends React.Component<ModalFormProps, ModalFormState>{

    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e){
        this.props.onSubmit(this.state.filter);
    }


    render() {
        let title = this.props.title;
        return (
            <div className="modal fade form_modal" id={this.props.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        {/*<Modal_Header title={title}/>*/}
                        <div className="modal-header">
                        <h5 className="modal-title" id="result_modal_label">{this.props.title}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                        <div className="modal-body ">
                            <FilterCard
                                fields={this.props.fields}
                                filterChanged={(filter) => this.setState({filter: filter})}
                            />

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalForm;