import React from "react";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import domain from "../../Domain";
import Table from "../tables/Table";
import {
    formatMoney, getFormattedPercentageChange,
    getPercentageChange,
    htmlEscape,
    numberWithCommas,
    parseDate,
    toPercentage
} from "../../util/FormatUtil";
import {getItemTotals, sortByValue} from "../../util/ProductMapUtils";
import Overlay from "../Overlay";
import {DefaultProps} from "../App";
import {getCurrentFYStart, getCurrentFYStartYear, todayLastFY} from "../../util/FYDateUtil";
import {LineChart} from "./LineChart";
import palette from "google-palette"
interface SalesCompProps extends DefaultProps{

}
interface SalesData{
    total_shipped_sales:number
    total_shipped_cost:number
    total_shipped_margin:number
    shipping_total:number
    current_month_sales
    product_data
    start_date

    end_date
}
interface SalesCompState {
    location_filters:any,
    filter:any,
    sales:[],
    item_map:[];
    show_loading:boolean
    diff:SalesData
    sale_totals_one:SalesData
    sale_totals_two:SalesData
    new_timeframe_stats:SalesData
    winners
    losers
    new_timeframe_revenue
    graph_one:{[key:number]:{[key:number]:{total_shipped_sales:number, units:number}}}
    graph_two:{[key:number]:{[key:number]:{total_shipped_sales:number, units:number}}}
    colors:string[]
    dataToGraph?
}

export class SalesComparison extends React.Component<SalesCompProps, SalesCompState>{
    constructor(props) {
        super(props);
        let date = new Date();
        let colors = palette('mpn65', 2);
        colors = colors.map(c => "#"+c);
        this.state = {
            losers: undefined, winners: undefined, new_timeframe_revenue: undefined,
            diff: {} as SalesData,
            show_loading: true,
            sale_totals_one: {} as SalesData,
            filter: {
                start_date_one: getCurrentFYStartYear() - 1 + getCurrentFYStart().slice(4),
                end_date_one: todayLastFY(),
                start_date_two: getCurrentFYStart(),
                end_date_two: "" + (date.getFullYear()) + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0')
            }

            , item_map: [], location_filters: {countries: []}, sales: [],
            sale_totals_two: {} as SalesData,
            new_timeframe_stats: {} as SalesData,
            graph_one: undefined,
            graph_two: undefined,
            dataToGraph:"sales",
            colors:colors
        }


        this.onSubmit = this.onSubmit.bind(this);
    }

    getHighlight(num:number){
        if(!num || num === 0)
            return null;

        return num > 0 ?  "light-green" : "light-red";
    }

    buildItemTable(title, data){
        return ( <div className={"card shadow-lg primary-black-bg white"}>
            <div className="card-header green-border text-center">
                <h4>{title}</h4>
            </div>
            <div className={"card-body p-0 m-0 "}>
                <Table
                    columns={[{
                        label: "Customer",
                        key: "customer.name",
                        modal_key: "customer",

                    },
                        {
                            label: "Family",
                            key: "product_family",
                        },
                        {
                            label: "Item",
                            key: "item",
                        },
                        {
                            label: "Total",
                            key: "values.total",
                            formatFunc:formatMoney,
                        },
                        {
                            label: "Units",
                            key: "values.units",
                            formatFunc: numberWithCommas,
                        },

                    ]}
                    table_data={data ? data : null}
                    table_style={"table-borderless"}
                    load_modal={this.props.open_modal}/>
            </div>
        </div>)
    }

    buildSalesTable(sales_data:SalesData, title, id, highlight){
        let product_data = sales_data.product_data ? sortByValue(sales_data.product_data) : [];
        return(          <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
            <div className={"card shadow-lg primary-black-bg white"}>
                <div className="card-header green-border text-center">
                    <h4>{title} </h4>
                    <p className={"text-center"}>{sales_data.start_date ? parseDate(sales_data.start_date) :"All"} to {sales_data.end_date ? parseDate(sales_data.end_date) : "All"}</p>
                </div>
                <div className={"card-body p-0 m-0 "}>
                    <Table columns={[{
                        label: "Total Product Sales: ",
                        key: "total_shipped_sales",
                        formatFunc: formatMoney,
                    },
                        {
                            label: "Total S&H: ",
                            key: "shipping_total",
                            formatFunc: formatMoney,
                        },
                        {
                            label: "Total Cost: ",
                            key: "total_shipped_cost",
                            formatFunc: formatMoney,
                            extraCol: [{raw: toPercentage(sales_data.total_shipped_cost, sales_data.total_shipped_sales)}],
                        },
                        {
                            label: "Total Margin: ",
                            key: "total_shipped_margin",
                            formatFunc: formatMoney,
                            extraCol: [{raw: toPercentage(sales_data.total_shipped_margin, sales_data.total_shipped_sales)}],
                            isBreakPoint:true
                        },
                    ]}
                           isHorizontal={true}
                           isMap={true}
                           table_data={sales_data ? sales_data : null}
                           table_style={"table-borderless"}/>
                    <table className={"table table-borderless p-0 m-0"}>
                        <tbody>

                        {product_data ? product_data.map((value) => {
                            let product_key = value[0];
                            let items = value[1];
                            let total = sales_data.product_data[product_key] ? sales_data.product_data[product_key].total : 0; //Already sorted so total is at the top
                            let total_units = sales_data.product_data[product_key] ? sales_data.product_data[product_key].total_units : 0; //Already sorted so total is at the top
                            return (<React.Fragment>
                                <tr>
                                    <td><a role={"button"} data-toggle={"collapse"}
                                           href={"#" + htmlEscape(value[0])}> {value[0]}</a>
                                    </td>
                                    <td>{formatMoney(total)}</td>
                                    <td>{toPercentage(total, sales_data.total_shipped_sales)}</td>
                                    <td>{numberWithCommas(total_units)} units</td>
                                </tr>
                                {items.map((item) => {
                                    let item_name = item[0];
                                    if (item_name === "total" || item_name === "total_units") {
                                        return null
                                    }
                                    let totals = getItemTotals(sales_data.product_data, product_key, item_name);
                                    let total_sale = totals ? totals.total : 0;
                                    let total_unit = totals ? totals.units : 0;
                                    return (<React.Fragment>
                                        <tr className="collapse"
                                            id={htmlEscape(value[0] )}>
                                            <td>{item_name}</td>
                                            <td>{formatMoney(total_sale)}</td>
                                            <td>{toPercentage(total_sale, sales_data.total_shipped_sales)}</td>
                                            <td>{numberWithCommas(total_unit)} units</td>
                                        </tr>
                                    </React.Fragment>)
                                })}
                            </React.Fragment>)
                        }) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>)
    }


    componentDidMount(): void {
        fetch(domain + "/api/items/map").then(res => res.json())
            .then(data => this.setState({
                item_map:data
            }))
            .catch(e => console.log("Failed to fetch locations" +  e));
        fetch(domain + "/api/employees/sales").then(res => res.json())
            .then(data => {
                console.log("Data", data);
                data = data.map(d => {
                    d.label = d.name;
                    d.value = d.name;
                    return d;
                });
                this.setState({sales: data})
            })
            .catch(console.log)

        fetch(domain + "/api/location/all").then(res => res.json())
            .then(data => this.setState({
                location_filters: {
                    states: data.states,
                    countries: data.countries
                },
            }))
            .catch(e => console.log("Failed to fetch locations" +  e));
        this.queryComparison()
    }

    queryComparison(){
        const filter = JSON.parse(JSON.stringify(this.state.filter));

        filter.customers =  filter.customers ? filter.customers.map(val => val.value ? val.value : val) : [];
        this.setState({show_loading:true}, ()=>{
            fetch(domain+"/api/orders/comparison", {
                method: 'POST',
                body: JSON.stringify(filter),
                headers: {
                    'Content-Type': 'application/json'
                }}).then(res=>res.json()).then(data=>{
                console.log("COMP DATA", data);
                this.setState({
                    sale_totals_one:data.stats_one,
                    sale_totals_two: data.stats_two,
                    diff:data.diff,
                    show_loading:false,
                    winners:data.winners,
                    losers:data.losers,
                    new_timeframe_revenue:data.new_timeframe_revenue,
                    new_timeframe_stats:data.new_timeframe_stats,
                    graph_one:data.graph_one,
                    graph_two:data.graph_two
                })
            }).catch(e => {
                console.error(e);
                this.setState({show_loading:false});
            })
        })
    }

    onSubmit(){
        this.queryComparison();
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log(this.state.filter);
        let countries = [];
        this.state.location_filters.countries.forEach((value, key) => {
            countries.push(value.country);
        });


        let states = [];
        if (this.state.filter.countries)
            this.state.location_filters.states.forEach(state => {
                this.state.filter.countries.some(c => {
                    if (state.country === c) {
                        states.push({label: state.description, value: state.state});
                        return true;
                    }
                    return false;
                })
            });

        let items = [];
        if(this.state.filter.products){
            this.state.filter.products.forEach((p:string)=>{
                items = items.concat(this.state.item_map[p].map(i=> ({label:i.Product, value:i.Product})))
            })
        }
        let diffProductData = this.state.diff.product_data ? sortByValue(this.state.diff.product_data) : [];
        console.log(this.state.dataToGraph);
        let keyToGraph = (this.state.dataToGraph.value ? this.state.dataToGraph.value : this.state.dataToGraph[0]) === "sales" ? "total_shipped_sales" : "units";
        let graph_one = {data:[], borderColor:this.state.colors[0], label:"Time One", lineTension: 0,};
        let startPushingOne = false;
        let graph_two = {data:[], borderColor:this.state.colors[1], label:"Time Two", lineTension: 0,};
        let startPushingTwo = false;
        for(let i = 2013; i <= new Date().getUTCFullYear(); i++){
            for(let j = 0; j < 12; j++){

                if(this.state.graph_one && this.state.graph_one[i + 1] && this.state.graph_one[i  + 1][j]){
                    let thisMonth = new Date(i +2, j, 1);
                    let data = this.state.graph_one[i + 1][j][keyToGraph];
                    if(!startPushingOne && data !== 0){
                        startPushingOne = true;
                    }
                    if(startPushingOne && (data !== 0 || graph_one.data.length < 12))
                        graph_one.data.push({y:data,t:thisMonth});
                }
                if(this.state.graph_two && this.state.graph_two[i] && this.state.graph_two[i][j]){
                    let thisMonth = new Date(i, j, 1);
                    let data = this.state.graph_two[i ][j][keyToGraph];
                    if(!startPushingTwo && data !== 0){
                        startPushingTwo = true;
                    }
                    if(startPushingTwo && (data !== 0 || graph_two.data.length < 12))
                        graph_two.data.push({y:this.state.graph_two[i][j][keyToGraph],t:thisMonth});
                }
            }
        }
        return (<React.Fragment>
            <Overlay showLoading={this.state.show_loading}/>
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                <div className={"row"}>
                    <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Filtering</h4>
                            </div>
                            <div className={"card-body"}>
                                <FilterCard
                                    fields={[
                                        {
                                            key: "customers",
                                            label: "Customer",
                                            type: FIELD_TYPE.SEARCH_RESULT,
                                            result_types: ["bill_to", "ship_to"],
                                            isMulti:true
                                        },
                                        {
                                            key: "products",
                                            label: "Product Line",
                                            type: FIELD_TYPE.SELECT,
                                            options: this.state.item_map ? Object.keys(this.state.item_map) : null,
                                            isMulti:true
                                        },
                                        {
                                            key: "items",
                                            label: "Item",
                                            type: FIELD_TYPE.SELECT,
                                            options: items,
                                            isMapped: true,
                                            noOpMessage: "Select a Product",
                                            isMulti:true
                                        },
                                        {
                                            key: "sales_reps",
                                            label: "Sales Rep.",
                                            type: FIELD_TYPE.SELECT,
                                            options: this.state.sales,
                                            isMapped: true,
                                            isMulti:true
                                        },
                                        {key: "start_date_one", label: "Start Date 1", type: FIELD_TYPE.DATE, default:this.state.filter.start_date_one},
                                        {key: "end_date_one", label: "End Date 1", type: FIELD_TYPE.DATE, default:this.state.filter.end_date_one},
                                        {key: "start_date_two", label: "Start Date 2", type: FIELD_TYPE.DATE, default:this.state.filter.start_date_two},
                                        {key: "end_date_two", label: "End Date 2", type: FIELD_TYPE.DATE, default:this.state.filter.end_date_two},
                                        {
                                            key: "countries",
                                            label: "Country",
                                            type: FIELD_TYPE.SELECT,
                                            options: countries,
                                            isMulti:true
                                        },
                                        {
                                            key: "states",
                                            label: "State",
                                            type: FIELD_TYPE.SELECT,
                                            options: states,
                                            isMapped: true,
                                            noOpMessage: "Select a Country",
                                            isMulti:true
                                        },
                                    ]}
                                    filterChanged={(filter) => this.setState({filter: filter})}
                                />
                            </div>

                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={this.onSubmit}>Apply
                                </button>
                                <button className={"btn btn-outline-danger float-right"}
                                        onClick={() => window.location.reload()}>Clear
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-8 col-lg-6 col-md-6  col-12">
                        <div className={"card shadow-lg primary-black-bg white"}>
                        <div className="card-header green-border ">
                            <h4>Comparisons Over Time</h4>
                        </div>
                        <div className={"card-body"}>

                            <LineChart datasets={[graph_one, graph_two]} labels={[]} chartYAxe={[{
                                ticks:{
                                    fontColor:"#ffffe4",
                                    callback: (value) =>{
                                        let keyToGraph = (this.state.dataToGraph.value ? this.state.dataToGraph.value : this.state.dataToGraph[0]) === "sales" ? "total_shipped_sales" : "units";
                                        if(keyToGraph === "units")
                                            return numberWithCommas(Number(value));
                                        // Convert the number to a string and splite the string every 3 charaters from the end
                                        // value = value.toString();

                                        const formatter = new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 2
                                        });
                                        formatter.format(parseFloat(value as string));
                                        return '$ ' + numberWithCommas(Number(value));
                                    },
                                },
                                gridLines:{
                                    color:"#FFF"
                                }}]}
                                       chartXAxe={[{
                                           type:'time',
                                           time:{
                                               unit:'month'
                                           },
                                           ticks:{fontColor:"#FFF"},
                                           gridLines:{
                                               color:"#ffffe4"
                                           },
                                           distribution: 'linear'
                                       }]}
                                       // tooltips={{
                                       //     callbacks:{
                                       //         afterLabel(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                                       //             // @ts-ignore
                                       //
                                       //             return "Lot: " + (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].obj.lot : "");
                                       //         }
                                       //     }
                                       //     // intersect:false,
                                       //     // callbacks:{
                                       //     //     label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                                       //     //         console.log("TT ITem, ", tooltipItem)
                                       //     //         console.log(data.datasets[0].data[tooltipItem.index])
                                       //     //         console.log("Dataset 0,", data.datasets[0])
                                       //     //         // @ts-ignore
                                       //     //         return "Lot: " + (data.datasets[0].data[tooltipItem.index] ? data.datasets[0].data[tooltipItem.index].obj.lot : "");
                                       //     //     }
                                       //     // }
                                       // }}
                            />
                            <div className={"pt-3"}>
                                <p>Compares Start Date 1 - End Date 1 (offset by 1 year) to Start Date/End 2. For example, looking at FY2019 to date to FY2020 to date. The X-axis is built from Start Date 2 - End Date 2.</p>
                            <FilterCard  fields={[{ isMapped:true, key:"toGraph",label:"Data to Graph",type:FIELD_TYPE.SELECT, options:[{label:"Shipped Sales",value:"sales"},{ label:"Qty. Shipped", value:"qty"}], default:{label:"Shipped Sales", value:"sales"}},] } filterChanged={(e)=>this.setState({dataToGraph:e.toGraph})}/>

                            </div>
                        </div>
                            <div className="card-footer green-border-top">
                                {/*<button className={"btn btn-outline-info"} onClick={this.onSubmit}>Apply*/}
                                {/*</button>*/}

                            </div>
                        </div>
                    </div>

                </div>
                <div className={"row pt-2"}>
                    {this.buildSalesTable(this.state.sale_totals_one, "Shipped Totals One", "sale_comp_one", false)}
                    {this.buildSalesTable(this.state.sale_totals_two, "Shipped Totals Two", "sale_comp_two", false)}
                    {/*{this.buildSalesTable(this.state.diff, "Difference", "sale_comp_diff", true)}*/}
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border text-center">
                                <h4>Difference </h4>
                                <p>(Totals 2 - Totals 1)</p>
                            </div>
                            <div className={"card-body p-0 m-0 "}>
                                <Table columns={[{
                                    label: "Total Product Sales: ",
                                    key: "total_shipped_sales",
                                    formatFunc: formatMoney,
                                    content_class:this.getHighlight(this.state.diff.total_shipped_sales),
                                    extraCol: [{raw:  getFormattedPercentageChange(this.state.sale_totals_two.total_shipped_sales, this.state.sale_totals_one.total_shipped_sales),
                                        content_class:this.getHighlight(getPercentageChange(this.state.sale_totals_two.total_shipped_sales, this.state.sale_totals_one.total_shipped_sales))}],
                                },
                                    {
                                        label: "Total S&H: ",
                                        key: "shipping_total",
                                        formatFunc: formatMoney,
                                    },
                                    {
                                        label: "Total Cost: ",
                                        key: "total_shipped_cost",
                                        formatFunc: formatMoney,
                                        extraCol: [{raw: getFormattedPercentageChange(this.state.sale_totals_two.total_shipped_cost, this.state.sale_totals_one.total_shipped_cost)}],
                                    },
                                    {
                                        label: "Total Margin: ",
                                        key: "total_shipped_margin",
                                        formatFunc: formatMoney,
                                        extraCol: [{raw: getFormattedPercentageChange(this.state.sale_totals_two.total_shipped_margin, this.state.sale_totals_one.total_shipped_margin)}],
                                        isBreakPoint:true
                                    },
                                ]}
                                       isHorizontal={true}
                                       isMap={true}
                                       table_data={this.state.diff ? this.state.diff : null}
                                       table_style={"table-borderless"}/>
                                <table className={"table table-borderless p-0 m-0"}>
                                    <tbody>

                                    {diffProductData ? diffProductData.map((value) => {
                                        let product_key = value[0];
                                        let items = value[1];
                                        let mappedValues = this.state.diff.product_data[product_key];
                                        let total = mappedValues ? mappedValues.total : 0; //Already sorted so total is at the top
                                        let total_units = mappedValues ? mappedValues.total_units : 0; //Already sorted so total is at the top
                                        let salesTwoTotals = this.state.sale_totals_two.product_data[product_key] ? this.state.sale_totals_two.product_data[product_key].total : 0
                                        let salesOneTotals = this.state.sale_totals_one.product_data[product_key] ? this.state.sale_totals_one.product_data[product_key].total : 0
                                        return (<React.Fragment>
                                            <tr>
                                                <td><a role={"button"} data-toggle={"collapse"}
                                                       href={"#" + htmlEscape(value[0])}> {value[0]}</a>
                                                </td>
                                                <td className={this.getHighlight(total)}>{formatMoney(total)}</td>
                                                <td className={this.getHighlight( getPercentageChange(salesTwoTotals, salesOneTotals))}>{getFormattedPercentageChange(salesTwoTotals, salesOneTotals)}</td>
                                                <td className={this.getHighlight(total_units)}>{numberWithCommas(total_units)} units</td>
                                            </tr>
                                            {items.map((item) => {
                                                let item_name = item[0];
                                                if (item_name === "total" || item_name === "total_units") {
                                                    return null;
                                                }
                                                let diffTotals = getItemTotals(this.state.diff.product_data, product_key, item_name);
                                                let total_sale = diffTotals ? diffTotals.total : 0;
                                                let total_unit = diffTotals ? diffTotals.units : 0;
                                                let itemTotalsOne = getItemTotals(this.state.sale_totals_two.product_data, product_key, item_name) ? getItemTotals(this.state.sale_totals_two.product_data, product_key, item_name).total : 0
                                                let itemTotalsTwo =  getItemTotals(this.state.sale_totals_one.product_data, product_key, item_name) ? getItemTotals(this.state.sale_totals_one.product_data, product_key, item_name).total : 0
                                                return (<React.Fragment>
                                                    <tr className="collapse"
                                                        id={htmlEscape(value[0] )}>
                                                        <td>{item_name}</td>
                                                        <td className={this.getHighlight(total_sale)}>{formatMoney(total_sale)}</td>
                                                        <td className={this.getHighlight(total_sale/this.state.diff.total_shipped_sales)}>
                                                            {getFormattedPercentageChange(itemTotalsOne, itemTotalsTwo)}</td>
                                                        <td className={this.getHighlight(total_unit)}>{numberWithCommas(total_unit)} units</td>
                                                    </tr>
                                                </React.Fragment>)
                                            })}
                                        </React.Fragment>)
                                    }) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        {this.buildItemTable("Largest Gains", this.state.winners)}
                    </div>
                    <div className={"col-6"}>
                        {this.buildItemTable("Largest Losses", this.state.losers)}
                    </div>
                </div>
                <div className={"row pt-2"}>

                        {this.buildSalesTable(this.state.new_timeframe_stats, "New Revenue from Time 1 to Time 2", "new_timeframe_stats", false)}

                    <div className={"col-6"}>
                        {this.buildItemTable("New Revenue from Time 1 to Time 2", this.state.new_timeframe_revenue)}
                    </div>
                </div>

            </div>
        </React.Fragment>)
    }
}