import React from "react";
import Table from "../tables/Table";
import {parseDate} from "../../util/FormatUtil";
import {ModalBodyProps} from "./Modals";
import {HorizontalTable} from "../tables/HorizontalTable";

export default class ComplaintModal extends React.Component<{data}, {}>{

    public static display(){
        window['$']("#complaint_modal").modal('show')
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>

            <div className="modal fade" id={"complaint_modal"} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className={"col-6"}>
                                <h5 className="modal-title" id="result_modal_label">Complaint</h5>
                            </div>
                            <div className={"col-5"}>

                            </div>
                            <div className={"col-1"}>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body m-0 p-0">
                            <div className={"row m-0 p-0"}>
                                <div className={"col-12 m-0 p-0"}>
                                    <HorizontalTable
                                        columns={[
                                            {label: "Originator", key: "sales.name"},
                                            {label: "CCR", key: "ccr_num"},
                                            {label: "Reason", key: "ccr_reason_code"},
                                            {label: "Product Line", key: "product_line"},
                                            {label: "Item", key: "item"},
                                            {label:"Customer", key:"name"},
                                            {label:"Contact", key:"contact"},
                                            {label:"Phone", key:"phone"},
                                            {label:"Email", key:"email"},
                                            {label:"Fax", key:"fax_num"},
                                            {label:"Complaint", key:"complaint_desc"},
                                            {label:"Cause", key:"cause_description"},
                                            {label:"CCR Action", key:"ccr_action_type"},
                                            {label:"Complain Type", key:"complain_type"},
                                            {label:"Corrective Action", key:"corrective_action"},
                                            {label:"Review Due Date", key:"review_due_date", formatFunc:parseDate},
                                            {label:"Follow Due Date", key:"follow_due_date", formatFunc:parseDate},
                                            {label:"Close Due Date", key:"close_due_date", formatFunc:parseDate},
                                            {label:"Close Date", key:"close_date", formatFunc:parseDate},
                                            {label: "Not Issued Reason", key: "Uf_IMYcapaNotIssuedReason"},
                                            {label: "Date Reported", key: "Uf_IMYccDateReported", formatFunc:parseDate},
                                            {label: "Complaint Category", key: "Uf_IMYcomplaintCategory"},
                                            {label: "Complaint Lot", key: "Uf_IMYcomplaintLot"},
                                            {label: "Event Justification", key: "Uf_IMYeventJustification"},
                                            {label: "Investigation Desc.", key: "Uf_IMYinvestigationDesc"},
                                            {label: "Investigation Necessary", key: "Uf_IMYinvestigationNecessary"},
                                            {label: "Investigation Passed", key: "Uf_IMYinvestResultsPass"},
                                            {label: "No Replacement Reason", key: "Uf_IMYnoReplacementReason"},

                                            {label: "Previous Complaint", key: "Uf_IMYpreviousComplaint"},
                                            {label: "Replacement Lot", key: "Uf_IMYreplacementLot"},
                                            {label: "Replacement Ref", key: "Uf_IMYreplacementRef"},
                                            {label: "Similar Complaint", key: "Uf_IMYsimilarComplaint"},
                                        ]}
                                        table_data={this.props.data}
                                        table_style={"table-striped text-left m-0 p-0"}
                                        header_style={"fw-700"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>)
    }
}