import * as React from "react";
import $ from "jquery"
import NavSearch from "./NavSearch";
import domain from "../Domain";
import {signInWithEmailAndPassword} from "firebase/auth";
import {onAuthStateChanged, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult} from "firebase/auth";
import {auth} from "../FirebaseConfig";

interface navState{
    user?
    showOverlay?
    userName: string
}
class Nav extends React.Component<{}, navState> {

    constructor(props) {
        super(props);
        this.state ={
            userName: ""
        }
    }
    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log(user)
                this.setState({userName: user.displayName})
                // User is signed in.
                // ...
            } else {
                console.log("No user found")
                // User is not signed in.
                // ...
            }
        });
/*        if(!window['location'].pathname.includes('/login') && !window['location'].hostname.includes(":3000")) {
            if(process.env.NODE_ENV !== 'development') {

                fetch(domain + "/api/auth", {
                    method: 'POST',
                    body: JSON.stringify({}),
                    headers: {'Content-Type': 'application/json'}
                }).then(res => res.json())
                    .then(data => {
                        console.log(data)
                        this.setState({user: data.user})
                    }).catch(e => {
                    console.log(e);
                    // @ts-ignore
                    window["location"] = "/login"
                })
            }
        }*/

        $(document).ready(function () {
            // $("#sidebar").mCustomScrollbar({
            //     theme: "minimal"
            // });

            $('#dismiss, .overlay').on('click', function () {
                // hide sidebar
                $('#sidebar').removeClass('active');
                // hide overlay
                $('.overlay').removeClass('active');
            });

            $('#sidebarCollapse').on('click', function () {
                // open sidebar
                $('#sidebar').addClass('active');
                // fade in the overlay
                $('.overlay').addClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });
        });
    }

    signIn = async () => {
        const provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            tenant: 'f1f1b8d0-6c95-49c7-bea5-ad1ad4acace6'
        });
        let userCred = "";
        signInWithPopup(auth, provider).then((result) => {
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            console.log(accessToken);
            fetch("https://graph.microsoft.com/v1.0/me",{
                method: "GET",
                headers: {
                    "Authorization": "Bearer "+accessToken
                }
            }).then(res => res.json())
                .then(data => {
                     console.log(data.displayName)
                    this.setState({userName: data.displayName})
                    }
                ).catch(e => {
                this.setState({showOverlay: false});
                console.log(e)
                console.log("Catch block");
            });
        }).catch((error) => {
            console.log(error)
        })
    }



    render() {
        return (
            <React.Fragment>
                <nav id="sidebar" className={"scrollstyle primary-black-bg"}>

                    <div id={"sidebarWrapper p-0 m-0"}>
                        <div className="row primary-black-bg">
                            <div className="col-12">
                                <button type="button" className="btn btn-outline-light  float-right" id="dismiss">
                                    <i className="fa fa-arrow-left"></i>

                                </button>
                            </div>

                        </div>

                        {/*<div className="sidebar-header">*/}
                        {/*    <h3>immyforce</h3>*/}
                        {/*</div>*/}
                        <div id={"navTagWrapper"}>
                            <ul className="list-unstyled components ">

                                <div className="accordion" id="menuAccordion">
                                    <li className={"active"}>
                                        <a className={"navTag primary-black-bg"} data-toggle="collapse"
                                           data-target="#collapseOne" aria-expanded="false"
                                           aria-controls="collapseOne">
                                            IMMYForce
                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <div id="collapseOne" className="dropDown collapse" aria-labelledby="headingOne"
                                         data-parent="#menuAccordion">
                                        <ul className="list-unstyled" id="homeSubmenu">
                                            <li>
                                                <a href="/openorders">Open Orders</a>
                                            </li>
                                            <li>
                                                <a href="/orders">Order Report</a>
                                            </li>
                                            <li>
                                                <a href="/comparison">Sales Comparisons</a>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <a href="/dashboard">Rep Dashboard</a>*/}
                                            {/*</li>*/}
                                            <li>
                                                <a href="/shipping">Shipping Estimates</a>
                                            </li>
                                            <li>
                                                <a href={"/sos"}>S.O.S. Portal</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <li className={"active"}>
                                        <a className={"navTag primary-black-bg"} data-toggle="collapse"
                                           data-target="#collapseTwo" aria-expanded="false"
                                           aria-controls="collapseTwo">
                                            IMMYBooks
                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <div id="collapseTwo" className="dropDown collapse" aria-labelledby="headingOne"
                                         data-parent="#menuAccordion">
                                        <ul className="list-unstyled" id="homeSubmenu">
                                            <li>
                                                <a href="/expensereport">Expense Report</a>
                                            </li>
                                            <li>
                                                <a href="/vouchers">Vouchers</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <li className={"active"}>
                                        <a className={"navTag primary-black-bg"} data-toggle="collapse"
                                           data-target="#collapseThree" aria-expanded="false"
                                           aria-controls="collapseThree">
                                            IMMYTrack
                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <div id="collapseThree" className="dropDown collapse" aria-labelledby="headingOne"
                                         data-parent="#menuAccordion">
                                        <ul className="list-unstyled" id="homeSubmenu">
                                            <li>
                                                <a href="/inventory">Inventory Snapshot</a>
                                            </li>
                                            <li>
                                                <a href="/idcontrol">Id Control</a>
                                            </li>
                                            <li>
                                                <a href="/production">Production Analysis</a>
                                            </li>
                                            <li>
                                                <a href="/qcreport">QC Report</a>
                                            </li>
                                            <li>
                                                <a href="/materials">Materials Analysis</a>
                                            </li>

                                            <li>
                                                <a href="/repair">Maintenance Records</a>
                                            </li>
                                            <li>
                                                <a href="/videojet">Video Jet</a>
                                            </li>
                                            <li>
                                                <a href="/training">Training</a>
                                            </li>

                                            {/*<li>*/}
                                            {/*    <a href="/scrap">Scrap Report</a>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <a href="/videojet">VideoJet Label</a>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <a href="/training">Training Checklist</a>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <a href="/modules">Training Modules</a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>

                  {/*                  <li className={"active"}>
                                        <a className={"navTag primary-black-bg"} data-toggle="collapse"
                                           data-target="#collapseFour" aria-expanded="false"
                                           aria-controls="collapseFour">
                                            IMMYLabs
                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <div id="collapseFour" className="dropDown collapse" aria-labelledby="headingOne"
                                         data-parent="#menuAccordion">
                                        <ul className="list-unstyled" id="homeSubmenu">
                                            <li>
                                                <a href="/samples">Samples</a>
                                            </li>
                                            <li>
                                                <a href="/quality">Quality</a>
                                            </li>
                                            <li>
                                                <a href="/forms">Form Search</a>
                                            </li>
                                            <li>
                                                <a href="/password">Greeter Password</a>
                                            </li>
                                        </ul>
                                    </div>*/}

                                    <li className={"active"}>
                                        <a className={"navTag primary-black-bg"} data-toggle="collapse"
                                           data-target="#collapseFive" aria-expanded="false"
                                           aria-controls="collapseFive">
                                            IMMYLegacy
                                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <div id="collapseFive" className="dropDown collapse" aria-labelledby="headingOne"
                                         data-parent="#menuAccordion">
                                        <ul className="list-unstyled" id="homeSubmenu">
                                           {/* <li>
                                                <a href="/packdaddy">Pack Daddy</a>
                                            </li>*/}
                                            <li>
                                                <a href="/immyzon">IMMYZon</a>
                                            </li>
                                            <li>
                                                <a href="/fulfillment">IMMYZon Fulfillment</a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </ul>
                        </div>
                    </div>
                </nav>

                <div className="container-fluid primary-black-bg p-1">
                    <div className="row m-0">
                        <div className={"col-4"}>
                            <div>
                                <button type="button" className="btn btn-outline-light float-left" id="sidebarCollapse">
                                    <span className="dark-blue-text"><i className="fa fa-bars fa-1x"/></span>
                                </button>
                            </div>
                            <div className={"pl-5 mt-1"}>
                                {this.state.userName ? <p className={"white"}>Hello, {this.state.userName}</p> :
                                    <p className={"white"}>Not logged in.</p>}
                            </div>
                        </div>

                        <div
                            className={"col-4 d-block  input-group pl-0 pr-0"}>
                            <form className="d-inline-block width-100">
                                {/*<SearchResult open_modal={this.props.open_modal}/>*/}
                                <NavSearch/>
                            </form>
                        </div>
                        <div className="col-4 float-right white">

                            <img width="64px" className="img-fluid float-right" src={"/images/IMMYForce.png"}/>
                            <a href={"/historical"}>
                                <button className="btn btn-outline-light float-right ml-3">Historical Search</button>
                            </a>
                            {this.state.userName ? <></> :
                            <button className="btn btn-primary float-right ml-3" onClick={() => this.signIn()}>Log In
                            </button>
                            }
                        </div>
                    </div>
                </div>

                <div className="overlay"/>

            </React.Fragment>
        );
    }
}

export default Nav;