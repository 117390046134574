import React from 'react';
import Select from "react-select";
import domain from "../../Domain";
import Table from "../tables/Table";
import {parseDate, formatMoney, toReactSelectList} from "../../util/FormatUtil"
import $ from "jquery";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import {Column} from "../tables/TableBase";
import {buildDisplayString} from "../tables/TableUtil";
import OppTaskFormModal from '../modals/OppTaskFormModal';
import SimpleHeader from "../tables/SimpleHeader";
import EditOppTaskFormModal from '../modals/OppTaskEditFormModal';
import {OppEditFormModal} from "../modals/OppEditFormModal";
import OppForm from "../modals/OppForm";
import {DefaultProps} from "../App";
import {getAllItems} from "../../util/ProductMapUtils";

interface OppState{
    filter:any,
    sales_reps:any[]
    selected_page:string,
    table_data?:any[]
    show_loading:boolean
    page_options:any[],
    location_filters:any,
    product_data?:any[]
    opp_totals?:any[]
    selected_opp?:any
    num_pages?:any
    selected_task?:any
    item_map:any
}

export default class Opportunities extends React.Component<DefaultProps, OppState> {
    private pageRef: any;


    constructor(props) {
        super(props);
        this.state = {
            selected_page: "",
            show_loading: true,
            page_options: [{value: "1", label: "1"}],
            filter: {products: [], items: [], countries: [], states: []},
            sales_reps: [],
            item_map:{},
            location_filters: {
                states: [],
                countries: []
            }
        };
        this.pageRef = React.createRef();

        this.getPageOptions = this.getPageOptions.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.addOpp = this.addOpp.bind(this);
        this.addTask = this.addTask.bind(this);
    }

    componentDidMount() {
        let getPageOptions = this.getPageOptions;
        fetch(domain + "/api/opportunities?page=1").then(res => res.json())
            .then(data => {
                console.log(data);
                this.setState({
                product_data: data.product_data,
                opp_totals: data.opp_totals,
                table_data: data.table_data,
                num_pages: data.num_pages,
                    item_map:data.item_map,
                page_options: getPageOptions(data.num_pages), show_loading: false
            })})
            .catch(console.log);
        fetch(domain + "/api/location/all").then(res => res.json())
            .then(data => this.setState({
                location_filters: {
                    states: data.states,
                    countries: data.countries
                },
            }))
            .catch(e => console.log("Failed to fetch locations" +  e));
        fetch(domain + "/api/employees/sales").then(res => res.json())
            .then(data => {
                console.log("Data", data)
                data = data.map(d => {
                    d.label = d.name;
                    d.value = d.vend_num;
                    return d;
                })
                this.setState({sales_reps: data})
            })
            .catch(console.log)
    }

    getPageOptions(n) {
        let options = [];
        for (let i = 1; i <= n; i++) {
            options.push({value: i, label: i});
        }
        return options;
    }

    onSubmit(e) {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        console.log(filter);
        filter.customers = filter.customers ? filter.customers.map(val => val.value ? val.value : val) : [];
        this.queryOpportunities(filter, 1);
        this.pageRef.current.setState({value: 1, label: 1});
        return false;
    }

    addOpp(e) {
        // @ts-ignore
        window.$("#opp_form").modal('show');
    }

    editTask(val){
        this.setState({selected_task:val}, ()=>{
            // @ts-ignore
            window.$("#task_edit").modal('show');
        })
    }
    private editOpp(opp: any) {
        this.setState({selected_opp:opp}, ()=>{
            // @ts-ignore
            window.$("#opp_edit_form").modal('show');
        })
    }

    addTask(opp){
        console.log(opp)
        this.setState({selected_task:opp}, ()=>{
            // @ts-ignore
            window.$("#task_form").modal('show');
        })

    }

    queryOpportunities(state_filter, page) {
        let filter = JSON.parse(JSON.stringify(state_filter));
        let getPageOptions = this.getPageOptions;
        console.log(filter)
        this.setState({show_loading: true}, function () {
            $.ajax({
                url: domain + "/api/opportunities?page=" + page,
                type: "POST",
                datatype: "json",
                data: JSON.stringify(filter),
                contentType: "application/json;charset=UTF-8",
                success: data => {
                    console.log("Opp", data);
                    this.setState({
                        table_data: data.table_data,
                        opp_totals: data.opp_totals,
                        page_options: getPageOptions(data.num_pages),
                        item_map:data.item_map
                    })
                },
                complete: data => {
                    this.setState({show_loading: false})
                }
            })
        })
    }

    render() {

        let countries = [];
        this.state.location_filters.countries.forEach((value, key) => {
            countries.push(value.country)
        });


        let states = [];
        if (this.state.filter.countries)
            this.state.location_filters.states.forEach(state => {
                this.state.filter.countries.some(c => {
                    if (state.country === c) {
                        states.push({label: state.description, value: state.state});
                        return true;
                    }
                    return false;
                })
            });
        let cb = () =>{console.log("Callback")
            this.onSubmit(null)}

            let items = [];
        if(this.state.filter.products){
            console.log(this.state.filter.products)
            this.state.filter.products.forEach((p:string)=>{
                console.log(this.state.item_map[p].map(i=> ({label:i.Product, value:i.Product})));
                items = items.concat(this.state.item_map[p].map(i=> ({label:i.Product, value:i.Product})))
            })
        }
        console.log("ITEMS", items)
        return (
            <React.Fragment>
                <OppForm callback={cb} ID={"opp_form"}/>
                <EditOppTaskFormModal callback={cb} ID={"task_edit"} data={this.state.selected_task}/>
                <OppTaskFormModal callback={cb} ID={"task_form"} opp_data={this.state.selected_task}/>
                <OppEditFormModal callback={cb} ID={"opp_edit_form"} data={this.state.selected_opp}/>
                <Overlay showLoading={this.state.show_loading}/>
                <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                    <div className={"row"}>
                        <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Opportunity Filtering</h4>
                                </div>
                                <div className={"card-body"}>
                                    <FilterCard
                                        fields={[
                                            {
                                                key: "customers",
                                                label: "Customer",
                                                type: FIELD_TYPE.SEARCH_RESULT,
                                                result_types: ["bill_to", "ship_to"],
                                                isMulti:true
                                            },
                                            {
                                                key: "products",
                                                label: "Product Line",
                                                type: FIELD_TYPE.SELECT,
                                                options: this.state.item_map ? Object.keys(this.state.item_map) : null,
                                                isMulti:true
                                            },
                                            {
                                                key: "items",
                                                label: "Item",
                                                type: FIELD_TYPE.SELECT,
                                                options: items,
                                                isMapped: true,
                                                noOpMessage: "Select a Product",
                                                isMulti:true
                                            },
                                            {
                                                key: "sales_reps",
                                                label: "Sales Rep.",
                                                type: FIELD_TYPE.SELECT,
                                                options: this.state.sales_reps,
                                                isMapped: true,
                                                isMulti:true
                                            },
                                            {key: "start_date", label: "Created by", type: FIELD_TYPE.DATE},
                                            {key: "end_date", label: "Expected Close", type: FIELD_TYPE.DATE},
                                            {
                                                key: "countries",
                                                label: "Country",
                                                type: FIELD_TYPE.SELECT,
                                                options: countries,
                                                isMulti:true
                                            },
                                            {
                                                key: "states",
                                                label: "State",
                                                type: FIELD_TYPE.SELECT,
                                                options: states,
                                                isMapped: true,
                                                noOpMessage: "Select a Country",
                                                isMulti:true
                                            },
                                        ]}
                                        filterChanged={(filter) => this.setState({filter: filter})}
                                    />
                                </div>
                                <div className="card-footer green-border-top">
                                    <button className={"btn btn-outline-info"} onClick={this.onSubmit}>Apply
                                    </button>
                                    <button className={"btn btn-outline-danger float-right"}
                                            onClick={() => window.location.reload()}>Clear
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Opportunity Totals</h4>
                                </div>
                                <div className={"card-body p-0 m-0"}>
                                    <Table
                                        columns={[{label: "Total Value", key: "total_value", formatFunc: formatMoney}]}
                                        isHorizontal={true}
                                        table_data={this.state.opp_totals ? [this.state.opp_totals] : null}/>
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 pt-3 m-0 tableFixHead min-vh-100"}>
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4 className="d-inline-block">Open Opportunities</h4>
                                    <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                            onClick={this.addOpp}>Add Opportunity
                                    </button>

                                    <div className=" align-middle float-right pages">
                                        <Select
                                            isSearchable={true}
                                            placeholder={"1"}
                                            noOptionsMessage={() => ""}
                                            inputValue={this.state.selected_page}
                                            ref={this.pageRef}
                                            onChange={(e) => this.queryOpportunities(this.state.filter, e.value)}
                                            className={"state_select"}
                                            options={this.state.page_options}
                                        />
                                    </div>
                                    <h5 className="d-inline-block float-right align-middle pr-2">Page </h5>
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                    <table className={"skip-stripe table table-dark table-striped p-0 m-0"}>
                                        <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Sales Rep</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Contact</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Proj. Close Date</th>
                                            <th scope="col">Close Date</th>
                                            <th scope="col">Est. Value</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.table_data ? this.state.table_data.map((opp, index) =>{
                                            return(<React.Fragment>
                                                <tr className={"collapsed " + (((index - 1)  % 2)  === 0 ? "dark-row-override-even" : "dark-row-override-odd")}>

                                                <td><a data-toggle={"collapse"}
                                                        role={"button"}
                                                       href={"#a" + index}>
                                                    {opp.name}
                                                 </a></td>
                                                    <td>{opp.sales_name}</td>
                                                    <td>{opp.customer ? <a href="#!" onClick={() => this.props.open_modal(opp.customer)}>{opp.customer.name}</a> : null}</td>
                                                    <td>{opp.contact ?  <a href="#!" onClick={() => this.props.open_modal(opp.contact)}>{opp.contact.name}</a>  : null}</td>
                                                    <td>{opp.item ? opp.item.item : null}</td>
                                                    <td>{opp.opp_status}</td>
                                                    <td>{parseDate(opp.CreateDate)}</td>
                                                    <td>{parseDate(opp.projected_close_date)}</td>
                                                    <td>{parseDate(opp.close_date)}</td>
                                                    <td>{formatMoney(opp.est_value)}</td>
                                                    <td> <button className={"btn btn-outline-light ml-3 d-inline-block"}
                                                                 onClick={(e) => this.editOpp(opp)}>Edit
                                                    </button></td>
                                                </tr>
                                                <tr className={"collapse "} id={"a" + index}>
                                                    <td className="p-0  m-0 " colSpan={12}>
                                                         <div className={"row p-0 m-0"}>


                                                        <SimpleTable columns={[
                                                                    {label:"Task #", key:"opp_task_num"},
                                                                    {label:"Description", key:"description"},
                                                                    {label:"Type", key:"opp_task_type"},
                                                                    {label:"Status", key:"status", formatFunc: (str) => str === "O" ? "Open" : "Closed"},
                                                                    {label:"Created", key:"CreateDate", formatFunc:parseDate},
                                                                    {label:"Due", key:"due_date", formatFunc:parseDate},
                                                                    {label:"Completed", key:"complete_date", formatFunc:parseDate},
                                                                     {label:"", key:"edit"}
                                                                    ]}
                                                                table_data={opp.tasks}
                                                                    rowBuilder={((table_data, columns) =>{
                                                                        if(!table_data)
                                                                            return
                                                                        return table_data.map((val:any)  => {

                                                                            return (<tr>{
                                                                                    columns.map((col:Column) => {
                                                                                        if(col.key === "edit"){
                                                                                            return (<button onClick={(e)=>this.editTask(val)} className={"btn btn-outline-light"}>Edit Task</button>)
                                                                                        }
                                                                                        return (<td>{buildDisplayString(col, val, null)}</td>)
                                                                                    })
                                                                                }</tr>

                                                                            )
                                                                        })
                                                                    })}/>
                                                                <button onClick={(e)=>this.addTask(opp)} className={"btn btn-outline-light"}>Add New Task</button>
                                                         </div>
                                                    </td>

                                                </tr>
                                                </React.Fragment>
                                            )
                                        }): null}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
