import React from "react"
import QuerySearch from "./query_search/QuerySearch";
import Result_Modal from "./modals/Modals";


class NavSearch extends React.Component<{}, {selected_result?:any}>{

    constructor(props) {
        super(props);
        this.state = {};
        this.onResultSelected = this.onResultSelected.bind(this)
    }


    onResultSelected(e){
        this.setState({selected_result:e.value}, ()=>{
            window['$']("#nav_search_modal").modal('show');
        })
    }


    render() {
        return(
            <div>
                <QuerySearch onChange={this.onResultSelected}/>
                <Result_Modal id={"nav_search_modal"} selected_data={this.state.selected_result}/>
            </div>
        )
    }
}

export default NavSearch;