import React from "react";
import Select from "react-select";
import {parseDate} from "../../util/FormatUtil";
import moment from "moment";
import domain from "../../Domain";
import {EquipmentRecord} from "../../types/EquipmentRecord";
import {TrainingModules} from "../immytrack/TrainingModules";
import {Course} from "../../types/TrainingTypes";

interface EditProps{
    onSubmit
    module:Course
}

export class TrainingModuleModal extends React.Component<EditProps, any>{
    constructor(props) {
        super(props);
        this.state = {}
        this.getAdjustedField = this.getAdjustedField.bind(this);
    }

    getTrueFalseWrap(value){
        return {label:value ? "True" : "False", value: !!value}
    }

    getSelectWrap(value){
        return value ? {label:value, value:value} : null;
    }


    componentWillReceiveProps(nextProps: Readonly<EditProps>, nextContext: any) {
        let keys = Object.keys(this.state);
        let obj = JSON.parse(JSON.stringify(this.state));
        keys.forEach(k => obj[k] = '');
        console.log(nextProps)
        let equipRecord:Course = nextProps.module ? JSON.parse(JSON.stringify(nextProps.module)) : {};
        // if(equipRecord) {
        //
        // }
        this.setState(obj, () => this.setState(equipRecord));
    }

    getReadOnly(key:string, display:string){
        return this.getAdjustedField(key,display,true)
    }

    getMappedLots(arr){
        return arr.map(a => ({label:a.Lot, value:a.Lot}));
    }

    getStateObj(key, val){
        let obj = {};
        obj[key] = val;
        return obj;
    }

    getField(key:string, display:string){
        return this.getAdjustedField(key,display,false);
    }

    getSelectField(key:string, label:string, options){
        return(
            <div className="form-group row">
                <label className={"col-sm-2 col-form-label"}>{label}</label>
                <div className={"col-sm-8 p-0 m-0 "}>

                    <Select
                        onChange={(e)=> {
                            this.setState(this.getStateObj(key, e))}}
                        className={"state_select"}
                        value={this.state[key]}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    getTextArea(key, display){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <textarea cols={3} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }
    getAdjustedField(key, display, readOnly){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <input type={"search"} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       readOnly={readOnly}
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }

    submitForm(){
        let rec:Course = JSON.parse(JSON.stringify(this.state));
        let type:Course = rec as Course;

        if(!type.description || !type.course_num)
            return alert("Please complete all fields.")
        let obj:Course = {
            course_num: rec.course_num,
            description:rec.description
        } as Course
        if(rec.RowPointer)
            obj.RowPointer = rec.RowPointer;
        // rec.PlannedProcedure = rec.PlannedProcedure ? rec.PlannedProcedure.value : "";
        // rec.CompletedDate = rec.CompletedDate ? rec.CompletedDate : null;
        // rec.ScheduledDate = rec.ScheduledDate ? rec.ScheduledDate : null;
        // rec.DownTimeStart = rec.DownTimeStart ? rec.DownTimeStart : null;
        // rec.DownTimeEnd = rec.DownTimeEnd ? rec.DownTimeEnd : null;
        // rec.CompletedBy = rec.CompletedBy ? rec.CompletedBy.value : null;
        // rec.IntervalCategory = rec.IntervalCategory ? rec.IntervalCategory.value : null;
        // rec.TaskType = rec.TaskType ? rec.TaskType.value : null;
        TrainingModuleModal.hide();
        this.props.onSubmit(JSON.parse(JSON.stringify(obj)));
    }

    buildDate(key, display){
        return(<div className="form-group row">
            <label className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type="date" name="start" max="3000-12-31"
                       value={this.state[key]}
                       min="1000-01-01" className="form-control datepicker" onChange={(e)=>  this.setState(this.getStateObj(key, e.target.value))}/>
            </div></div>)
    }


    public static display() {
        window["$"]("#" + TrainingModuleModal.ID).modal('show');
        console.log("display")
    }
    public static hide() {
        window["$"]("#" + TrainingModuleModal.ID).modal('hide');
    }
    public static readonly ID = "moduleCreate"

    render() {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={TrainingModuleModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">{"Training Module"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                {this.getField("course_num", "Course Number")}
                                {this.getField("description", "Description")}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={(e) =>
                                    this.submitForm()}>
                                    Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}