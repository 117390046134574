import React from "react";
import {EquipmentRecord} from "../../types/EquipmentRecord";
import Select from "react-select";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import {onAuthStateChanged, OAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult} from "firebase/auth";
import {auth} from "../../FirebaseConfig";

import Table from "../tables/Table";
import {
    formatMoney,
    getPageOptions,
    getTaskTeams,
    numberWithCommas,
    parseDate, parseMonth,
    toTrueFalse
} from "../../util/FormatUtil";
import domain from "../../Domain";
import {Gage} from "../../types/Gage";
import {EquipmentRecordModal} from "../modals/EquipmentRecordModal";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FBGrid from "./ProductionAnalysis";

interface IMState {
    upcomingJobs: EquipmentRecord[];
    selectedItem: Gage
    equipment: any[]
    filter?
    gageCategories: string[]
    workHistory: EquipmentRecord[];
    showOverlay: boolean
    selectedRecord: EquipmentRecord;
    selectedItemWrapper;
    tasksPages,
    team?: any
    userView: boolean
    CAGridItems;
    colFilter: { products: string[], items: string[] };
    direction: string;
}

export class InventoryManagement extends React.Component<any, IMState> {


    constructor(props) {
        super(props);
        this.state = {
            equipment: [],
            upcomingJobs: [],
            filter: {},
            selectedRecord: {} as EquipmentRecord,
            tasksPages: [{value: "1", label: "1"}],
            direction: 'asc'

        } as IMState;
        this.onExport = this.onExport.bind(this);
        this.handleDelete = this.handleDelete.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
    }

    public queryTasks(page) {
        this.setState({showOverlay: true, upcomingJobs: []}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/tasks"), {
                method: 'POST',
                body: JSON.stringify({page: page, team: this.state.team}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    if (data)
                        for (let i = 0; i < data.tasks.length; i++) {
                            data.tasks[i].ScheduledDate = data.tasks[i].ScheduledDate.slice(0, 7)
                        }
                    console.log(data)
                    this.setState({upcomingJobs: data.tasks, tasksPages: getPageOptions(data.num_pages)})
                }).catch((e) => {
                alert("Failed");
                console.log(e)
            }).finally(() => this.setState({showOverlay: false}));
        })
    }

    handleDelete(r) {
        console.log("R: " + r)
        console.log("R: " + JSON.stringify(r))
        this.setState({showOverlay: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/delete"), {
                method: 'POST',
                body: JSON.stringify({record: r}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    if (data.success) {
                        alert("Deleted");
                        window.location.reload();
                    } else {
                        alert("Failed to delete record.");
                    }
                    this.setState({showOverlay: false});
                });
        })

    }

    handleEdit(r) {
        console.log("Edit: " + r)
        if (!r.EquipmentID)
            return "NONE";

        let gage = this.state.equipment.find(e => e.value.gage_num === r.EquipmentID);
        r.EquipmentID = r.EquipmentID // + " - " + (gage ? gage.value.description : "");
        this.setState({selectedRecord: r},
            () => EquipmentRecordModal.display())
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                let uId = user.providerData[0].uid;

                console.log(user.providerData[0].uid)

                fetch(domain + "/api/firebase/checkInvGroup", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({uId: uId})
                }).then(res => res.json())
                    .then(data => {
                        if (data.CanView) {
                            this.setState({showOverlay: true}, () => {
                                fetch(domain + (this.props.route ? this.props.route : "/api/equipment/"), {
                                    method: 'POST',
                                    body: JSON.stringify({}),
                                    headers: {'Content-Type': 'application/json'}
                                }).then(res => res.json())
                                    .then(data => {
                                        console.log(data)
                                        this.setState({
                                            showOverlay: false,
                                            equipment: data.gages.map(i => ({
                                                label: i.gage_num + " - " + i.description,
                                                value: i
                                            }))
                                        })
                                    }).catch(() => {
                                    alert("Failed");
                                    this.setState({showOverlay: false});
                                });
                            });

                            this.queryTasks(1);

                            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/categories"), {
                                method: 'POST',
                                body: JSON.stringify({}),
                                headers: {'Content-Type': 'application/json'}
                            }).then(res => res.json())
                                .then(data => {
                                    console.log(data)
                                    this.setState({gageCategories: data.types})
                                });
                        } else {
                            alert("You do not have access to this page!")
                        }
                    }).catch(e => {
                    this.setState({showOverlay: false});
                    console.log(e)
                    console.log("Catch block");
                })
            }
        });
    }

    public fetchEquipByID(id) {
        this.setState({showOverlay: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/id"), {
                method: 'POST',
                body: JSON.stringify({id: id}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    this.setState({
                        selectedItemWrapper: data.wrapper,
                        selectedItem: data.wrapper.value,
                        showOverlay: false
                    }, () => this.queryItemRecord())
                });
        })
    }

    sortCATable(hdr, tableData) {
        let rows = tableData;
        //console.log(JSON.stringify(rows), "This is the table data")
        if (rows != null) {
            rows.sort((a, b) => {
                let first = (typeof a[hdr] === 'string') ? a[hdr].toLowerCase() : a[hdr];
                let second = (typeof b[hdr] === 'string') ? b[hdr].toLowerCase() : b[hdr];
                if (first < second) {
                    return this.state.direction == 'asc' ? -1 : 1;
                }
                if (first > second) {
                    return this.state.direction == 'asc' ? 1 : -1;
                }
                return 0;
            });
            this.setState({upcomingJobs: rows})
            if (this.state.direction == 'asc') {
                this.setState({direction: 'desc'})
            } else if (this.state.direction == 'desc') {
                this.setState(({direction: 'asc'}))
            }
        }
    }


    render() {
        //console.log(this.state.selectedItemWrapper)
        //console.log(this.state.filter.category)
        let items = JSON.parse(JSON.stringify(this.state.equipment));
        //console.log(this.state.selectedItem)
        if (this.state.filter.category && this.state.filter.category.length > 0)
            items = items.filter(i => this.state.filter.category.includes(i.value.gagetype))
        let selectedItem = this.state.selectedItem;
        return <React.Fragment>
            {/*Table*/}
            <EquipmentRecordModal equipmentRecord={this.state.selectedRecord}
                                  onSubmit={(r: EquipmentRecord) => this.postRecord(r)}/>
            <Overlay showLoading={this.state.showOverlay}/>
            <div className={"col-12 pt-3 m-0 tableFixHead"}>
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <div className="row">
                            <div className={"col-2"}>
                                <h4 className="d-inline-block">Upcoming Tasks</h4>
                            </div>
                            <div className={"col-1"}>
                                <h5 className="d-inline-block float-right align-middle pr-2">Team </h5>
                            </div>
                            <div className={"col-2"}>
                                <Select
                                    isSearchable={true}
                                    placeholder={"All"}
                                    noOptionsMessage={() => "Failed to Load"}
                                    // ref={this.pageRef}
                                    onChange={(e: ReactSelect) => {
                                        this.setState({team: e.value}, () => this.queryTasks(1))
                                    }}
                                    className={"state_select"}
                                    options={getTaskTeams()}
                                />
                            </div>
                            <div className={"col-4"}></div>
                            <div className={"col-1"}>
                                <h5 className="d-inline-block float-right align-middle pr-2">Page </h5>
                            </div>
                            <div className={"col-2"}>

                                <Select
                                    isSearchable={true}
                                    placeholder={"1"}
                                    noOptionsMessage={() => "Failed to Load"}
                                    // ref={this.pageRef}
                                    onChange={(e: ReactSelect) => {
                                        this.queryTasks(e.value)
                                    }}
                                    className={"state_select"}
                                    options={this.state.tasksPages}
                                />
                            </div>

                        </div>

                    </div>
                    <div className={"card-body table-responsive-lg  p-0 m-0"}>
                        <SimpleTable columns={[
                            {
                                label: "Item", key: "EquipmentID",
                                headerOnClick: () => this.sortCATable('EquipmentID', this.state.upcomingJobs),
                                rawFormat: (val: EquipmentRecord) => {
                                    if (!val.EquipmentID)
                                        return "NONE";

                                    let gage = this.state.equipment.find(e => e.value.gage_num === val.EquipmentID);
                                    return (val.EquipmentID + " - " + (gage ? gage.value.description : ""))
                                },
                                onClick: (val) => {
                                    if (val.EquipmentID)
                                        this.fetchEquipByID(val.EquipmentID)
                                }
                            },
                            {
                                label: "Notes",
                                key: "Notes",
                                headerOnClick: () => this.sortCATable('EquipmentID', this.state.upcomingJobs)
                            },
                            {
                                label: "Scheduled",
                                key: "ScheduledDate",
                                formatFunc: parseMonth,
                                headerOnClick: () => this.sortCATable('ScheduledDate', this.state.upcomingJobs)
                            },
                            {label: "Planned?", key: "PlannedProcedure", formatFunc: toTrueFalse},
                            {label: "Recurring?", key: "Recurring", formatFunc: toTrueFalse},
                            {
                                label: "", key: "", rawFormat: (val) =>
                                    <React.Fragment>
                                        {this.state.userView ?
                                            <button className={"btn btn-outline-primary"}
                                                    onClick={() => this.handleEdit(val)}>Edit</button>
                                            : <div></div>}
                                        <br/><br/>
                                        {this.state.userView ?
                                            <button className={"btn btn-outline-danger"}
                                                    onClick={() => this.handleDelete(val)}>Delete</button>
                                            : <div></div>}
                                    </React.Fragment>
                            }
                        ]}
                                     table_data={this.state.upcomingJobs}/>
                    </div>
                </div>
            </div>
            <div className={"col-12 pt-3 m-0 tableFixHead"}>
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <h4 className="d-inline-block">Equipment History</h4>
                        {/*    <a href={"/tasks"}><button  className={"btn btn-outline-primary"}>Create Recurring Task</button></a>*/}

                    </div>
                    <div className="card shadow-lg primary-black-bg white">
                        <div className="card-header">

                            <div className={"col-6 "}>
                                <FilterCard
                                    fields={[
                                        {
                                            key: "category",
                                            label: "Category",
                                            type: FIELD_TYPE.SELECT,
                                            isMulti: true,
                                            options: this.state.gageCategories,
                                            noOpMessage: "Select a product family"
                                        }]} filterChanged={(e) => this.setState({filter: e})}/>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Items</label>
                                    <div className="col-sm-8  p-0 m-0">
                                        <Select
                                            placeholder={"All"}
                                            noOptionsMessage={() => "Failed to load."}
                                            value={this.state.selectedItemWrapper}
                                            onChange={(e) => {
                                                this.setState({
                                                    selectedItemWrapper: e,
                                                    selectedItem: e ? e.value as unknown as Gage : null
                                                }, () => this.queryItemRecord())
                                            }}
                                            className={"state_select"}
                                            options={items}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card-body table-responsive-lg  p-0 m-0"}>
                        <div className={"col-12"}>
                            <button className={"btn btn-outline-primary"}
                                    onClick={() => this.setState({selectedRecord: {EquipmentID: this.state.selectedItem ? this.state.selectedItem.gage_num : null} as EquipmentRecord}, () => {
                                        if (!this.state.selectedRecord || !this.state.selectedRecord.EquipmentID) {
                                            return alert("No item selected")
                                        }
                                        EquipmentRecordModal.display()
                                    })}>Create Record
                            </button>
                            <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                    onClick={this.onExport}>Export All
                                to Excel
                            </button>

                            {selectedItem ?
                                <React.Fragment>
                                    <p><b>Type:</b> {selectedItem.gagetype}</p>
                                    <p><b>Model:</b> {selectedItem.modelnum}</p>
                                    <p><b>Serial:</b> {selectedItem.serialnum}</p>
                                    <p><b>Asset Num:</b> {selectedItem.assetnum}</p>
                                    <p><b>Location:</b> {selectedItem.location}</p>
                                    <p><b>Dept:</b> {selectedItem.deptdescription}</p>
                                    <p><b>Manufacturer:</b> {selectedItem.manufacturer}</p>
                                </React.Fragment>

                                : null}
                        </div>
                        <SimpleTable columns={[
                            {label: "Task ID", key: "ID"},
                            {label: "Notes", key: "Notes"},
                            {label: "Scheduled", key: "ScheduledDate", formatFunc: parseMonth},
                            {label: "Completed", key: "CompletedDate", formatFunc: parseDate},
                            {label: "Down Time", key: "DownTimeStart", formatFunc: parseDate},
                            {label: "Planned?", key: "PlannedProcedure", formatFunc: toTrueFalse},
                            {label: "Recurring?", key: "Recurring", formatFunc: toTrueFalse},
                            {label: "Completed By", key: "CompletedBy"},
                            {
                                label: "", key: "", rawFormat: (val) => <React.Fragment>
                                    {this.state.userView ?
                                        <button className={"btn btn-outline-primary"}
                                                onClick={() => this.handleEdit(val)}>Edit</button> : <div></div>}
                                    <br/><br/>
                                    {this.state.userView ?
                                        <button className={"btn btn-outline-danger"}
                                                onClick={() => this.handleDelete(val)}>Delete</button>
                                        : <div></div>}
                                </React.Fragment>
                            }
                        ]}
                                     table_data={this.state.workHistory}/>

                    </div>
                </div>
            </div>


        </React.Fragment>;
    }

    private

    queryItemRecord() {
        if (!this.state.selectedItem) {
            alert("Select an item first.")
            return;
        }
        this.setState({showOverlay: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/history"), {
                method: 'POST',
                body: JSON.stringify({item: this.state.selectedItem}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    this.setState({workHistory: data.records})
                }).finally(() => this.setState({showOverlay: false}));
        })
    }

    private postRecord(r:EquipmentRecord) {
        this.setState({showOverlay: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/equipment/record"), {
                method: 'POST',
                body: JSON.stringify({record: r}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    if (data.success) {
                        alert("Saved");
                        EquipmentRecordModal.hide();
                        this.queryTasks(1);

                        if (this.state.selectedItem)
                            this.queryItemRecord();
                    } else {
                        alert("Failed to save record.");
                    }
                    this.setState({showOverlay: false});
                });
        })
    }

    private onExport() {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({showOverlay: true}, () => {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({showOverlay: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "equipment_history.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/equipment/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(null);
        })
    }
}