import {
    formatAddress,
    formatLot,
    formatMoney,
    getTrackingLink,
    numberWithCommas,
    parseDate,toLocalDate
} from "../../util/FormatUtil";
import * as React from "react";
import {ModalBodyProps} from "./Modals";

export default class OrderModal extends React.Component<ModalBodyProps, {}>{
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let data = this.props.data;
        let address = data.ship_to_customer ? formatAddress(data.ship_to_customer) : "";
        let self = this;

        return (
            <table className="table table-striped text-left m-0 p-0">
                <tbody>
                {data.main_order ? (
                        <tr>
                            <th>Master Order</th>
                            <td><a href={"#!"}
                                   onClick={() => this.setState({data:data.main_order})}> {data.main_order.Item}</a>
                            </td>
                        </tr>)
                    : null
                }
                <tr>
                    <th scope="row">Sales Person</th>
                    <td>{data['Sales Person']}</td>
                </tr>
                <tr>
                    <th scope="row">Invoice Number</th>
                    <td>{data['Invoice Num']}</td>
                </tr>
                <tr>
                    <th scope="row">Invoice Date</th>
                    <td>{parseDate(data['Invoice Date'])}</td>
                </tr>
                <tr>
                    <th scope="row">Order</th>
                    <td>{data['Order']}</td>
                </tr>
                <tr>
                    <th scope="row">Order Line</th>
                    <td>{data['Order Line']}</td>
                </tr>
                <tr>
                    <th scope="row">Order Date</th>
                    <td>{toLocalDate(data['OrderDateTime'])}</td>
                </tr>
                <tr>
                    <th scope="row">Ship Date</th>
                    <td>{parseDate(data['Ship Date'])}</td>
                </tr>
                <tr>
                    <th scope="row">Lot</th>
                    <td>{formatLot(data['Lot'])}</td>
                </tr>
                <tr>
                    <th scope="row">Lot Expiration</th>
                    <td>{data['Lot Expiry']}</td>
                </tr>
                <tr>
                    <th scope="row">Serial Number</th>
                    <td>{data['SerialNum']}</td>
                </tr>
                <tr>
                    <th scope="row">Customer PO</th>
                    <td>{data['Customer PO']}</td>
                </tr>
                <tr>
                    <th scope="row">Tracking Number</th>
                    <td>{getTrackingLink(data['Tracking Number'], data["carrier_code"])}</td>
                </tr>
                <tr>
                    <th scope="row">Bill To Customer</th>
                    <td><a href="#!"
                           onClick={() => this.props.load_modal({cust_num:data.bill_to_customer.cust_num , cust_seq:data.bill_to_customer.cust_seq})}>{data.bill_to_customer ? data.bill_to_customer.name : ""}</a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">Ship To Customer</th>
                    <td><a href="#!"
                           onClick={() => this.props.load_modal({cust_num:data.ship_to_customer.cust_num , cust_seq:data.ship_to_customer.cust_seq})}>{data.ship_to_customer ? data['ship_to_customer'].name : ""}</a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">Ship To Address</th>
                    <td>{address}</td>
                </tr>
                <tr>
                    <th scope="row">Product Line</th>
                    <td>{data['product_line']}</td>
                </tr>
                <tr>
                    <th scope="row">Item</th>
                    <td>{data['Item']}</td>
                </tr>
                <tr>
                    <th scope="row">Description</th>
                    <td>{data['Description']}</td>
                </tr>
                <tr>
                    <th scope="row">Qty Ordered</th>
                    <td>{numberWithCommas(data['Qty Ordered'])}</td>
                </tr>
                <tr>
                    <th scope="row">Unit Price</th>
                    <td>{formatMoney(data['Unit Price'])}</td>
                </tr>
                <tr>
                    <th scope="row">Net Price</th>
                    <td>{formatMoney(data['Net Price'])}</td>
                </tr>
                <tr>
                    <th scope="row">Unit Cost</th>
                    <td>{formatMoney(data['Unit Cost'])}</td>
                </tr>
                <tr>
                    <th scope="row">Item Margin</th>
                    <td>{formatMoney(data['Item Margin'])}</td>
                </tr>
                <tr>
                    <th scope="row">Total Shipped Sales</th>
                    <td>{formatMoney(data['Total Shipped Sales'])}</td>
                </tr>
                <tr>
                    <th scope="row">Total Shipped Cost</th>
                    <td>{formatMoney(data['Total Shipped Cost'])}</td>
                </tr>
                <tr>
                    <th scope="row">Total Shipped Margin</th>
                    <td>{formatMoney(data['Total Shipped Margin'])}</td>
                </tr>
                {/*{data["order_lines"] ? (data["order_lines"].map((o, index) => (*/}
                {/*    <tr>*/}
                {/*        <th>Line Item {o["Order Line"]}</th>*/}
                {/*        <td><a href={"#!"} onClick={() => {*/}
                {/*            o.main_order = o.main_order ? o.main_order : data;*/}
                {/*            o.type = "order";*/}
                {/*            o.ship_to_customer = data.ship_to_customer;*/}
                {/*            o.name = data.name + ' line ' + o["Order Line"];*/}
                {/*            self.queryFromModal(o, "order", null)*/}
                {/*        }}>{o["Item"]}</a></td>*/}
                {/*    </tr>*/}
                {/*))) : null}*/}
                </tbody>
            </table>
        )
    }
}