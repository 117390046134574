import React from "react";
import Overlay from "../Overlay";
import {TrainingModuleModal} from "../modals/TrainingModuleModal";
import {Course, TrainingType} from "../../types/TrainingTypes";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import domain from "../../Domain";
import {Employee} from "../../types/Employee";
import {TrainingModal} from "../modals/TrainingModal";
import {parseDate, toTrueFalse} from "../../util/FormatUtil";
import {TrainingEditModal} from "./TrainingEditModal";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../../FirebaseConfig";

interface TrainingState {
    showLoading
    records: { record: TrainingType, course: Course, employee: Employee }[]
    startDates
    endDates
    selectedRecord?: TrainingType
    employees: { label: string, value: string } []
    instructors: {label: string, value: string}[]
    modules: Course[]
    filter?
    courseArray: any
    verification: {label: string, value: string } []
    direction: string;
    userView:boolean
}

export class Training extends React.Component<any, TrainingState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            records: [],
            startDates: [],
            endDates: [],
            employees: [],
            instructors: [],
            modules: [],
            courseArray: [],
            verification: [],
            direction: 'asc',
            userView: false
        };
        this.onExport = this.onExport.bind(this);
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                let uId = user.providerData[0].uid;

                console.log(user.providerData[0].uid)

                fetch(domain + "/api/firebase/checkTrainingGroup", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({uId: uId})
                }).then(res => res.json())
                    .then(data => {
                        if (data.CanView) {
                            this.setState({userView: data.canView})
                            this.queryModules(1);
                            this.setState(() => {
                                fetch(domain + (this.props.route ? this.props.route : "/api/employees/all"), {
                                    method: 'GET',
                                    headers: {'Content-Type': 'application/json'}
                                }).then(res => res.json())
                                    .then(data => {

                                        this.setState({employees: data}, () => {
                                            console.log(this.state.employees)
                                        })
                                    }).catch(() => {
                                    alert("Failed Employees");
                                });
                                fetch(domain + (this.props.route ? this.props.route : "/api/training/verification"), {
                                    method: 'GET',
                                    headers: {'Content-Type': 'application/json'}
                                }).then(res => res.json())
                                    .then(data => {

                                        this.setState({verification: data}, () => {
                                            console.log(this.state.verification)
                                        })
                                    }).catch(() => {
                                    alert("Failed Employees");
                                });
                                fetch(domain + (this.props.route ? this.props.route : "/api/training/instructors"), {
                                    method: 'GET',
                                    headers: {'Content-Type': 'application/json'}
                                }).then(res => res.json())
                                    .then(data => {

                                        this.setState({instructors: data}, () => {
                                        })
                                    }).catch(() => {
                                    alert("Failed Employees");
                                });
                            })

                            fetch(domain + (this.props.route ? this.props.route : "/api/training/course"), {
                                method: 'GET',
                                headers: {'Content-Type': 'application/json'}
                            }).then(res => res.json())
                                .then(data => {
                                    this.setState({courseArray: data})
                                }).catch(() => {
                                alert("Failed Courses");
                            });

                            this.setState(() => {
                                fetch(domain + (this.props.route ? this.props.route : "/api/training/allmodules"), {
                                    method: 'POST',
                                    body: JSON.stringify({page: 1}),
                                    headers: {'Content-Type': 'application/json'}
                                }).then(res => res.json())
                                    .then(data => {
                                        this.setState({modules: data.modules}, () => {
                                            console.log(this.state.modules)
                                        })
                                    }).catch(() => {
                                    alert("Failed");
                                });
                            })
                        } else {
                            alert("You do not have access to this page!")
                        }
                    }).catch(e => {
                    this.setState({showLoading: false});
                    console.log(e)
                    console.log("Catch block");
                })
            }
        });

/*        fetch(domain + (this.props.route ? this.props.route : "/api/fulfillment/auth"), {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(data => {
                console.log("can view:" + JSON.stringify(data))
                this.setState({userView: data.canView})
            });*/

    }

    public queryModules(page) {
        this.setState({showLoading: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/training/records"), {
                method: 'POST',
                body: JSON.stringify({page: page, filter: this.state.filter}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.records.instructor) {
                        let emp = this.props.employees.find(e => e.value === data.records.instructor.trim());
                        if(emp)
                            data.records.instructor = emp;
                    }
                    this.setState({records: data.records})
                }).catch((err) => {
                alert(err);
            }).finally(() => this.setState({showLoading: false}));
        })
    }

    onFilter() {

    }

    onSubmit(training) {
        this.setState({showLoading: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/training/training"), {
                method: 'POST',
                body: JSON.stringify(training),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    if (data.success) {
                        alert("Saved");
                        window.location.reload();
                    } else {
                        alert("Failed to save.")
                    }
                }).catch(() => {
                alert("Failed");
            }).finally(() => this.setState({showLoading: false}));
        })
    }

    onEdit(training) {
        
        this.setState({showLoading: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/training/edit"), {
                method: 'POST',
                body: JSON.stringify(training),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    if (data.success) {
                        alert("Saved");
                        window.location.reload();
                    } else {
                        alert("Failed to save.")
                    }
                }).catch(() => {
                alert("Failed");
            }).finally(() => this.setState({showLoading: false}));
        })
    }

    handleDelete(r) {
        console.log("R: " +r)
        console.log("R: " +JSON.stringify(r))
        if(window.confirm("Are you sure you want to delete this record?") == true) {
            this.setState({showLoading: true}, () => {
                fetch(domain + (this.props.route ? this.props.route : "/api/training/delete"), {
                    method: 'POST',
                    body: JSON.stringify({record: r}),
                    headers: {'Content-Type': 'application/json'}
                }).then(res => res.json())
                    .then(data => {
                        if (data.success) {
                            alert("Deleted");
                            window.location.reload();
                        } else {
                            alert("Failed to delete record.");
                        }
                        this.setState({showLoading: false});
                    });
            })
        } else {

        }
    }

    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({showLoading: true},  function() {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({showLoading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "training_report.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/training/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }

    sortCATable(hdr, obj, tableData) {
        let rows = tableData;
        console.log(rows, "This is the table data")
        if (rows != null) {
            rows.sort((a, b) => {
                let first = (typeof a[obj][hdr] === 'string') ? a[obj][hdr].toLowerCase() : a[obj][hdr];
                let second = (typeof b[obj][hdr] === 'string') ? b[obj][hdr].toLowerCase() : b[obj][hdr];
                console.log(a.record, "Test")
                if(hdr.includes('name')){
                    first = first.split(',')[0]
                    second = second.split(',')[0]
                }
                console.log(first, "First")
                console.log(second, "Second")
                if (first < second) {
                    console.log('First < second')
                    return this.state.direction == 'asc' ? -1 : 1;
                }
                if (first > second) {
                    console.log('First > second')
                    return this.state.direction == 'asc' ? 1 : -1;
                }
                return 0;
            });
            this.setState({records: rows})
            if(this.state.direction == 'asc') {
                this.setState({direction: 'desc'})
            }
            else if(this.state.direction == 'desc'){
                this.setState(({direction: 'asc'}))
            }
        }
    }

    getFilter() {
        return (
            <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <h4>Module Filtering</h4>
                    </div>
                    <div className={"card-body"}>
                        <FilterCard
                            fields={[
                                {
                                    key: "employee",
                                    label: "Employee",
                                    isMulti: true,
                                    type: FIELD_TYPE.SELECT,
                                    options: this.state.employees,
                                    isMapped: true
                                },
                                {
                                    key: "description",
                                    label: "Description",
                                    isMulti: true,
                                    type: FIELD_TYPE.SELECT,
                                    options: this.state.courseArray[0],
                                    isMapped: true
                                },
                                {
                                    key: "course_num",
                                    label: "Course #",
                                    isMulti: true,
                                    type: FIELD_TYPE.SELECT,
                                    options: this.state.courseArray[1],
                                    isMapped: true
                                },
                                {
                                    key: "instructor",
                                    label: "Instructor",
                                    isMulti: true,
                                    type: FIELD_TYPE.SELECT,
                                    options: this.state.instructors,
                                    isMapped: true
                                },


                            ]}
                            filterChanged={(filter) => {
                                console.log("Filter changed", filter)
                                // @ts-ignore
                                this.setState({filter: filter})
                            }}
                        />
                        {/*<div className="form-group row">*/}
                        {/*    <label className="col-sm-4 col-form-label">Shortcuts</label>*/}
                        {/*    <div className="col-sm-8 p-0 m-0">*/}
                        {/*        <Select*/}
                        {/*            isSearchable={false}*/}
                        {/*            placeholder={"None"}*/}
                        {/*            noOptionsMessage={()=> "Failed to load."}*/}
                        {/*            isMulti={false}*/}
                        {/*            onChange={(e)=> null}*/}
                        {/*            className={"state_select"}*/}
                        {/*            options={this.state.fyOptions}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                    <div className="card-footer green-border-top">
                        <button className={"btn btn-outline-info"} onClick={(e) => this.queryModules(1)}>Apply
                        </button>
                        <button className={"btn btn-outline-danger float-right"}
                                onClick={() => window.location.reload()}>Clear
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (<React.Fragment>
            <TrainingModal onSubmit={(e) => this.onSubmit(e)} training={this.state.selectedRecord}
                           employees={this.state.employees} modules={this.state.modules}/>
            <TrainingEditModal onSubmit={(e) => this.onEdit(e)} training={this.state.selectedRecord} verification ={this.state.verification}
                               employees={this.state.employees}/>
            <Overlay showLoading={this.state.showLoading}/>
            {/*<TrainingModuleModal onSubmit={(e) => this.onSubmit(e)} module={this.state.selectedModule}/>*/}
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                <div className={"row"}>
                    {this.getFilter()}
                    <div className={"col-12 pt-3  m-0 tableFixHead"}>
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <div className="row">
                                    <div className={"col-2"}>
                                        <h4 className="d-inline-block">Training Records</h4>
                                    </div>
                                    <div className={"col-4"}>
                                        <button className={"btn btn-outline-primary"} onClick={() =>
                                            this.setState({selectedRecord: {} as TrainingType}, () => {
                                                TrainingModal.display()
                                            })
                                        }>Create Record
                                        </button>
                                        <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                                onClick={this.onExport}>Export to Excel
                                        </button>
                                    </div>

                                </div>

                            </div>
                            <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                <SimpleTable columns={[
                                    {label: "Employee", key: "employee.name",  headerOnClick: () => this.sortCATable('name', 'employee', this.state.records)},
                                    {label: "Verification of Effectiveness Method", key: "record.Description",  headerOnClick: () => this.sortCATable('description', 'record', this.state.records)},
                                    {label: "Course Description", key: "course.description", headerOnClick: () => this.sortCATable('description', 'course', this.state.records)},
                                    {label: "Course #", key: "course.course_num", headerOnClick: () => this.sortCATable('course_num', 'course', this.state.records)},
                                    {label: "Instructor", key: "record.name",  headerOnClick: () => this.sortCATable('name', 'record', this.state.records)},
                                    {label: "Assigned", key: "record.start_date", formatFunc: parseDate,  headerOnClick: () => this.sortCATable('start_date', 'record', this.state.records)},
                                    {label: "Completed", key: "record.end_date", formatFunc: parseDate,  headerOnClick: () => this.sortCATable('end_date', 'record', this.state.records)},
                                    {label: "Understand Consequence of Error?", key: "record.Uf_IMYConseqError", formatFunc: toTrueFalse, headerOnClick: () => this.sortCATable('Uf_IMYConseqError', 'record', this.state.records)},
                                    {
                                        label: "", key: "", rawFormat: (val) => <React.Fragment>
                                            <span id={"rowButtons"}>
                                            <button className={"btn btn-outline-primary"}
                                                    onClick={() => this.setState({selectedRecord: val.record}, () => TrainingEditModal.display())}>Edit
                                            </button>
                                            {this.state.userView ?
                                            <button className={"btn btn-outline-danger ml-2"} onClick={() => this.handleDelete(val.record)}>Delete</button>
                                                : <div></div>}
                                            </span>
                                        </React.Fragment>
                                    }
                                ]} table_data={this.state.records}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
}