import React from "react";
import {Bar, Line} from "react-chartjs-2";
import Chart, {ChartDataSets} from "chart.js";

export interface BarProps {
    datasets:ChartDataSets[]
    labels:any[]
}
export class BarChart extends React.Component<BarProps, any>{

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Bar data={{
                datasets:this.props.datasets,
                labels:this.props.labels,
            }}
                 options={{
                     legend:{
                         labels:{
                             fontColor:"#fff"
                         }
                     },
                     tooltips:{
                         callbacks:{
                             label:function (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                                 return '$ ' + Intl.NumberFormat().format((tooltipItem.value as unknown as number));
                             }
                         }
                     },

                     scales:{
                         xAxes:[{
                             ticks:{fontColor:"#FFF"},
                             gridLines:{
                                 color:"#ffffe4"
                             }
                         }],
                         yAxes:[{
                             ticks:{
                                 fontColor:"#ffffe4",
                                 callback: function(value, index, values) {
                                     // Convert the number to a string and splite the string every 3 charaters from the end
                                     value = value.toString();
                                     // @ts-ignore
                                     value = value.split(/(?=(?:...)*$)/);

                                     // Convert the array to a string and format the output
                                     // @ts-ignore
                                     value = value.join(',');
                                     return '$' + value;
                                 }
                             },
                             gridLines:{
                                 color:"#FFF"
                             },
                         }]
                     },
                 }}/>
        )
    }
}