import React from "react";
import Overlay from "../Overlay";
import {TrainingModuleModal} from "../modals/TrainingModuleModal";
import {Course, TrainingType} from "../../types/TrainingTypes";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import domain from "../../Domain";
import {Employee} from "../../types/Employee";
import {TrainingModal} from "../modals/TrainingModal";
import {parseDate} from "../../util/FormatUtil";
import Select from "react-select";
import axios from "axios";
import Swal from 'sweetalert2';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../../FirebaseConfig";


interface OrdersState {
    showLoading
    records: { record: TrainingType, course: Course, employee: Employee }[]
    selectedRecord?: TrainingType
    employees: { label: string, value: string } []
    products: { label: string, value: string }[]
    uom: string
    jobs: []
    DeliveryLocations: []
    FamilyCode: string
    currUom: string
    order?
    delivInfo?
    modules: Course[]
    filter?
    courseArray: any
    userEmail: string
}

export class IMMYZon extends React.Component<any, OrdersState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            records: [],
            employees: [],
            products: [],
            uom: null,
            jobs: null,
            DeliveryLocations: null,
            FamilyCode: null,
            currUom: null,
            order: {
                product: null,
                quantity: null,
                uom: null,
                line: null,
                isBackOrdered: null
            },
            delivInfo: {
                delivLocation: null,
                jobNum: null,
                empName: null,
                notes: null
            },
            modules: [],
            courseArray: [],
            userEmail: null
        };
    }

    componentDidMount() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                let uId = user.providerData[0].uid;

                console.log(user.providerData[0].uid)
                this.setState({userEmail: user.providerData[0].email})
            }
        });
        this.setState({showLoading: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/fulfillment/employees"), {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {

                    this.setState({employees: data}, () => {

                    })
                }).catch(() => {
                alert("Failed Employees");
            });
            fetch(domain + (this.props.route ? this.props.route : "/api/items/IZ"), {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    this.setState({products: data[0]}, () => {

                    })
                    this.setState({uom: data[1]}, () => {

                    })
                    data[2].unshift({label: "N/A", value: "N/A"})
                    this.setState({jobs: data[2]})
                    this.setState({DeliveryLocations: data[3]})
                }).catch(() => {
                alert("Failed Employees");
            });
            fetch(domain + (this.props.route ? this.props.route : "/api/fulfillment/records"), {
                method: 'POST',
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    this.setState({records: data})
                }).catch(() => {
                alert("Failed Records");
            }).finally(() => this.setState({showLoading: false}));
        })

    }

    validateFields(element, index, array) {
        return element == null || element.trim().length === 0 || isNaN(array[1])
    }

    onSubmit(e) {
        let reqFields = {
            product: this.state.order.product,
            quantity: this.state.order.quantity,
            delivLoc: this.state.delivInfo.delivLocation,
            name: this.state.delivInfo.empName
        };
        let email;
        let service;
        if (Object.values(reqFields).some(this.validateFields)) {
            Swal.fire({
                title: 'Error',
                text: 'Please complete all required fields to submit your order!',
                icon: 'error',
            })
        } else {
            //   fetch(domain + (this.props.route ? this.props.route : "/api/email/immyzon"), {
            //     method: 'POST',
            //     headers: {'Content-Type': 'application/json'}
            // }).then(res => res.json())
            //     .then(data => {
            //         this.setState({records: data})
            //     }).catch(() => {
            //     alert("Failed Records");
            // });
            if (this.state.order.line === 'RM (Goat) Serum and Serum Pools' || this.state.order.line === 'RM Antigen' || this.state.order.line === 'RM Antibody') {
                console.log("IMMY Hut!")
                email = 'materials@immy.com'
                service = 'Hut'
            } else if (this.state.order.line === 'QC Reference Materials') {
                console.log("QFC!")
                email = 'qfc@immy.com'
                service = 'QFC'
            } else {
                console.log("FishBucket!")
                email = 'internal-ordering@immy.com'
            }
            this.setState({showLoading: true}, () => {
                axios.post(domain + "/api/fulfillment/immyzon", [this.state.order, this.state.delivInfo, email, service, this.state.userEmail], { // receive two parameter endpoint url ,form data
                })
                    .then(res => { // then print response status
                        if (res.data.success) {
                            //this.setState({canRequest:true, showLoading:false})
                            Swal.fire({
                                title: 'Success!',
                                text: 'Order placed and Email Sent!',
                                icon: 'success',
                                allowOutsideClick: false
                            }).then((response) => {
                                if (response.isConfirmed) {
                                    window.location.reload()
                                }
                            })
                        } else {
                            //this.setState({canRequest:true, showLoading:false})
                            Swal.fire({
                                title: 'Failure!',
                                text: 'Failed to place order! Try again and let IT know if the issue persists!',
                                icon: 'error',
                                allowOutsideClick: false
                            }).then((response) => {
                                if (response.isConfirmed) {
                                    window.location.reload()
                                }
                            })
                            console.error(res.data.error)
                        }
                    }).catch().finally(() => this.setState({showLoading: false}));
            })
        }

    }

    render() {
        return (
            <React.Fragment>
                <Overlay showLoading={this.state.showLoading}/>
                {/*<TrainingModuleModal onSubmit={(e) => this.onSubmit(e)} module={this.state.selectedModule}/>*/}
                <div className="container-fluid secondary-slate-bg min-vh-100 white pt-5">
                    <div className="immyzonHeader ">
                        <div className={"card shadow-lg primary-black-bg white fulfillFilter"}>
                            <div className="card-header green-border ">
                                <h4>Order Details</h4>
                            </div>
                            <div className="card-body green-border-top">
                                <FilterCard
                                    fields={[
                                        {
                                            key: "refNum",
                                            label: "Reference # *",
                                            type: FIELD_TYPE.SELECT,
                                            options: this.state.products,
                                            isMapped: true,

                                        },
                                        {
                                            key: "isBackOrdered",
                                            label: "Is this product back ordered?",
                                            type: FIELD_TYPE.RADIO,
                                        },
                                        {
                                            key: "quantity",
                                            label: "Quantity *",
                                            type: FIELD_TYPE.TEXT,
                                        },
                                        {
                                            key: "uom",
                                            label: "UOM ",
                                            type: FIELD_TYPE.TEXT,
                                            placeholder: this.state.order.uom ? this.state.order.uom.label : null,
                                            readOnly: true
                                        },
                                        {
                                            key: "line",
                                            label: "Line ",
                                            type: FIELD_TYPE.TEXT,
                                            placeholder: this.state.order.line ? this.state.order.line : null,
                                            readOnly: true
                                        },
                                    ]}
                                    filterChanged={(e) => {

                                        if (e.refNum && e.refNum != this.state.order.refNum) {

                                            fetch(
                                                domain +
                                                (this.props.route
                                                    ? this.props.route
                                                    : "/api/items/FamCode"),
                                                {
                                                    method: "POST",
                                                    headers: {"Content-Type": "application/json"},
                                                    body: JSON.stringify({code: e.refNum[0]}),
                                                }
                                            )
                                                .then((res) => res.json())
                                                .then((data) => {

                                                    this.setState(
                                                        {
                                                            order: {
                                                                product: e.refNum[0],
                                                                quantity: this.state.order.quantity === e.quantity ? this.state.order.quantity : e.quantity,
                                                                uom: this.state.uom[
                                                                    this.state.products.findIndex(
                                                                        (elem) => elem.label === e.refNum[0]
                                                                    )
                                                                    ],
                                                                line: data[0].description,
                                                                isBackOrdered: this.state.order.isBackOrdered === e.isBackOrdered? this.state.order.isBackOrdered : e.isBackOrdered
                                                            },
                                                        },
                                                    );
                                                    // this.setState({products: data[0]}, () => {
                                                    //     console.log(this.state, "Products")
                                                    // })
                                                    // this.setState({uom: data[1]}, () => {
                                                    //     console.log(this.state.uom)
                                                    // })
                                                    // this.setState({jobs: data[2]})
                                                    // this.setState({DeliveryLocations: data[3]})
                                                })
                                                .catch((err) => {
                                                    alert(err);
                                                });
                                        }
                                        if (Object.keys(e).length > 0) {
                                            this.setState(
                                                {
                                                    order: {
                                                        product: this.state.order.product ? this.state.order.product : e.refNum,
                                                        quantity: this.state.order.quantity === e.quantity ? this.state.order.quantity : e.quantity,
                                                        uom: this.state.order.uom != null ? this.state.order.uom : e.uom,
                                                        line: this.state.order.line ? this.state.order.line : e.line,
                                                        isBackOrdered: this.state.order.isBackOrdered ? this.state.order.isBackOrdered : e.isBackOrdered
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div className="card-body green-border-top">
                                <FilterCard
                                    fields={[
                                        {
                                            key: "delivLocation",
                                            label: "Delivery Location *",
                                            type: FIELD_TYPE.SELECT,
                                            options: this.state.DeliveryLocations,
                                            isMapped: true,
                                        },
                                        {
                                            key: "job",
                                            label: "Job # ",
                                            type: FIELD_TYPE.SELECT,
                                            options: this.state.jobs,
                                            isMapped: true,
                                        },
                                        {
                                            key: "emp",
                                            label: "Employee Name *",
                                            type: FIELD_TYPE.SELECT,
                                            options: this.state.employees,
                                            isMapped: true,
                                        },
                                        {
                                            key: "notes",
                                            label: "Notes ",
                                            type: FIELD_TYPE.TEXT_AREA,
                                        },
                                    ]}
                                    filterChanged={(e) => {
                                        if (Object.keys(e).length > 0) {
                                            this.setState(
                                                {
                                                    delivInfo: {
                                                        delivLocation: e.delivLocation ? e.delivLocation[0] : null,
                                                        jobNum: e.job ? e.job : null,
                                                        empName: e.emp ? e.emp[0] : null,
                                                        notes: e.notes ? e.notes : null
                                                    },
                                                }
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={(e) => this.onSubmit(e)}>Submit
                                </button>

                                <h5 className="float-right">* Required Fields</h5>
                            </div>
                        </div>
                        <div className="fulfillLogo">
                            <img src="images/IMMYZON.png" alt=""/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 pt-3  m-0 tableFixHead"}>
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <div className="row">
                                        <div className={"col-2"}>
                                            <h4 className="d-inline-block">Orders</h4>
                                        </div>
                                        <div className={"col-2"}></div>
                                    </div>
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                    <SimpleTable
                                        columns={[
                                            {label: "Order", key: "OrderNumber"},
                                            {label: "Type", key: "Type"},
                                            {label: "Part", key: "ReferenceNumber"},
                                            {label: "Qty", key: "Quantity"},
                                            {label: "UOM", key: "UOM"},
                                            {label: "WO", key: "WorkOrder"},
                                            {label: "Delivery Location", key: "DeliveryLocation"},
                                            {label: "Employee", key: "EmployeeName"},
                                            {
                                                label: "Date",
                                                key: "DateEntered",
                                            },
                                            {label: "Time", key: "TimeEntered"},
                                            {label: "Status", key: "Status"},
                                            {
                                                label: "",
                                                key: "",
                                                rawFormat: (val) => (
                                                    <React.Fragment>

                                                    </React.Fragment>
                                                ),
                                            },
                                        ]}
                                        table_data={this.state.records}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}