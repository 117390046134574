import React from "react";
import {Column, SimpleTableComponentProps} from "./TableBase";

export default class SimpleHeader extends React.Component<SimpleTableComponentProps, any> {

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <thead>
            {this.props.children}
            <tr>
                {this.props.columns.map((col: Column, index) => {
                    return (<th scope="col" onClick={col.headerOnClick ? col.headerOnClick : null} data-toggle={'popover'} title={col.popoverText} data-content={col.popoverText}>{col.label}</th>)
                })}
            </tr>
            </thead>)
    }
}