import React from "react";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import ModalForm from "./ModalForm";
import cookie from 'react-cookie';
import {ModalBodyProps} from "./Modals";

class PasswordModal extends React.Component<any, any>{

    public static display(){
        console.log("Display called")
        window["$"]("#password_modal").modal('show');

    }
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <ModalForm
                fields={[
                    {label:"Password", key:"password", type:FIELD_TYPE.TEXT}
                ]}
                ID={"password_modal"}
                onSubmit={(filter=>{
                    let date = new Date();
                    date.setFullYear(3000);
                    cookie.save('password', filter.password,  { path: '/' , secure:false, expires:date, maxAge:9000000});
                        // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
                )}
                title={"Authentication"}/>
        )
    }
}

export default PasswordModal;