import React, {Component} from 'react';
import '../App.css';
import './modals/Modals'

import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Orders from './immyforce/Orders'
import Opportunities from './immyforce/Opportunities'


import ShippingEstimates from "./immyforce/ShippingEstimates";
import Nav from "./Nav";
import Result_Modal from "./modals/Modals";
import $ from 'jquery';
import Home from "./Home";
import OpenOrders from "./immyforce/OpenOrders";

import ExpenseReport from "./immybooks/ExpenseReport";
import SOSPortal from "./immyforce/SOSPortal";

import ProductionAnalysis from "./immytrack/ProductionAnalysis";
import QCReport from "./immytrack/QCReport";
import Customer from "./immyforce/Customer";
import {HistoricalSearch} from "./immyforce/HistoricalSearch";
import Samples from "./immylabs/Samples";
import {MaterialAnalysis} from "./immytrack/MaterialAnalysis";
import InventorySnapshot from "./immytrack/InventorySnapshot";
import IdControl from "./immytrack/IdControl";
import Vouchers from './immybooks/Vouchers';
import {SalesAnalysis} from "./immyforce/SalesAnalysis";
import { RepDashboard } from './immyforce/RepDashboard';
import {SalesComparison} from "./immyforce/SalesComparison";
import Quality from "./immylabs/Quality";
import FormSearch from "./immylabs/FormSearch";
import VaccineSearch from "./immylabs/VaccineSearch";
import PasswordModal from "./modals/PasswordModal";
import PasswordChange from "./immylabs/PasswordChange";

import {InventoryManagement} from "./immytrack/InventoryManagement";

import {WipLabel} from "./immytrack/WipLabel";
import {RecurringTaskManager} from "./immytrack/RecurringTaskManager";
import {VideoJet} from "./immytrack/VideoJet";
import {Training} from "./immytrack/Training";
import {TrainingModules} from "./immytrack/TrainingModules";
import PackDaddy from "./immylegacy/PackDaddy";
import {IMMYZon} from "./immylegacy/IMMYZon";
import {Fulfillment} from "./immylegacy/Fulfillment"


export interface DefaultProps {
    open_modal?:(obj:{}) =>void
}

export interface DefaultState {
    modal_data?: any
    show_loading?: boolean
}

class App extends Component<DefaultProps, DefaultState> {
    constructor(props) {
        super(props);
        this.state = {
            modal_data: null
        }
        this.open_modal = this.open_modal.bind(this);
    }

    open_modal(data) {
        this.setState({
            modal_data: data,
        }, () => {
            // @ts-ignore
            window.$("#result_modal").modal('show');
        })
    }

    render() {
        return (

            <BrowserRouter>
                <div className="App min-vh-100 secondary-slate-bg">
                    <Nav/>
                    <Result_Modal selected_data={this.state.modal_data} load_result_from_result={this.open_modal}/>
                    <Switch>
                        <Route path="/" render={(props) => <Home {...props} open_modal={this.open_modal}/>} exact/>

                        <Route path="/openorders" render={(props) => <OpenOrders {...props} open_modal={this.open_modal}/>} exact/>

                        <Route path="/orders" render={(props) => <Orders {...props} open_modal={this.open_modal}/>}
                               exact/>
                        <Route path="/sales" render={(props) => <SalesAnalysis {...props} open_modal={this.open_modal}/>}
                               exact/>
                        <Route path="/opportunities"
                               render={(props) => <Opportunities {...props} open_modal={this.open_modal}/>}/>
                        <Route path="/customer" render={(props) => <Customer {...props} open_modal={this.open_modal}/>}
                               exact/>
                        <Route path="/dashboard" render={(props) => <RepDashboard {...props} open_modal={this.open_modal}/>}
                               exact/>

                               <Route path={"/comparison"} render={(props) => <SalesComparison {...props} open_modal={this.open_modal}/>}
                                      exact/>
                        <Route path="/shipping"
                               render={(props) => <ShippingEstimates {...props} open_modal={this.open_modal}/>}/>
                        <Route path="/expensereport"
                               render={(props) => <ExpenseReport {...props} open_modal={this.open_modal}/>}/>
                        <Route path={"/sos"}
                               render={(props) => <SOSPortal {...props} open_modal={this.open_modal}/> }/>

                        <Route path={"/inventory"}
                               render={(props) => <InventorySnapshot {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/idcontrol"}
                               render={(props) => <IdControl {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/production"}
                               render={(props) => <ProductionAnalysis {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/qcreport"}
                               render={(props) => <QCReport {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/historical"}
                               render={(props) => <HistoricalSearch {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/samples"}
                               render={(props) => <Samples {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/quality"}
                               render={(props) => <Quality {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/materials"}
                               render={(props) => <MaterialAnalysis {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/vouchers"}
                               render={(props) => <Vouchers {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/forms"}
                               render={(props) => <FormSearch {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/password"}
                               render={(props) => <PasswordChange {...props} open_modal={this.open_modal}/> }/>

                        <Route path={"/repair"}
                               render={(props) => <InventoryManagement {...props} open_modal={this.open_modal}/> }/>

                        <Route path={"/wiplabel"}
                               render={(props) => <WipLabel {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/tasks"}
                               render={(props) => <RecurringTaskManager {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/videojet"}
                               render={(props) => <VideoJet {...props}/> }/>
                        <Route path={"/training"}
                               render={(props) => <Training {...props}/> }/>
                        <Route path={"/modules"}
                               render={(props) => <TrainingModules {...props}/> }/>
                        <Route path={"/packdaddy"}
                               render={(props) => <PackDaddy {...props} open_modal={this.open_modal}/> }/>
                        <Route path={"/immyzon"}
                               render={(props) => <IMMYZon/> }/>
                        <Route path={"/fulfillment"}
                               render={(props) => <Fulfillment/> }/>
                    </Switch>
                </div>
            </BrowserRouter>);
    }
}

export default App;
