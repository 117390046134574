import React from "react";
import domain from "../../Domain";

interface HistoricalState{
    term:string
    data?:any[]
}
export class HistoricalSearch extends React.Component<any, HistoricalState>{
    constructor(props) {
        super(props);
        this.state ={term:"", data:[]}
        this.search = this.search.bind(this)
    }

    search(){
        let self = this;
        fetch(domain + (this.props.route ? this.props.route : "/api/global/historical"), {
            method: 'POST',
            body: JSON.stringify({term:this.state.term}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                self.setState({
                    data:data
                })
            });
    }

    getImageFromType(type) {
        let types = {
            "bill_to": "/images/bill_to_colored.png",
            "ship_to": "/images/ship_to_colored.png",
            "contact": "/images/contact_colored.png",
            "opportunity": "/images/opportunity.png",
            "order": "/images/order.png",
            "back_order": "/images/open_box.png"
        };
        return types[type];
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                <div className={"row"}>
                    <div className={"col-12  m-0 tableFixHead min-vh-100"}>
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <div className={"row"}>
                                    <h4 className="d-inline-block">Historical Global Search</h4>
                                    <div className={"col-4 float-left"}> <input type="search" className="form-control"
                                                                      value={this.state.term}
                                                                      autoComplete="off"
                                                                      onChange={(e) => this.setState({term:e.target.value})}/>
                                    </div>
                                    <div className={"col-4 float-left"}>
                                        <button className="btn btn-outline-primary" onClick={this.search}>Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className={"card-body"}>
                                <table>
                                    <thead>

                                    </thead>
                                    <tbody>
                                    {this.state.data.map(d =>{
                                        return (
                                            <tr>
                                                <td> <img className="p-0 m-0"
                                                          src={this.getImageFromType(d.type)} width={25}
                                                          height={25}/></td>
                                                <td><a href={"#"} onClick={() => this.props.open_modal(d)}>{d.name}</a></td>
                                            </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}