import React from "react"
import Table from "../tables/Table";
import {formatMoney, formatAddress} from "../../util/FormatUtil";
import domain from "../../Domain";
import Overlay from "../Overlay";
import QuerySearch from "../query_search/QuerySearch";

interface ShippingState{
    customer?
    show_loading?
    table_data?
    order?
    shipment_customer?
    co_num?
    cost_breakdown?
    rates?
    carrier_account?
}

export default class ShippingEstimates extends React.Component<any, ShippingState>{

    constructor(props) {
        super(props);
        this.state = {show_loading:false};
        this.makeEstimate = this.makeEstimate.bind(this);
        this.orderSelected = this.orderSelected.bind(this)
        this.getInfo = this.getInfo.bind(this);
    }

    getInfo(data){
        this.setState({show_loading:true, customer:null, table_data:null, order:null, shipment_customer:null}, ()=>{
            fetch(domain + "/api/shipping/info", {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res=> res.json()).then(info =>{
                //   info.shipment_customer.ship_code = info.customer.ship_code
                console.log(info)
                this.setState({
                    order:data,
                    customer:info.customer,
                    shipment_customer:info.shipment_customer,
                    table_data:info.items,
                    cost_breakdown:null,
                    rates:null,
                    carrier_account: info.carrier_account,
                    show_loading:false}, ()=>{
                    this.makeEstimate(this.state.carrier_account)
                })
            }).catch(e =>{
                this.setState({show_loading:false})
            })
        })
    }

    orderSelected(){
        fetch(domain + "/api/orders/order", {
            method: 'POST',
            body: JSON.stringify({co_num:this.state.co_num.trim()}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json()).then(order =>{
            if(!order){
                alert("No order found")
            }else{
                this.getInfo(order);
            }
        }).catch(e =>{
            console.log(e);
            alert("Failed to get order. Try again.");
        })
    }

    makeEstimate(carrier_account){
        if(!this.state.customer){
            alert("No customer associated with order")
            return;
        }
        let total_qty = 0;
        this.state.table_data.forEach(i =>{
            total_qty += i.qty_ordered;
        })
        if(total_qty >= 1000){
            alert("Order too large. Contact shipping for a custom quote.");
            return;
        }
        if(!this.state.customer.ship_code){
            alert("No ship code associated with customer")
            return;
        }


        let self = this;
        this.setState({show_loading:true}, ()=> {
            fetch(domain + "/api/shipping/estimate", {
                method: 'POST',
                body: JSON.stringify({
                    items: this.state.table_data,
                    order: this.state.order,
                    customer: this.state.shipment_customer
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(estimate => {

                if(estimate.failed){
                    alert("Failed to get estimate");
                    this.setState({show_loading:false})
                    return;
                }
                if(carrier_account) {
                    estimate.cost_breakdown[1].description = ""
                    estimate.cost_breakdown[1].cost = 0
                    console.log("Estimate:" + JSON.stringify(estimate.cost_breakdown))
                    console.log("Has carrier")
                    this.setState({
                        rates: estimate.rates,
                        cost_breakdown: estimate.cost_breakdown,
                        show_loading: false
                    })
                }
                else{
                    console.log("Has no carrier")
                    this.setState({
                        rates: estimate.rates,
                        cost_breakdown: estimate.cost_breakdown,
                        show_loading: false
                    })
                }

            }).catch(e => {
                alert("Failed to get estimate");
                this.setState({show_loading: false})
            })
        });
    }

    render() {
        let rates = this.state.rates ? this.state.rates.data.rates : [];
        let customer = this.state.customer ? this.state.customer : {};
        let shipment_customer = this.state.shipment_customer ? this.state.shipment_customer  : {};
        //console.log(this.state.cost_breakdown)
        let total_cost = 0;
        try{
            total_cost = this.state.cost_breakdown ? this.state.cost_breakdown.reduce((a,b) => a + b.cost, 0) : 0;
        }catch (e) {
            console.log(e);
            alert("Failed to get a cost breakdown.")
        }

        console.log("total cost", total_cost)
        return (
            <React.Fragment>
                <Overlay
                    showLoading={this.state.show_loading}
                />
            <div className="container-fluid secondary-slate-bg min-vh-100">

                <div className={"row"}>
                <div className={"col-12 pt-3 m-0 tableFixHead "}>
                    <div className={"card shadow-lg primary-black-bg white"}>
                        <div className="card-header green-border ">
                            <div className={"row"}>
                                <div className="col-xl-5 ">

                                    <h4 className="d-inline-block float-left pr-3">Shipping Estimate</h4>
                                    <div className={"row"}>
                                        <div className={"col-8 l-3"}>
                                            <div className="form-group row">

                                                    <input type="search" className="form-control" id={"co_num"} placeholder={"Customer Order Number"}
                                                           value={this.state.co_num}
                                                           autoComplete="off"
                                                           onChange={(e) => this.setState({co_num:e.target.value})}/>

                                            </div>
                                            {/*<QuerySearch resultTypes={["order", "back_order"]} onChange={(data) =>this.orderSelected(data.value)}/>*/}
                                        {/*<SearchResult route={'/api/search/planned'} resultTypes={["order", "back_order"]} select_override={(data) => this.orderSelected(data)} form_id={"order_input"} />*/}
                                        </div>

                                        <div className={"col-4 float-left"}>
                                            <button onClick={this.orderSelected} className={"btn btn-outline-primary"}>Estimate</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className={"card-body table-responsive-lg  p-0 m-0"}>
                            <Table columns={[
                                {label: "Product", key: "item"},
                                {label: "Ordered Qty.", key: "qty_ordered"},
                                {label: "Status", key: "stat"},
                                {label: "Unit Price", key: "price", formatFunc: formatMoney}
                            ]}
                                   table_data={this.state.table_data}
                                   load_modal={this.props.open_modal}/>
                        </div>
                    </div>
                </div>
                <div className="col-4 pt-2">
                    <div className={"card shadow-lg primary-black-bg white"}>
                        <div className="card-header green-border ">
                            <h4 className="d-inline-block float-left">Rate Estimates</h4>
                        </div>
                        <div className={"card-body p-0 m-0"}>
                            <Table
                            columns={[{
                                label:"Service",
                                key:"service_name"
                                },
                                {
                                    label:"Price",
                                    key:"total_charge.amount"
                                }]}
                                table_data={rates}
                            />
                        </div>
                    </div>
                </div>
                    <div className="col-4 pt-2">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4 className="d-inline-block float-left">Shipping Information</h4>
                            </div>
                            <div className={"card-body p-0 m-0"}>
                                <Table
                                isHorizontal={true}
                                columns={[
                                    {label:"Ship-to Customer Order", raw:this.state.order ? this.state.order.Order : ""},
                                    {label:"Ship-to Customer", key:"name"},
                                    {label:"Ship-to Ship Code", key:"ship_code"},
                                    {label:"Ship-to Address", raw:formatAddress(shipment_customer)}
                                ]}
                                table_data={shipment_customer}
                                isMap={true}/>
                            </div>
                        </div>
                        <div className={"card shadow-lg primary-black-bg white mt-3"}>
                            <div className="card-header green-border">
                                <h4 className="d-inline-block float-left">Customer Information</h4>
                            </div>
                            <div className={"card-body p-0 m-0"}>
                                <Table
                                    isHorizontal={true}
                                    columns={[
                                        {label:"Customer Order", raw:this.state.order ? this.state.order.Order : ""},
                                        {label:"Customer", key:"name"},
                                        {label:"Ship Code", key:"ship_code"},
                                        {label:"Address", raw:formatAddress(customer)}
                                    ]}
                                    table_data={customer}
                                    isMap={true}/>
                            </div>
                        </div>
                    </div>
                <div className="col-4 pt-2">
                    <div className={"card shadow-lg primary-black-bg white"}>
                        <div className="card-header green-border ">
                            <h4 className="d-inline-block float-left">Cost Breakdown</h4>
                        </div>
                        <div className={"card-body p-0 m-0"}>
                            <Table
                            columns={[
                                {label:"Item", key:'name'},
                                {label:"Description", key:'description'},
                                {label:"Cost", key:"cost", isBreakPoint:true, formatFunc:formatMoney}
                            ]}
                            table_data={this.state.cost_breakdown ? this.state.cost_breakdown : []}
                            append={<React.Fragment><th className="green-border-top">Total Estimate</th>
                                <td className="green-border-top"></td>
                                <td className="green-border-top">{formatMoney(total_cost)}</td></React.Fragment>}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </React.Fragment>
    )
    }
}