import React from "react";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import domain from "../../Domain";
import QuerySearch from "../query_search/QuerySearch";
import Select from "react-select";
import {formatAddress, getAddressString} from "../../util/FormatUtil";
import axios from 'axios'
import Overlay from "../Overlay";

interface SOSItem{
    name:string
    qty:string
    lot:string
    pricing:string
    index:number
}
interface SOSFilter{
    request:string[]
    is_international:string[]
    sales_rep:string[]
    customer:any
    shipto_customer:any
    attn:string
    notes:string

}
interface SOSIntFilter{
    paperwork:string[]
    Contact:string
    Phone:string
    Email:string
    duties:string
    inspection:string
}
interface SOSState {
    items:SOSItem[]
    request_types:ReactSelect[]
    item_options:[]
    sales?:ReactSelect[]
    address?:string
    filter:SOSFilter
    intFilter:SOSIntFilter
    paperwork_file?:File
    rep_approval?:string
    itemFormName?:string
    itemFormQty?:string
    itemFormLot?:string
    itemFormPricing?:string
    canRequest:boolean
    showLoading:boolean
}
class SOSPortal extends React.Component<{}, SOSState> {

    constructor(props) {
        super(props);
        this.state = {
            request_types: [
                {label: "Sample for Validation", value: "Sample"},
                {label: "FOC for Studies", value: "FOC"},
                {label: "NC Replacement", value: "NC Replacement"}],
            item_options: [],
            items: [],
            itemFormName: "",
            filter: {} as SOSFilter,
            intFilter:{} as SOSIntFilter,
            canRequest:true,
            showLoading:false
        }
        this.deleteRow = this.deleteRow.bind(this);
        this.addItem = this.addItem.bind(this);
        this.getYesNoSelect = this.getYesNoSelect.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    private sendEmail(){
        console.log(this.state.filter.shipto_customer.value.cust_num)
        console.log(this.state.filter.customer.value.cust_num)
        if(this.state.filter.shipto_customer.value.cust_num !== this.state.filter.customer.value.cust_num) {
            alert("The Customer Account and the Ship-To Customer Account numbers are not the same! Please check!")
            return;
        }
        if(!this.state.canRequest)
            return;
        if(!this.state.filter.request || (this.state.filter.request[0] !== "Sample" && !this.state.filter.shipto_customer) || !this.state.filter.customer  || !this.state.filter.attn
        || !this.state.filter.sales_rep || !this.state.filter.is_international){
            alert("Missing required fields.")
            return;
        }else{
            if(this.state.filter.is_international[0] === "Yes"){
                if(!this.state.intFilter.duties || !this.state.intFilter.inspection || !this.state.intFilter.paperwork){
                    alert("Missing required fields.")
                    return;
                }else{
                    if(this.state.intFilter.paperwork[0] === "Yes"){
                        if(!this.state.rep_approval || !this.state.paperwork_file){
                            alert("Missing required rep approval or file.")
                            return;
                        }
                    }
                }
            }
        }
        // Check if any items have been added before submitting request
        if(!this.state.items.length){
            alert("There are no Products.\nProducts must be added before submitting.")
            return;
        }

        this.setState({canRequest:false, showLoading:true}, () =>{
            let data = new FormData();
            data.append('file', this.state.paperwork_file);
            data.append('filter', JSON.stringify(this.state.filter));
            data.append('address', this.state.address)
            data.append('intFilter', JSON.stringify(this.state.intFilter));
            data.append('items', JSON.stringify(this.state.items));

            axios.post(domain + "/api/email/sos", data, { // receive two parameter endpoint url ,form data
            })
                .then(res => { // then print response status
                    console.log(res.statusText)
                    console.log(res)
                    if(res.data.success) {
                        this.setState({canRequest:true, showLoading:false})
                        alert("Email sent!")
                    }else {
                        this.setState({canRequest:true, showLoading:false})
                        alert("Failed to send email.")
                        console.error(res.data.error)
                    }
                }).catch(() => this.setState({canRequest:true, showLoading:false}));
        })
    }

    getYesNoSelect() {
        return [{label: "Yes", value: "Yes"}, {label: "No", value: "No"}];
    }

    componentDidMount(): void {
        fetch(domain + "/api/items/finishedSOS").then(res => res.json())
            .then(data => {
                data = data.map(d => {
                    d.value = d.Product;
                    d.label = d.Product;
                    return d
                });
                this.setState({item_options: data})
            });

        fetch(domain + "/api/employees/sales").then(res => res.json())
            .then(data => {
                console.log("Data", data)
                data = data.map(d => {
                    d.label = d.name;
                    d.value = d.name;
                    return d;
                })
                this.setState({sales: data})
            })
            .catch(console.log)
    }

    private addItem() {
        let items = [...this.state.items]
        items.push({
            name: this.state.itemFormName,
            qty: this.state.itemFormQty,
            lot: this.state.itemFormLot,
            pricing: this.state.itemFormPricing,
            index: items.length + 1
        });
        this.setState({items: items});
    }

    private deleteRow(index: number) {
        console.log("Index", index);
        let items = this.state.items.filter(i => i.index !== index)
        this.setState({items: items});
    }

    private getMainFilterCard() {
        return (<React.Fragment>
                <FilterCard fields={[
                    {
                        key: "request", label: "Request Type", type: FIELD_TYPE.SELECT,
                        options: this.state.request_types, isMapped: true
                    },
                    {
                        key: "customer",
                        label: "Customer Account",
                        type: FIELD_TYPE.SEARCH_RESULT,
                        isMulti: false,
                        result_types: ["bill_to", "ship_to"]
                    },
                    {
                        key: "shipto_customer",
                        label: "Ship-to Customer",
                        type: FIELD_TYPE.SEARCH_RESULT,
                        isMulti: false,
                        callback: (e) => {
                            this.setState({address: getAddressString(e.value)})
                        },
                        result_types: ["ship_to"]
                    },
                    {key: "attn", label: "Attention", type: FIELD_TYPE.TEXT},
                    {
                        key: "sales_rep",
                        label: "Sales Rep",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.sales,
                        isMapped:true
                    },
                    {
                        key: "is_international", label: "International",
                        type: FIELD_TYPE.SELECT, options: this.getYesNoSelect(), isMapped: true
                    },
                    {key: "notes", label: "Notes", type: FIELD_TYPE.TEXT_AREA, rows: 3}
                ]} filterChanged={(filter) => this.setState({filter: filter as SOSFilter})}/>
                <form autoComplete={"off"}>
                    <div className={"form-group row"}>
                        <label htmlFor={""} className="col-sm-4 col-form-label">Ship-to Address</label>
                        <div className="col-sm-8 p-0 m-0">
            <textarea rows={5} className="form-control"
                      readOnly={(!this.state.filter.request || this.state.filter.request[0] !== "Sample")}
                      value={this.state.address}
                      onChange={(e) => this.setState({address: e.target.value})}/>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }

    private getInternationalFilterCard() {
        return (<React.Fragment>
            <FilterCard fields={[{label: "Contact", key: "Contact", type: FIELD_TYPE.TEXT},
                {label: "Phone #", key: "Phone", type: FIELD_TYPE.TEXT},
                {label: "Email", key: "Email", type: FIELD_TYPE.TEXT},
                {
                    key: "duties", label: "Are we paying taxes/duties?",
                    type: FIELD_TYPE.SELECT, options: this.getYesNoSelect(), isMapped: true
                },
                {
                    key: "inspection", label: "Inspection Required?",
                    type: FIELD_TYPE.SELECT, options: this.getYesNoSelect(), isMapped: true
                },
                {
                    key: "paperwork", label: "Special Paperwork?",
                    type: FIELD_TYPE.SELECT, options: this.getYesNoSelect(), isMapped: true
                }]}
                        filterChanged={(e) => this.setState({intFilter: e as SOSIntFilter})}/>
            {this.state.intFilter.paperwork && this.state.intFilter.paperwork[0] === "Yes" ?

                <React.Fragment>
                    <div className="form-group files color">
                        <label>Upload Your File </label>
                        <input type="file" className="form-control" onChange={(e) => this.setState({paperwork_file:e.target.files[0]})}/>
                    </div>
                    <div className={"form-group"}>
                        <label  className="col-sm-4 col-form-label">Who is Approving?</label>
                        <div className="col-sm-8  p-0 m-0">
                            <Select
                                isSearchable={false}
                                placeholder={"All"}
                                noOptionsMessage={()=> "Failed to load."}
                                // @ts-ignore
                                onChange={(e)=> this.setState({rep_approval:e})}
                                className={"state_select"}
                                options={this.state.sales}
                            />
                        </div>
                    </div>

                </React.Fragment>

             : null}
        </React.Fragment>)
    }


    render() {

        return (
            <React.Fragment>
                <Overlay showLoading={this.state.showLoading}/>
            <div className={"container-fluid pt-3"}>
                <div className={"row"}>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Request Information</h4>
                            </div>
                            <div className={"card-body "}>
                                {this.getMainFilterCard()}


                                {this.state.filter.is_international && this.state.filter.is_international[0] === "Yes" ?
                                    (
                                        this.getInternationalFilterCard()
                                    ) : null}


                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-6 col-md-6 col-12 pb-2">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Products Requested</h4>
                            </div>
                            <div className={"card-body p-0 m-0"}>
                                <table className={"table table-dark table-striped  p-0 m-0"}>
                                    <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        {/* eslint-disable-next-line react/style-prop-object */}
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Lot</th>
                                        <th scope="col">Pricing</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.items.map((item, index) => {
                                        return (<tr>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.qty}
                                            </td>
                                            <td>
                                                {item.lot}
                                            </td>
                                            <td>
                                                {item.pricing}
                                            </td>
                                            <td>
                                                <button onClick={() => this.deleteRow(item.index)}
                                                        className={"btn btn-outline-danger"}>
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>)
                                    })}
                                    <tr>
                                        <td><Select
                                            isSearchable={true}
                                            placeholder={"All"}
                                            noOptionsMessage={() => "Failed to load."}
                                            isMulti={false}
                                            onChange={(e) => this.setState({itemFormName: e.value})}
                                            className={"state_select table-state_select"}
                                            options={this.state.item_options ? this.state.item_options : []}
                                        />
                                        </td>
                                        {/*<th scope="row">1</th>*/}
                                        <td><input className="form-control form-control-range" type="number"
                                                   onChange={(e) => this.setState({itemFormQty: e.target.value})}/></td>
                                        <td><input className="form-control form-control-range" type="text"
                                                   onChange={(e) => this.setState({itemFormLot: e.target.value})}/></td>
                                        <td><input className="form-control form-control-range" type="text"
                                                   onChange={(e) => this.setState({itemFormPricing: e.target.value})}/>
                                        </td>
                                        <td>
                                            <button onClick={this.addItem}
                                                    className={"float-right btn btn-outline-primary"}>Add
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div className={"card-footer"}>
                            </div>

                        </div>
                        <div className={"card shadow-lg mt-2 primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Sample Email</h4>
                            </div>
                            <div className={"card-body"}>

                                <p><b>Request Type: </b> {this.state.filter.request}</p>
                                <p><b>Sales Rep: </b>  {this.state.filter.sales_rep ? this.state.filter.sales_rep[0] : null}</p>
                                <p><b>Customer Account: </b> {this.state.filter.customer ?
                                        this.state.filter.customer.value.name + " " + this.state.filter.customer.value.cust_num +
                                    ":"+this.state.filter.customer.value.cust_seq : null}</p>
                                <p><b>Ship-to Customer: </b> {this.state.filter.shipto_customer ?this.state.filter.shipto_customer.value.name +
                                    " " + this.state.filter.shipto_customer.value.cust_num + ":"+this.state.filter.shipto_customer.value.cust_seq : null } </p>
                                <p className={"preserve"}><b>Address</b><br/>
                                    Attention: {this.state.filter.attn}<br/>
                                    {this.state.address}
                                </p>
                                <p>
                                        <b>Notes: </b>{this.state.filter.notes}
                                </p>
                                <p><b>Is this international? </b>{this.state.filter.is_international ? this.state.filter.is_international[0] : null}</p>
                                {this.state.filter.is_international && this.state.filter.is_international[0] === "Yes" ?
                                (<React.Fragment>
                                    <p><b>Contact: </b> {this.state.intFilter.Contact}</p>
                                    <p><b>Phone # </b> {this.state.intFilter.Phone}</p>
                                    <p><b>Email: </b> {this.state.intFilter.Email}</p>
                                    <p><b>Are we paying duties/taxes? </b>{this.state.intFilter.duties}</p>
                                    <p><b>Inspection Required? </b>{this.state.intFilter.inspection}</p>
                                    <p><b>Is there special paperwork? </b>{this.state.intFilter.paperwork}</p>
                                </React.Fragment>)

                                    : null}
                                <b>Items</b>
                                <table className={"table table-dark table-striped  p-0 m-0"}>
                                    <thead>
                                    <tr>
                                        <th scope="col">Product</th>
                                        {/* eslint-disable-next-line react/style-prop-object */}
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Lot</th>
                                        <th scope="col">Pricing</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.items.map((item, index) => {
                                        return (<tr>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.qty}
                                                </td>
                                                <td>
                                                    {item.lot}
                                                </td>
                                                <td>
                                                    {item.pricing}
                                                </td>
                                            </tr>)})}
                                    </tbody>
                                </table>

                            </div>
                            <div className={"card-footer"}>

                                <button onClick={this.sendEmail} className={"float-right btn btn-outline-primary"}>Send Email</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default SOSPortal;