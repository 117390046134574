import React from 'react';
import '../App.css';
import './modals/Modals'
import OpenOrders from "./immyforce/OpenOrders"
import domain from "../Domain"
interface HomeState{
    modal_data:any
}

class Home extends React.Component<{open_modal}, HomeState>{
    constructor(props){
        super(props);
        this.state={
            modal_data:{}
        };
    }

    render (){
        return(
            <React.Fragment>
                <div className={"  tableFixHead min-vh-100"}>
                    <div className={"card shadow-lg primary-black-bg white"}>
                        <div className="card-header green-border ">
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500'}}>Welcome to IMMYSoft</h4>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
    )}
}
export default Home;