import * as React from "react";
import moment from "moment";

export const trim = (str) =>{
    return str.trim();
};

export const formatLot = (lot:string) =>{
    if(!lot || lot === "")
        return "";

    let lotarr = lot.split("");
    let newLot = "";
    if(lotarr[0] === 'A'){
        lotarr = lot.slice(1).split("");
    }

    if(lotarr[0] === 'L'){
        newLot = "L" + parseInt(lot.slice(1))
    }else if(lotarr[0] === "F"){
        newLot = lot.slice(0,4) + parseInt(lot.slice(4))
    }
    if(lot.split("")[0] ==="A"){
        lot = "A" + lot;
        newLot = "A" + newLot;
    }

    return lot + " / " + newLot;
};

export const numberWithCommas = (x:number)=> {
    if(!x)
        return "0";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
// Takes a DATE 2019-01-30 -> 01-30-2019
export const formatUSDate = (x, splitter?) =>{
    if(!x)
        return "";
    x = x.split(splitter ? splitter : '-');
    return x[1] + "-" + x[2] + "-" + x[0];
};

export const formatUSDateWithSlash = (x) =>{
    return formatUSDate(x, '/')
}

// Takes a DATE 2019-01-30T00:00:00 -> 2019-01-30
export const parseDate = (x:string, noFormat?:boolean):string =>{
    if(!x)
        return "";
    if(x === '0')
        return "Incomplete"
    x = x.slice(0, 10);
    return !noFormat ? formatUSDate(x) : x;
};

export const parseMonth = (x:string, noFormat?:boolean):string =>{
    if(!x)
        return "";
    x = x.slice(0, 7);
    return x
};

export const toTrueFalse = (x:any):string =>{
    //console.log(x, "TOTRUEFALSE")
    return x === 0 || x === null ? "No" : "Yes";
}

export const toLocalDate = (x:string):string =>{
    let utcMoment = moment.utc(x, 'YYYY-MM-DD hh:mm:ss.SSSSSSS').format('YYYY-MM-DD HH:mm:ss.SSS');
    return moment.utc(utcMoment).format('YYYY-MM-DD hh:mm a');
};


// Takes a number 10000.545 -> '$ 10,000.54'
export const formatMoney = (x) =>{
    if(!x){
        return "$ 0"
    }
    if(typeof x === "string")
        x = parseFloat(x);
    return "$ " + numberWithCommas(x.toFixed(2));
};

export const formatPercentage = (val) =>{
    if(!val)
        return "0.0 %";

    return val.toFixed(2) + " %";
}

export const toPercentage = (val, ofTotal) =>{
    if(!val || !ofTotal || ofTotal === 0){
        return "0.0 %";
    }
    return ((val * 100) / ofTotal).toFixed(2) + " %";
};



export const formatPhoneNumber = (phone) =>{
    return <a href={"tel:" + phone}>{phone}</a>
};

export const getFormattedPercentageChange = (oldNumber, newNumber) =>{
    return formatPercentage(getPercentageChange(oldNumber, newNumber));
}

export const getPercentageChange = (b: number, a: number) =>{
    let percent: number;


    if(b !== 0) {
        if(a !== 0) {
            percent = (b - a) / a * 100;
        } else {
            percent = b * 100;
        }
    } else {
        percent = - a * 100;
    }
    if(percent > 100) {
        return 100;
    }
    else if (percent < -100){
        if(b > 0 && a < 0)
            return 100;
        return -100;
    }
    return percent;
};

export const formatEmail = (email:string) =>{
    return <a href={"mailto:" + email}>{email}</a>
};

export const getAddressString = (data) =>{
    let address = data['addr##1'];
    if(!address){return ""}
    let addrString = address
    if (data["addr##2"]) {
        addrString += "\n" + data["addr##2"]
    }
    if (data["addr##3"]) {
        addrString += "\n" + data["addr##3"]
    }
    if (data["addr##4"]) {
        addrString += "\n" + data["addr##4"]
    }
    addrString = addrString +"\n" +data.city + " "+ data.state + " "+ data.zip + " "+ data.country;
    return addrString;
};

export const toReactSelectList = (arr:string[]) =>{
    return arr.map(s => ({label:s, value:s}))
};

export const formatAddress = (data) =>{
    if(!data)
        return ""
    let address = data['addr##1'];
    if(!address){return ""}
    let addrString = address
    let addr2, addr3, addr4 = "";

    if (data["addr##2"]) {

        addr2 = <React.Fragment>
            <br/> {data["addr##2"]}
        </React.Fragment>
        addrString += " " + data["addr##2"]
    }
    if (data["addr##3"]) {
        addr3 = <React.Fragment>
            <br/> {data["addr##3"]}
        </React.Fragment>
        addrString += " " + data["addr##3"]
    }
    if (data["addr##4"]) {
        // @ts-ignore
        addr4 = <React.Fragment>
            <br/> {data["addr##4"]}
        </React.Fragment>
        addrString += " " + data["addr##4"]
    }
    addrString = addrString +" " +data.city + " "+ data.state + " "+ data.zip + " "+ data.country;
    address = <React.Fragment>
        {address} {addr2} {addr3} {addr4} <br/> {data.city}, {data.state}, {data.zip} <br/> {data.country};
    </React.Fragment>;
    address = <a target="_blank" href={"http://maps.google.com/maps?q=" + addrString}> {address}</a>
    return address
};

export const getTrackingLink = (link:string, code:string) =>{
    if (code === "DHL") {
        return (<a target={"_blank"} href={"https://www.dhl.com/en/express/tracking.html?AWB=" + link}> {link}</a>)
    } else if (code === "FEDX") {
        return (
            <a target={"_blank"} href={"https://www.fedex.com/apps/fedextrack/?tracknumbers=" + link}> {link}</a>)
    } else if (code === "UPS") {
        return (<a target={"_blank"}
                   href={"http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=" + link}>{link}</a>)
    } else {
        return link
    }
};

export const formatMultiselect = (data, label_key, value_key) =>{
    return data.map(d =>{
        return {label:d[label_key], value:d[value_key]}
    })
};

export const getPageOptions = (n:number) =>{
    let options = []
    for (let i = 1; i <= n; i++) {
        options.push({value: i, label: i})
    }
    return options
};

export const getSelectObjFromVal = (val:string, list:{label:string, value:any}[]) =>{
    if(!list)
        return null;
    let obj = null;
    list.some(s =>{
        if(s.value === val){
            obj = s;
            return true;
        }
    })
    return obj;
};

export const getTaskTeams = () =>{
    return [{label:"Production", value:"Production"}
        ,{label:"IT", value:"IT"}];
}

export const htmlEscape = (value:string) => {
    return (value).replace(/[^a-z0-9]/i, '_');
};