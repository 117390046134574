import React from "react";
import Select from "react-select";
import {parseDate, parseMonth} from "../../util/FormatUtil";
import moment from "moment";
import domain from "../../Domain";
import {EquipmentRecord} from "../../types/EquipmentRecord";

interface EditProps{
    onSubmit
    equipmentRecord:EquipmentRecord
}

export class EquipmentRecordModal extends React.Component<EditProps, any>{
    constructor(props) {
        super(props);
        this.state = {} as EquipmentRecord
        this.getAdjustedField = this.getAdjustedField.bind(this);
    }

    getTrueFalseWrap(value){
        console.log("Value: "+value)
        return {label:value ? "True" : "False", value: !!value}
    }

    getSelectWrap(value){
        return value ? {label:value, value:value} : null;
    }

    componentWillReceiveProps(nextProps: Readonly<EditProps>, nextContext: any) {
        let keys = Object.keys(this.state);
        let obj = JSON.parse(JSON.stringify(this.state));
        keys.forEach(k => obj[k] = "");
        console.log(nextProps)
        let equipRecord = nextProps.equipmentRecord ? JSON.parse(JSON.stringify(nextProps.equipmentRecord)) : {};
        if(equipRecord) {
            equipRecord.ScheduledDate = parseMonth(equipRecord.ScheduledDate, true)
            equipRecord.CompletedDate = parseDate(equipRecord.CompletedDate, true)
            equipRecord.DownTimeStart = parseDate(equipRecord.DownTimeStart, true)
            equipRecord.DownTimeEnd = parseDate(equipRecord.DownTimeEnd, true)
            equipRecord.CompletedBy = this.getSelectWrap(equipRecord.CompletedBy);
            equipRecord.PlannedProcedure = this.getTrueFalseWrap(equipRecord.PlannedProcedure)
            equipRecord.Recurring = this.getTrueFalseWrap(equipRecord.Recurring)
            equipRecord.TaskType = this.getSelectWrap(equipRecord.TaskType);
            equipRecord.IntervalCategory = this.getSelectWrap(equipRecord.IntervalCategory);
        }
        this.setState(obj, () => this.setState(equipRecord));
    }

    getReadOnly(key:string, display:string){
        return this.getAdjustedField(key,display,true)
    }

    getMappedLots(arr){
        return arr.map(a => ({label:a.Lot, value:a.Lot}));
    }

    getStateObj(key, val){
        let obj = {};
        obj[key] = val;
        return obj;
    }

    getField(key:string, display:string){
        return this.getAdjustedField(key,display,false);
    }

    getSelectField(key:string, label:string, options){
        return(
            <div className="form-group row">
                <label className={"col-sm-2 col-form-label"}>{label}</label>
                <div className={"col-sm-8 p-0 m-0 "}>

                    <Select
                        onChange={(e)=> {
                            this.setState(this.getStateObj(key, e))}}
                        className={"state_select"}
                        value={this.state[key]}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    getTextArea(key, display){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <textarea cols={3} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }
    getAdjustedField(key, display, readOnly){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <input type={"search"} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       readOnly={readOnly}
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }

    submitForm(){
        let rec = JSON.parse(JSON.stringify(this.state));
        let type:EquipmentRecord = rec as EquipmentRecord;
        // @ts-ignore
        if(!type.ScheduledDate || !type.PlannedProcedure || type.PlannedProcedure === "")
            return alert("Please complete required fields.")
        if(type.CompletedDate && (!type.CompletedBy || type.CompletedBy === ""))
            return alert("Please mark who completed this.")

        rec.PlannedProcedure = rec.PlannedProcedure ? rec.PlannedProcedure.value : "";
        rec.Recurring = rec.Recurring ? rec.Recurring.value : "";
        rec.CompletedDate = rec.CompletedDate ? rec.CompletedDate : null;
        rec.ScheduledDate = rec.ScheduledDate ? rec.ScheduledDate : null;
        rec.DownTimeStart = rec.DownTimeStart ? rec.DownTimeStart : null;
        rec.DownTimeEnd = rec.DownTimeEnd ? rec.DownTimeEnd : null;
        rec.CompletedBy = rec.CompletedBy ? rec.CompletedBy.value : null;
        rec.IntervalCategory = rec.IntervalCategory ? rec.IntervalCategory.value : null;
        rec.TaskType = rec.TaskType ? rec.TaskType.value : null;
        EquipmentRecordModal.hide();
        this.props.onSubmit(JSON.parse(JSON.stringify(rec)));
    }

    buildDate(key, display){
        return(<div className="form-group row">
            <label className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type="date" name="start" max="3000-12-31"
                       value={this.state[key]}
                       min="1000-01-01" className="form-control datepicker" onChange={(e)=>  this.setState(this.getStateObj(key, e.target.value))}/>
            </div></div>)
    }

    buildMonth(key, display){

        return(<div className="form-group row">
            <label className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type="month" name="start" max="3000-12"
                       value= {this.state[key]}
                       min="1000-01" className="form-control datepicker" onChange={(e)=>  this.setState(this.getStateObj(key, e.target.value))}/>
            </div></div>)
    }


    public static display() {
        window["$"]("#" + EquipmentRecordModal.ID).modal('show');
        console.log("display")
    }
    public static hide() {
        window["$"]("#" + EquipmentRecordModal.ID).modal('hide');
    }
    public static readonly ID = "recordEdit"

    render() {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={EquipmentRecordModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">{"Maintenance Record"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                {this.getReadOnly("EquipmentID", "Item")}
                                {this.getTextArea("Notes", "Notes")}
                                {this.getSelectField("PlannedProcedure", "Planned?", [{label:"True", value:true}
                                    ,{label:"False", value:false}])}
                                {this.getSelectField("Recurring", "Recurring?", [{label:"True", value:true}
                                    ,{label:"False", value:false}])}
                                {this.buildMonth("ScheduledDate", "Scheduled")}
                                {this.buildDate("CompletedDate", "Completed")}
                                {this.buildDate("DownTimeStart", "Downtime Start")}
                                {this.buildDate("DownTimeEnd", "Downtime End")}
                                {this.getSelectField("TaskType", "Task Type", [
                                    {label:"Planned Preventive Maintenance", value:"Planned Preventive Maintenance"}
                                    ,{label:"Unplanned Maintenance", value:"Unplanned Maintenance"}])}
                                {this.getSelectField("CompletedBy", "Completed By", [
                                    {label:"Jason Outlaw", value:"Jason Outlaw"},
                                    {label:"Dillon Christian", value:"Dillon Christian"},
                                    {label:"Ashley Ellis", value:"Ashley Ellis"},
                                    {label:"Erin Hayes", value:"Erin Hayes"},
                                    {label:"Danny Towery", value:"Danny Towery"},
                                    {label:"Kevin Jones", value:"Kevin Jones"}
                                    ])}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={(e) =>
                                    this.submitForm()}>
                                    Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}