import React from "react";
import {numberWithCommas, formatMoney, parseDate, toPercentage, getPageOptions} from "../../util/FormatUtil";
import $ from 'jquery';
import Select from 'react-select'
import domain from "../../Domain"
import Table from "../tables/Table"
import {sortByValue, getAllItems, getItems} from "../../util/ProductMapUtils";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import Overlay from "../Overlay";
import {HorizontalBar} from "react-chartjs-2";
import {HorizontalTable} from "../tables/HorizontalTable";

interface IdControlState {
    filter: {
        start_date
        end_date
    }
    modal_data
    table_data
    show_loading
    page_options: ReactSelect[]
    start_date: string
    end_date: string
    alarm: boolean
    datetime
    flask_one: number
    flask_two: number
    lot_one: string
    lots: []
    lot_two: string
    selected_page?
}


class IdControl extends React.Component<{ open_modal }, IdControlState> {

    constructor(props) {
        super(props);
        let startDate = new Date();
        let endDate = new Date();
        let today = new Date();
        let month = (startDate.getMonth() + 1).toString();
        month = month.length === 1 ? "0" + month : month;
        let day = today.getDate().toString();
        day = day.length === 1 ? "0" + day : day;
        let hour = today.getHours().toString();
        hour = hour.length === 1 ? "0" + hour : hour;
        let min = today.getMinutes().toString();
        min = min.length === 1 ? "0" + min : min;


        let date = today.getFullYear().toString() + '-' + month + '-' + day;
        let time = hour + ":" + min;
        let dt = date + 'T' + time;

        this.state = {
            alarm: null,
            datetime: null,
            flask_one: null,
            flask_two: null,
            lot_one: "",
            lot_two: "",
            lots: [],
            modal_data: null,
            table_data: null,
            show_loading: true,
            filter: {start_date: dt, end_date: dt},
            page_options: [{value: "1", label: "1"}],
            start_date: date + "T00:00",
            end_date: date + "T11:59"
        }

        // this.pageRef = React.createRef();
        this.htmlEscape = this.htmlEscape.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onExport = this.onExport.bind(this);
        //this.shortcutSelected = this.shortcutSelected.bind(this);

    }

    componentDidMount() {

        // fetch(domain + "/api/idcontrol/all").then(res => res.json())
        //     .then(data => {
        //         console.log('data ', data)
        //         this.setState({table_data: data})
        //     })
        //     .catch(error => {
        //         console.log('error ', error)
        //     })


        fetch(domain + "/api/idcontrol/getDropDownData").then(res => res.json())
            .then(data => {
                //console.log("data " +JSON.stringify(data))
                this.setState({
                    lots: data.lotsMapped,
                    show_loading: false
                })
            })
            .catch(e => {
                alert(e);
            })


    }


    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "id_control.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/idcontrol/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }


    htmlEscape(value) {
        return (value).replace(/[^a-z0-9]/i, '_');
    }


    onSubmit(e) {
        this.queryInventory(this.state.filter, 1);
        return false;
    }

    queryInventory(fil, page) {
        this.setState({
            show_loading: true,
            table_data: null
        })
        //console.log("queryInventory fil: " +JSON.stringify(fil));
        const filter = JSON.parse(JSON.stringify(fil));
        //console.log("filter before fetch: " + JSON.stringify(filter));
        fetch(domain + "/api/idcontrol/filter?page=" + page, {
            method: 'POST',
            body: JSON.stringify(filter),
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(data => {
                console.log("number returned: " + data.idcontrolReturn.length)
                if (data.idcontrolReturn.length < 1 && filter.alarm === null) {
                    alert("No data returned.  Please refine search.")
                    this.setState({
                        show_loading: false
                    })
                    return;
                } else {
                    this.setState({
                        table_data: data.idcontrolReturn,
                        start_date: filter.start_date,
                        end_date: filter.end_date,
                        lot_one: filter.lot,
                        lot_two: filter.lot,
                        alarm: filter.alarm,
                        show_loading: false
                    })

                }


            })
            .catch(e => {
                alert(e);
            })

    }


    render() {
        //console.log("table_data in render " +JSON.stringify(this.state.table_data));
        let table_data = this.state.table_data ? this.state.table_data : [];
        return (
            <React.Fragment>
                <Overlay showLoading={this.state.show_loading}/>
                <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                    <div className={"row"}>
                        <div className="col-xl-6 col-lg-6 col-md-6  col-12">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Id Control Filtering</h4>
                                </div>
                                <div className={"card-body"}>
                                    <FilterCard
                                        fields={[
                                            {
                                                key: "start_date",
                                                label: "Start Date",
                                                type: FIELD_TYPE.DATETIME,
                                                default: this.state.start_date
                                            },
                                            {
                                                key: "end_date",
                                                label: "End Date",
                                                type: FIELD_TYPE.DATETIME,
                                                default: this.state.end_date
                                            },
                                            {
                                                key: "lot",
                                                label: "Lot",
                                                type: FIELD_TYPE.SELECT,
                                                options: this.state.lots,
                                                isMapped: true
                                            },
                                            {
                                                key: "alarm",
                                                label: "Alarm",
                                                type: FIELD_TYPE.RADIO
                                            },
                                        ]}
                                        filterChanged={(filter) => {
                                            console.log("Filter changed", filter)
                                            // @ts-ignore
                                            this.setState({filter: filter})
                                        }}
                                    />

                                </div>
                                <div className="card-footer green-border-top">
                                    <button className={"btn btn-outline-info"} onClick={(e) => this.onSubmit(e)}>Apply
                                    </button>

                                    <button className={"btn btn-outline-danger float-right"}
                                            onClick={() => window.location.reload()}>Clear
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div className={"col-xl-6 col-lg-6 col-md-6 col-12 pb-2"}>

                        </div>


                        {/*Table*/}
                        <div className={"col-12 pt-3 m-0 tableFixHead min-vh-100"}>
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4 className="d-inline-block">Id Control Results</h4>
                                    <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                            onClick={this.onExport}>Export to Excel
                                    </button>

                                    {/*<div className=" align-middle float-right pages">*/}
                                    {/*<Select*/}
                                    {/*    isSearchable={true}*/}
                                    {/*placeholder={"1"}*/}
                                    {/*noOptionsMessage={() => "Failed to Load"}*/}
                                    {/*inputValue={this.state.selected_page}*/}
                                    {/*// ref={this.pageRef}*/}
                                    {/*//onChange={(e:ReactSelect) => this.queryInventory(this.state.filter, e.value)}*/}
                                    {/*className={"state_select"}*/}
                                    {/*options={this.state.page_options}*/}
                                    {/*/>*/}
                                    {/*</div>*/}
                                    {/*<h5 className="d-inline-block float-right align-middle pr-2">Page </h5>*/}
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>

                                    <Table columns={[
                                        {label: "Datetime", key: "datetime"},
                                        {label: "Flask One", key: "flask1"},
                                        {label: "Flask Two", key: "flask2"},
                                        {label: "Lot One", key: "lot1"},
                                        {label: "Lot Two", key: "lot2"},
                                        {
                                            label: "Alarm", key: "alarm", formatFunc: (str, obj) => {
                                                return String(obj.alarm)
                                            }
                                        },
                                    ]}
                                           table_data={this.state.table_data}
                                           load_modal={this.props.open_modal}
                                    />
                                    <div className={table_data.length > 0 ? 'd-none' : 'd-inline text-center'}> No
                                        records to return
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </React.Fragment>

        );
    }

// shortcutSelected(e) {
//     let filter = JSON.parse(JSON.stringify(this.state.filter));
//     filter.start_date = String(e.value - 1) + "-07-01"
//     filter.end_date = "" + e.value + "-06-30";
//     this.setState({filter:filter, start_date:filter.start_date, end_date:filter.end_date})
// }
}

export default IdControl;