import React from "react";
import axios from 'axios';
import Select from "react-select";
import domain from "../../Domain";
import {BarChart} from "../immyforce/BarChart";
import {Pie} from "react-chartjs-2";
import Chart from "chart.js";
import CustomerPricing from "../immyforce/CustomerPricing";
import FilterCard, {FIELD_TYPE} from "../FilterCard";



export class WipLabel extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            job: '',
            lot: '',
            lotArray: [],
            jobError: '',
            lotError: '',
            ssrsUrl:''
        };

    }



    componentDidMount() {

        fetch(domain + (this.props.route ? this.props.route : "/api/wiplabel")).then(res => res.json())
            .then(data => {
                console.log(data)
            });
    }

    handleJobChange = event => {

        this.setState({job: event.target.value}, () => {
            //event.preventDefault();
            this.validateJob();

        });

        };

    validateJob = () => {
        const {job} = this.state;
        //alert(job);
        this.setState({
            jobError:
                job.length > 1 ? null : 'Name must be longer than 1 characters'
        });

            fetch(domain + (this.props.route ? this.props.route : "/api/wiplabel"), {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({job: this.state.job})
            })
                .then(res => res.json())
                .then(data => {
                    const options = data.map(d => ({
                        "value": d.lot,
                        "label": d.lot
                    }))
                    console.log(options);
                    if (options != null) {
                        this.setState({lotArray: options});
                    }
                }).catch(e => alert("Please input a job number!"));



    }

    handleChange(event) {
        this.setState({lot: event.label});

    }

    handleSubmit = event => {
        event.preventDefault();

        const jobParm = this.state.job
        const lotParm = this.state.lot

        if(jobParm == "") {
            alert("Please fill in job number");
        }
        else if(lotParm == "") {
            alert("Please fill in lot number");
        }

        else {
                //alert(jobParm + lotParm);
                window.location.href = `http://immy-vm-app/ReportServer?%2fSLReports%2fIMY_WipLabelExternal&rs:Command=Render&rs:Format=PDF&dsu:SyteLine=sa&dsp:SyteLine=IMY207ccg&job=${jobParm}&Lot=${lotParm}`;
                this.setState({


                    });
        }

    };

    render() {
        const styles = {
            container: base => ({
                ...base,
                flex: 1
            })
        };

        return (

            <React.Fragment>
                <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                    <br/>
                <div className="card-header green-border ">
                    <h4 style={{ color: 'White', fontWeight: 300}}>IMMY WIP Label</h4>
                </div>
                <div className={"card-body"}>
                    <form onSubmit={this.handleSubmit}>

                        <div className='form-group'>
                            <h6 style={{ color: 'White', fontWeight: 600}}>Job #</h6>
                            <input
                                name='job'
                                className={`form-control ${this.state.jobError ? 'is-invalid' : ''}`}
                                id='job'
                                placeholder='Enter Job#'
                                style={{
                                    width: "100%"
                                    }}
                                value={this.state.job}
                                onChange={this.handleJobChange}


                            />
                            <div className='invalid-feedback'>{this.state.dropoffnameError}</div>
                        </div>
                        <br/>
                        <div className='form-group'>
                            <h6 style={{ color: 'White', fontWeight: 600}}>Lot #</h6>
                            <Select
                                placeholder = {'Enter Lot#'}
                                options={this.state.lotArray}
                                value={{

                                    label: this.state.lot
                                }}
                                style={styles}
                                autosize={false}
                                onChange={this.handleChange.bind(this)}
                                /*theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        text: 'Black',
                                        primary25: 'hotpink',
                                        primary: 'yellow',
                                    }
                                })}*/

                            />


                        </div>

                        <div className="p-3 mb-4">

                            <div className="text-center">
                                <a target={'_blank'} href={'http://immy-vm-app/ReportServer?%2fSLReports%2fIMY_WipLabelExternal&rs:Command=Render&rs:Format=PDF&dsu:SyteLine=sa&dsp:SyteLine=IMY207ccg&job='+this.state.job+'&Lot='+this.state.lot+''}> <button type="submit" className="btn btn-success">Generate</button></a>
                            </div>

                        </div>

                    </form>
                </div>
                </div>
            </React.Fragment>

        );
    }
}

export default WipLabel;