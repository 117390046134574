import React from "react";
import {numberWithCommas, formatMoney, parseDate, toPercentage, getPageOptions} from "../../util/FormatUtil";
import $ from 'jquery';
import Select from 'react-select'
import domain from "../../Domain"
import Table from "../tables/Table"
import {sortByValue, getAllItems, getItems} from "../../util/ProductMapUtils";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import Overlay from "../Overlay";
import {HorizontalBar} from "react-chartjs-2";
import {HorizontalTable} from "../tables/HorizontalTable";

interface InventorySnapshotState{
    filter: {
        start_date
        end_date
    }
    fyOptions:ReactSelect[]
    modal_data
    table_data
    show_loading
    inventory_total: {
        total_on_hand: number,
        total_rsvd: number,
        total_matl_cost: number,
        total_lbr_cost: number,
        total_fovhd_cost: number,
        total_vovhd_cost: number,
        total_out_cost: number,
        total_unit_cost: number,
        total_cost: number
    }
    page_options:ReactSelect[]
    start_date:string
    end_date:string
    warehouses
    items
    locations
    // total_cost
    // total_unit_cost

    selected_page?
}


class InventorySnapshot extends React.Component<{open_modal}, InventorySnapshotState> {

    constructor(props) {
        super(props);
        let startDate = new Date();
        let endDate = new Date();
        let month = (startDate.getMonth() + 1).toString();
        month = month.length === 1 ? "0"+month : month;

        let date = new Date();
        let fyOptions = []
        for(let i = date.getFullYear(); i > 2013; i--){
            fyOptions.push({label:"FY" + i, value:i})
        }
        // dateString = dateString
        this.state = {
            warehouses: [],
            items: [],
            locations: [],
            // total_cost: null,
            // total_unit_cost: null,
            inventory_total: {
                total_on_hand: 0,
                total_rsvd: 0,
                total_matl_cost: 0,
                total_lbr_cost: 0,
                total_fovhd_cost: 0,
                total_vovhd_cost: 0,
                total_out_cost: 0,
                total_unit_cost: 0,
                total_cost: 0
            },
            fyOptions:fyOptions,
            modal_data: null,
            table_data: null,
            show_loading: true,
            filter: {start_date:startDate.getFullYear() + "-"+month+"-"+"01", end_date:endDate.getFullYear() + "-"+month+"-"+"01"},
            page_options: [{value: "1", label: "1"}],
            start_date:startDate.getFullYear() + "-"+month+"-"+"01",
            end_date:endDate.getFullYear() + "-"+month+"-"+"01"
        }

        // this.pageRef = React.createRef();
        this.htmlEscape = this.htmlEscape.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onExport = this.onExport.bind(this);
        //this.shortcutSelected = this.shortcutSelected.bind(this);

    }

    componentDidMount() {


        fetch(domain + "/api/inventory/getDropDownData").then(res => res.json())
            .then(data => {
                //console.log("data " +JSON.stringify(data))
                let warehousesMapped = data.warehouses.map(obj => {
                    obj.label = obj.whse;
                    obj.value = obj.whse;
                    return obj;
                })
                let itemsMapped = data.items.map(obj => {
                    obj.label = obj.item;
                    obj.value = obj.item;
                    return obj;
                })
                let locationsMapped = data.locations.map(obj => {
                    obj.label = obj.loc;
                    obj.value = obj.loc;
                    return obj;
                })

                this.setState({
                    warehouses: warehousesMapped,
                    locations: locationsMapped,
                    items: itemsMapped,
                    show_loading: false
                })
            })
            .catch(e => {
                alert(e);
            })


     }



    onExport(e) {
       // Use XMLHttpRequest instead of Jquery $ajax
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "inventory_snapshot.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/inventory/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }


    htmlEscape(value) {
        return (value).replace(/[^a-z0-9]/i, '_');
    }


    onSubmit(e) {
        this.queryInventory(this.state.filter, 1);
        return false;
    }

    queryInventory(fil, page) {
        this.setState({
            show_loading: true,
            table_data: null
        })
        //console.log("queryInventory fil: " +JSON.stringify(fil));
        const filter = JSON.parse(JSON.stringify(fil));
        //console.log("filter before fetch: " + JSON.stringify(filter));
        fetch(domain + "/api/inventory/filter?page=" + page, {
            method: 'POST',
            body: JSON.stringify(filter),
            headers: {'Content-Type': 'application/json'}
        })
            .then(res => res.json())
            .then(data => {
                console.log("number returned: " + data.inventorySnapshotReturn.length)
                if(data.inventorySnapshotReturn.length < 1){
                    alert("No data returned.  Please refine search.")
                    this.setState({
                        show_loading: false
                    })
                    return;
                }
                else{
                    this.setState({
                        table_data: data.table_data,
                        inventory_total: {
                            total_on_hand: data.total_on_hand.toFixed(2),
                            total_rsvd: data.total_rsvd,
                            total_matl_cost: data.total_matl_cost,
                            total_lbr_cost: data.total_lbr_cost,
                            total_fovhd_cost: data.total_fovhd_cost,
                            total_vovhd_cost: data.total_vovhd_cost,
                            total_out_cost: data.total_out_cost,
                            total_unit_cost: data.total_unit_cost,
                            total_cost: data.total_cost
                        },
                        page_options: getPageOptions(data.num_pages),
                        start_date: filter.start_date,
                        end_date: filter.end_date,
                        show_loading: false
                    })
                }


            })
            .catch(e => {
                alert(e);
            })

    }




    render() {
        //("table_data in render " +JSON.stringify(this.state.table_data));
        let table_data = this.state.table_data ? this.state.table_data : [];
        return (
            <React.Fragment>
                <Overlay showLoading={this.state.show_loading}/>
                <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                    <div className={"row"}>
                        <div className="col-xl-6 col-lg-6 col-md-6  col-12">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Inventory Snapshot Filtering</h4>
                                </div>
                                <div className={"card-body"}>
                                    <FilterCard
                                        fields={[
                                            {key: "start_date", label: "Start Date", type: FIELD_TYPE.DATE, default:this.state.start_date},
                                            {key: "end_date", label: "End Date", type: FIELD_TYPE.DATE, default:this.state.end_date},
                                            {
                                                key: "whse",
                                                label: "Warehouse",
                                                type: FIELD_TYPE.SELECT,
                                                isMulti:true,
                                                options: this.state.warehouses,
                                                isMapped: true
                                            },
                                            {
                                                key: "item",
                                                label: "Item",
                                                type: FIELD_TYPE.SELECT,
                                                isMulti:true,
                                                options: this.state.items,
                                                isMapped: true
                                            },
                                            {
                                                key: "lot",
                                                label: "Lot #",
                                                type: FIELD_TYPE.TEXT,
                                            },
                                            {
                                                key: "vend_lot",
                                                label: "Vendor Lot #",
                                                type: FIELD_TYPE.TEXT,
                                            },
                                            {
                                                key: "loc",
                                                label: "Location",
                                                type: FIELD_TYPE.SELECT,
                                                isMulti:true,
                                                options: this.state.locations,
                                                isMapped: true
                                            },
                                            {key: "exp_start_date", label: "Exp Start Date", type: FIELD_TYPE.DATE},
                                            {key: "exp_end_date", label: "Exp End Date", type: FIELD_TYPE.DATE},
                                            {
                                                key: "qty_on_hand",
                                                label: "QTY On Hand",
                                                type: FIELD_TYPE.TEXT,
                                            },
                                            {
                                                key: "qty_rsvd",
                                                label: "QTY Reserved",
                                                type: FIELD_TYPE.TEXT,
                                            },


                                            //hide rows unless determined they are needed
                                            // {
                                            //     key: "unit_cost",
                                            //     label: "Unit Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                            // {
                                            //     key: "matl_cost",
                                            //     label: "Material Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                            // {
                                            //     key: "lbr_cost",
                                            //     label: "Labor Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                            // {
                                            //     key: "fovhd_cost",
                                            //     label: "Fixed Ovhd Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                            // {
                                            //     key: "out_cost",
                                            //     label: "Outside Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                            // {
                                            //     key: "total_cost",
                                            //     label: "Total Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                            // {
                                            //     key: "total_unit_cost",
                                            //     label: "Total Unit Cost",
                                            //     type: FIELD_TYPE.TEXT,
                                            // },
                                        ]}
                                        filterChanged={(filter) => {
                                            //console.log("Filter changed", filter)
                                            // @ts-ignore
                                            this.setState({filter: filter})}}
                                    />
                                    {/*<div className="form-group row">*/}
                                    {/*    <label className="col-sm-4 col-form-label">Shortcuts</label>*/}
                                    {/*    <div className="col-sm-8 p-0 m-0">*/}
                                    {/*        <Select*/}
                                    {/*            isSearchable={false}*/}
                                    {/*            placeholder={"None"}*/}
                                    {/*            noOptionsMessage={()=> "Failed to load."}*/}
                                    {/*            isMulti={false}*/}
                                    {/*            onChange={(e)=> this.shortcutSelected( e)}*/}
                                    {/*            className={"state_select"}*/}
                                    {/*            options={this.state.fyOptions}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                                <div className="card-footer green-border-top">
                                    <button className={"btn btn-outline-info"} onClick={(e) =>this.onSubmit(e)}>Apply
                                    </button>
                                    <button className={"btn btn-outline-danger float-right"}
                                            onClick={() => window.location.reload()}>Clear
                                    </button>
                                </div>
                            </div>
                        </div>




                        {/*<div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">*/}
                        {/*    <div className={"card shadow-lg primary-black-bg white"}>*/}
                        {/*        <div className="card-header green-border ">*/}
                        {/*            <h4>Inventory Totals</h4>*/}
                        {/*        </div>*/}
                        {/*        <div className={"card-body p-0 m-0"}>*/}
                        {/*            <table className={"table table-borderless p-0 m-0"}>*/}
                        {/*                <tbody>*/}
                        {/*                {!this.state.table_data ? table_data.map((value, index) => {*/}

                        {/*                    return (<React.Fragment>*/}
                        {/*                        <tr>*/}
                        {/*                            <td><a role={"button"} data-toggle={"collapse"}*/}
                        {/*                                   href={"#" + this.htmlEscape(value[0])}> {value[0]}</a>*/}
                        {/*                            </td>*/}
                        {/*                            <td></td>*/}
                        {/*                            <td></td>*/}
                        {/*                            <td></td>*/}
                        {/*                        </tr>*/}
                        {/*                            return (<React.Fragment>*/}
                        {/*                                <tr className="collapse"*/}
                        {/*                                    id={this.htmlEscape(value[0])}>*/}
                        {/*                                    <td></td>*/}
                        {/*                                    <td></td>*/}
                        {/*                                    <td></td>*/}
                        {/*                                    <td></td>*/}
                        {/*                                </tr>*/}
                        {/*                            </React.Fragment>)*/}
                        {/*                        })}*/}
                        {/*                    </React.Fragment>)*/}
                        {/*                }) : null}*/}
                        {/*                </tbody>*/}
                        {/*            </table>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        <div className={"col-xl-6 col-lg-6 col-md-6 col-12 pb-2"}>
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Inventory Totals</h4>
                                </div>
                                <div className={"card-body p-0 m-0 "}>
                                    <HorizontalTable columns={[
                                        {
                                            label: "Total On Hand: ",
                                            key: "total_on_hand",
                                        },
                                        {
                                            label: "Total Reserved: ",
                                            key: "total_rsvd",
                                        },
                                        {
                                            label: "Total Material Cost: ",
                                            key: "total_matl_cost",
                                            formatFunc: formatMoney,
                                        },
                                        {
                                            label: "Total Labor Cost: ",
                                            key: "total_lbr_cost",
                                            formatFunc: formatMoney,
                                        },
                                        {
                                            label: "Total Fixed Ovhd Cost: ",
                                            key: "total_fovhd_cost",
                                            formatFunc: formatMoney,
                                        },
                                        {
                                            label: "Total Variable Ovhd Cost: ",
                                            key: "total_vovhd_cost",
                                            formatFunc: formatMoney,
                                        },
                                        {
                                            label: "Total Outside Cost: ",
                                            key: "total_out_cost",
                                            formatFunc: formatMoney,
                                            isBreakPoint: true,
                                        },
                                        {
                                            label: "Total Unit Cost: ",
                                            key: "total_unit_cost",
                                            formatFunc: formatMoney,
                                        },
                                        {
                                            label: "Total Cost: ",
                                            key: "total_cost",
                                            formatFunc: formatMoney,
                                            isBreakPoint: true,
                                        }
                                    ]}
                                                     table_data={this.state.inventory_total ? this.state.inventory_total : null}
                                                     table_style={"table-borderless"}/>
                                </div>
                                <p className={"TEXT-center"}>Results for: {this.state.start_date ? parseDate(this.state.start_date) :"All"} to {this.state.end_date ? parseDate(this.state.end_date) : "All"}</p>
                            </div>

                        </div>







                        {/*Table*/}
                        <div className={"col-12 pt-3 m-0 tableFixHead min-vh-100"}>
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4 className="d-inline-block">Inventory</h4>
                                    <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                            onClick={this.onExport}>Export to Excel
                                    </button>

                                    <div className=" align-middle float-right pages">
                                        <Select
                                            isSearchable={true}
                                            placeholder={"1"}
                                            noOptionsMessage={() => "Failed to Load"}
                                            inputValue={this.state.selected_page}
                                            // ref={this.pageRef}
                                            onChange={(e:ReactSelect) => this.queryInventory(this.state.filter, e.value)}
                                            className={"state_select"}
                                            options={this.state.page_options}
                                        />
                                    </div>
                                    <h5 className="d-inline-block float-right align-middle pr-2">Page </h5>
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                    <Table columns={[
                                        {label: "Snapshot Date", key: "snapshot_timestamp", formatFunc: parseDate},
                                        {label: "Warehouse", key: "whse"},
                                        {label: "Item", key: "item"},
                                        {label: "Lot #", key: "lot"},
                                        {label: "Vend Lot #", key: "vend_lot"},
                                        {label: "Location", key: "loc"},
                                        {label: "Exp Date", key: "exp_date", formatFunc: parseDate},
                                        {label: "On Hand", key: "qty_on_hand", formatFunc: numberWithCommas},
                                        {label: "Rsvd", key: "qty_rsvd", formatFunc: numberWithCommas},
                                        {label: "Unit Cost", key: "unit_cost", formatFunc: formatMoney},
                                        {label: "Matl Cost", key: "matl_cost", formatFunc: formatMoney},
                                        {label: "Lbr Cost", key: "lbr_cost", formatFunc: formatMoney},
                                        {label: "Fix Ovhd Cost", key: "fovhd_cost", formatFunc: formatMoney},
                                        {label: "Var Ovhd Cost", key: "vovhd_cost", formatFunc: formatMoney},
                                        {label: "Out Cost", key: "out_cost", formatFunc: formatMoney},
                                        {label: "Total Cost", key: "total_cost", formatFunc: formatMoney},
                                    ]}
                                           table_data={this.state.table_data}
                                           load_modal={this.props.open_modal}
                                   />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </React.Fragment>

        );
    }

    // shortcutSelected(e) {
    //     let filter = JSON.parse(JSON.stringify(this.state.filter));
    //     filter.start_date = String(e.value - 1) + "-07-01"
    //     filter.end_date = "" + e.value + "-06-30";
    //     this.setState({filter:filter, start_date:filter.start_date, end_date:filter.end_date})
    // }
}

export default InventorySnapshot;