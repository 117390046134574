import React from "react";
import {ModalBodyProps} from "./Modals";
import Table from "../tables/Table";
import {formatAddress, formatEmail, formatPhoneNumber} from "../../util/FormatUtil";

export default class ContactModal extends React.Component<ModalBodyProps, any>{

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let data = this.props.data;
        return (
            <Table
                isHorizontal={true}
                columns={[
                    {label: "Job Title", key: "job_title"},
                    {
                        label: "Customer", raw: (<a href={"#!"} onClick={() => {
                            this.props.load_modal({cust_num: data.cust_num, cust_seq: data.cust_seq})
                        }}>{data.cust_name}</a>)
                    },
                    {label: "Address", raw: formatAddress(data)},
                    {label: "Email", key: "email", formatFunc: formatEmail},
                    {label: "Fax", key: "fax_num"},
                    {label: "Office Phone", key: "office_phone", formatFunc: formatPhoneNumber},
                    {label: "Mobile Phone", key: "mobile_phone", formatFunc: formatPhoneNumber}
                ]}
                load_modal={this.props.load_modal}
                modal_cb={this.props.modal_cb}
                table_data={data}
                isMap={true}
                table_style={"table-striped text-left m-0 p-0"}
                header_style={"fw-700"}
            />
        )
    }
}