import React from "react";
import domain from "../../Domain";
import SimpleTable from "../tables/SimpleTable";
import {formatLot, formatUSDate, formatUSDateWithSlash, numberWithCommas, parseDate} from "../../util/FormatUtil";
import {Column} from "../tables/TableBase";
import {buildDisplayString} from "../tables/TableUtil";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Overlay from "../Overlay";



export default interface FBGrid{
    Product: string
    Description: string
    Line: string
    UOM: string
    "Units 12mo": number
    "Units 6mo": number
    "Units 3mo": number
    "FG Stock": number
    "In Progress": number
    "Iss COs": number
    "Planned COs": number
    Sellable: number
    //FGStockBreakdowns?:FGStockBreakdown[]
    //SellableBreakdowns?:SellableBreakdown[]

}

// interface SellableBreakdown{
//     Item:string
//     Lot:string
//     Qty:number
//     "Sell By Date":Date
//     "Exp. Date":Date
// }

// interface FGStockBreakdown{
//     Item:string
//     Lot:string
//     Qty:number
//     "Sell By Date":Date
//     "Exp. Date":Date
// }

interface ProductionState {
    GridItems:FBGrid[];
    CAGridItems;
    SelectedLine:string[];
    SelectedItems:string[];
    show_loading;
    export?;
    filter:{products:string[], items:string[]};
    direction: string;
}



export default class ProductionAnalysis extends React.Component<any, ProductionState>{
    constructor(props) {
        super(props);
        this.state = {
            GridItems: [],
            CAGridItems: [],
            SelectedItems: [],
            SelectedLine: [],
            show_loading: true,
            export: [],
            filter: {items: [], products: []},
            direction: 'asc',
        }


        this.onExport = this.onExport.bind(this);
    }

    componentDidMount(): void {
        this.setState({show_loading: true})
        fetch(domain + "/api/production/analysis").then(res => res.json())
            .then(data => {
                //console.log(data);
                let items = new Set<string>();
                let selectedLine = new Set<string>();
                data.forEach((d:FBGrid) =>{
                    items.add(d.Product)
                    selectedLine.add(d.Line)
                })
                this.setState({GridItems: data, SelectedItems:Array.from(items), SelectedLine:Array.from(selectedLine)})
                this.setState({show_loading: false})
            });

        fetch(domain + "/api/production/stockout").then(res => res.json())
            .then(data => {
                //console.log('stockout ' + JSON.stringify(data));
                this.setState({CAGridItems: data})
            });
    }

    onExport(e) {
        // Use XMLHttpRequest instead of Jquery $ajax
        //console.log(this.state.filter);
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "production_analysis.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/production/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }

    sortTable(hdr, tableData) {
        let rows = tableData;
        //console.log(JSON.stringify(rows))
        if (rows != null) {
            rows.sort((a, b) => {
                let first = (typeof a[hdr] === 'string') ? a[hdr].toLowerCase() : a[hdr];
                let second = (typeof b[hdr] === 'string') ? b[hdr].toLowerCase() : b[hdr];
                if (first < second) {
                    return this.state.direction == 'asc' ? -1 : 1;
                }
                if (first > second) {
                    return this.state.direction == 'asc' ? 1 : -1;
                }
                return 0;
            });
            this.setState({GridItems: rows})
            if(this.state.direction == 'asc') {
                this.setState({direction: 'desc'})
            }
            else if(this.state.direction == 'desc'){
                this.setState(({direction: 'asc'}))
            }
        }
    }

    sortCATable(hdr, tableData) {
        let rows = tableData;
        //console.log(JSON.stringify(rows))
        if (rows != null) {
            rows.sort((a, b) => {
                let first = (typeof a[hdr] === 'string') ? a[hdr].toLowerCase() : a[hdr];
                let second = (typeof b[hdr] === 'string') ? b[hdr].toLowerCase() : b[hdr];
                if (first < second) {
                    return this.state.direction == 'asc' ? -1 : 1;
                }
                if (first > second) {
                    return this.state.direction == 'asc' ? 1 : -1;
                }
                return 0;
            });
            this.setState({CAGridItems: rows})
            if(this.state.direction == 'asc') {
                this.setState({direction: 'desc'})
            }
            else if(this.state.direction == 'desc'){
                this.setState(({direction: 'asc'}))
            }
        }
    }


    render() {

        let tableData: FBGrid[] = JSON.parse(JSON.stringify(this.state.GridItems));
        let CAtableData = JSON.parse(JSON.stringify(this.state.CAGridItems));
        //console.log("tableData: " + JSON.stringify(tableData))
        if (this.state.filter.products && this.state.filter.products.length > 0) {
            tableData = tableData.filter(g => this.state.filter.products.indexOf(g.Line) > -1)
        }
        if (this.state.filter.items && this.state.filter.items.length > 0) {
            tableData = tableData.filter(g => this.state.filter.items.indexOf(g.Product) > -1)
        }
        return (
            <div className={"container-fluid tableFixHead"}>
                <Overlay showLoading={this.state.show_loading}/>
                <div className={"row pt-4"}>
                    <div className="col-xl-4 col-lg-6 col-md-6  col-12 pb-3">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Filtering</h4>
                            </div>
                            <div className={"card-body"}>
                                <FilterCard fields={[
                                    {
                                        key: "products",
                                        label: "Product Line",
                                        type: FIELD_TYPE.SELECT,
                                        options: this.state.SelectedLine ? this.state.SelectedLine : null,
                                        isMulti: true
                                    },
                                    {
                                        key: "items",
                                        label: "Items",
                                        type: FIELD_TYPE.SELECT,
                                        options: this.state.SelectedItems ? this.state.SelectedItems : null,
                                        isMulti: true
                                    },

                                ]} filterChanged={(e) => {
                                    this.setState({filter: e as { items: string[], products: string[] }})
                                }}/>
                            </div>
                        </div>


                    </div>

                    <div className="col-xl-8 col-lg-6 col-md-6  col-12 pb-3">
                        <div className="card shadow-lg primary-black-bg white">
                            <div className="card-header green-border ">
                                <h4 className="d-inline-block">Customers Affected by Stock Out</h4>
                                {/*<button className={"btn btn-outline-info ml-3 d-inline-block"}*/}
                                {/*        onClick={this.onExport}>Export to Excel*/}
                                {/*</button>*/}
                            </div>

                            <div className={"card-body p-0 m-0"}>
                                <SimpleTable table_data={CAtableData} columns={
                                    [
                                        {label: "Item", key: "Item", headerOnClick: () => this.sortCATable('Item', CAtableData)},
                                        {label: "Customer #", key: "Customer #", headerOnClick: () => this.sortCATable('Customer #', CAtableData)},
                                        {label: "Customer", key: "Customer", headerOnClick: () => this.sortCATable('Customer', CAtableData)},
                                        {label: "Qty", key: "qty_ordered", headerOnClick: () => this.sortCATable('qty_ordered', CAtableData)},
                                    ]
                                } rowBuilder={(table_data, columns) => {
                                    return (table_data ? table_data.map((val: any, val_index: number) => {

                                        let className = "m-0 p-0 " + (((val_index - 1) % 2) === 0 ? "dark-row-override-even" : "dark-row-override-odd");

                                        return (<React.Fragment>
                                            <tr className={className}>{

                                                columns.map((col: Column, index) => {
                                                    if (index === 0) {
                                                        return (
                                                            <td>

                                                                {buildDisplayString(col, val)}
                                                            </td>
                                                        )
                                                    }
                                                    return (<td>{buildDisplayString(col, val)}</td>)
                                                })
                                            }</tr>
                                        </React.Fragment>)
                                    }) : null);
                                }}/>

                            </div>
                        </div>


                    </div>
                </div>


                <div className={'row'}>


                    <div className={"col-lg-12"}>
                        <div className="card shadow-lg primary-black-bg white">
                            <div className="card-header green-border ">
                                <h4 className="d-inline-block">Production Analysis</h4>
                                <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                        onClick={this.onExport}>Export to Excel
                                </button>
                            </div>

                            <div className={"card-body p-0 m-0"}>
                                <SimpleTable table_data={tableData} columns={
                                    [
                                        {
                                            label: "Product",
                                            key: "Product",
                                            headerOnClick: () => this.sortTable('Product', tableData)
                                        },
                                        {
                                            label: "Description",
                                            key: "Description",
                                            headerOnClick: () => this.sortTable('Description', tableData)
                                        },
                                        {
                                            label: "Line",
                                            key: "Line",
                                            headerOnClick: () => this.sortTable('Line', tableData)
                                        },
                                        {
                                            label: "Units 12mo",
                                            key: "Units 12mo",
                                            headerOnClick: () => this.sortTable('Units 12mo', tableData),
                                            popoverText: 'Sum of quantity shipped between today and 12 months ago'
                                        },
                                        {
                                            label: "Units 6mo",
                                            key: "Units 6mo",
                                            headerOnClick: () => this.sortTable('Units 6mo', tableData),
                                            popoverText: 'Sum of quantity shipped between today and 6 months ago'
                                        },
                                        {
                                            label: "Units 3mo",
                                            key: "Units 3mo",
                                            headerOnClick: () => this.sortTable('Units 3mo', tableData),
                                            popoverText: 'Sum of quantity shipped between today and 3 months ago'
                                        },
                                        {
                                            label: "FG Stock",
                                            key: "FG Stock",
                                            headerOnClick: () => this.sortTable('FG Stock', tableData),
                                            popoverText: 'Quantity on hand from STAN warehouse and FG Stock lot location'
                                        },
                                        {
                                            label: "Floor",
                                            key: "Floor",
                                            headerOnClick: () => this.sortTable('Floor', tableData),
                                            popoverText: 'Quantity on hand from STAN warehouse and FG Stock lot location'
                                        },
                                        {
                                            label: "FG Reserved",
                                            key: "FG Reserved",
                                            headerOnClick: () => this.sortTable('FG Reserved', tableData),
                                            popoverText: 'Quantity on hand from STAN warehouse and FG Stock lot location'
                                        },
                                        {
                                            label: "In Progress",
                                            key: "In Progress",
                                            headerOnClick: () => this.sortTable('In Progress', tableData),
                                            popoverText: 'Sum of quantity released where status = Released'
                                        },
                                        {
                                            label: "Iss COs",
                                            key: "Iss COs",
                                            headerOnClick: () => this.sortTable('Iss COs', tableData),
                                            popoverText: 'Sum of quantity ordered minus sum of quantity shipped where status = Ordered and ScheduleDate is between today and one month from today'
                                        },
                                        {
                                            label: "Planned COs",
                                            key: "Planned COs",
                                            headerOnClick: () => this.sortTable('Planned COs', tableData),
                                            popoverText: 'Sum of quantity ordered minus sum of quantity shipped where status = Planned and ScheduleDate between today and one month from today'
                                        },
                                        {
                                            label: "Sellable",
                                            key: "Sellable",
                                            headerOnClick: () => this.sortTable('Sellable', tableData),
                                            popoverText: 'Total of orders from today back minus FG Stock'
                                        },
                                        {
                                            label: "MOH",
                                            key: "Months on Hand",
                                            headerOnClick: () => this.sortTable('Months on Hand', tableData),
                                            popoverText: 'FG Stock divided by (total of last 6 months sales in units divided by 6)\n'
                                        },
                                        {
                                            label: "SOD",
                                            key: "Stock Out Days",
                                            headerOnClick: () => this.sortTable('Stock Out Days', tableData),
                                            popoverText: 'Number of days that Sellable has been below zero\n'
                                        }
                                    ]
                                } rowBuilder={(table_data, columns) => {
                                    return (table_data ? table_data.map((val: any, val_index: number) => {

                                        let className = "m-0 p-0 collapsed " + (((val_index - 1) % 2) === 0 ? "dark-row-override-even" : "dark-row-override-odd");
                                        className = className + ((val["Sellable"] < 0) ? " table-red" : "")

                                        return (<React.Fragment>
                                            <tr className={className}>{

                                                columns.map((col: Column, index) => {
                                                    if (index === 0) {
                                                        return (
                                                            <td><a data-toggle={"collapse"} role={"button"}
                                                                   href={"#a" + val_index}>

                                                                {buildDisplayString(col, val)}
                                                            </a></td>
                                                        )
                                                    }
                                                    return (<td>{buildDisplayString(col, val)}</td>)
                                                })
                                            }</tr>
                                            <tr className={"collapse "} id={"a" + val_index}>
                                                <td className="p-0 m-0" colSpan={12}>
                                                    {/*<td><a data-toggle={"collapse"} role={"button"} href={"#" + val_index}>{"test"}</a></td>*/}
                                                    <div className={"row p-0 m-0"}>
                                                        <div className={"col-6 p-0 m-0 mt-3 blue-border-all"}>
                                                            <h4 className={"TEXT-center p-0 m-0 pb-1 pt-1"}>Units
                                                                3MO</h4>
                                                            <SimpleTable columns={[
                                                                {label: "Item", key: "Item"},
                                                                {label: "CO", key: "CO"},
                                                                {label: "Name", key: "Name"},
                                                                {label: "QTY", key: "QTY"},
                                                            ]} table_data={val.ThreeMonth}
                                                                         table_style={"table-sm table-dark table-bordered table-striped p-0 m-0"}/>
                                                        </div>
                                                        <div className={"col-6 p-0 m-0 mt-3 red-border-all"}>
                                                            <h4 className={"TEXT-center p-0 m-0 pb-1 pt-1"}>Iss COs</h4>
                                                            <SimpleTable columns={[
                                                                {label: "Item", key: "ITEM"},
                                                                {label: "CO", key: "CO_NUM"},
                                                                {label: "Name", key: "Name"},
                                                                {label: "QTY", key: "IssuedCO_QTY"},
                                                                {
                                                                    label: "Sch. Fulfillment",
                                                                    key: "Sch. Fulfillment",
                                                                    formatFunc: formatUSDateWithSlash
                                                                },
                                                                {label: "Status", key: "Status"},
                                                            ]} table_data={val.issCO}
                                                                         table_style={"table-sm table-secondary table-striped  table-bordered green-border-all p-0 m-0"}/>
                                                        </div>
                                                        <div className={"col-6 p-0 m-0 mt-3 red-border-all"}>
                                                            <h4 className={"TEXT-center p-0 m-0 pb-1 pt-1"}>Planned
                                                                COs</h4>
                                                            <SimpleTable columns={[
                                                                {label: "Item", key: "ITEM"},
                                                                {label: "CO", key: "CO_NUM"},
                                                                {label: "Name", key: "Name"},
                                                                {label: "QTY", key: "PlannedCOs_Qty"},
                                                                {label: "Sch. Fulfillment", key: "Sch. Fulfillment"},
                                                                {label: "Status", key: "Status"},
                                                            ]} table_data={val.PlannedCO}
                                                                         table_style={"table-sm table-secondary table-striped  table-bordered silver-border-all p-0 m-0"}/>
                                                        </div>
                                                        <div className={"col-6 p-0 m-0 mt-3 green-border-all"}>
                                                            <h4 className={"TEXT-center p-0 m-0 pb-1 pt-1"}>FG
                                                                Stock</h4>
                                                            <SimpleTable columns={[
                                                                {label: "Item", key: "Item"},
                                                                {label: "Lot", key: "Lot"},
                                                                {label: "Qty", key: "Qty"},
                                                                {label: "Sell By Date", key: "Sell By Date"},
                                                                {label: "Exp. Date", key: "Exp. Date"}
                                                            ]}
                                                                         table_data={val.FGStockBreakdown}  // this should be FGStockBreakdown, but for some reason it only works this way
                                                                         table_style={"table-sm table-dark table-bordered table-striped p-0 m-0"}/>
                                                        </div>
                                                    </div>
                                                </td>

                                            </tr>

                                        </React.Fragment>)
                                    }) : null);
                                }}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}