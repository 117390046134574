import React, {useState} from "react";
import domain from "../../Domain";
import BarcodeReader from 'react-barcode-reader';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import Swal from 'sweetalert2';
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import ReactCanvasConfetti from "react-canvas-confetti";
import Switch from "react-switch";
import ReactWeather, {useOpenWeather} from 'react-open-weather';
import WeatherPanel from "./WeatherPanel";
import EditOppTaskFormModal from "../modals/OppTaskEditFormModal";

let audioSuccess = new Audio('/sound/Success.wav');
let audioError = new Audio('/sound/Beep.wav');
let audioFire = new Audio('/sound/fire.wav');
let audioSad = new Audio('/sound/sad.wav');


interface PackDaddyState {
    employeesList: any[]
    employee: string
    shipNum: string
    lotNum: string
    itemList: any[]
    lotList: any[]
    barcodeScan: string
    showLoading: boolean
    result: string
    shipScan: boolean
    lotScan: boolean
    quantity: number
    itemScan: string
    previousLot: string
    itemScanList: any[]
    newScanList: any[]
    previousItem: string
    decrement: boolean
    location: string
    weatherInfo: any[]
    verified: boolean
    override: boolean

}

function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}


class PackDaddy extends React.Component<{ route: string }, PackDaddyState> {
    trigger: boolean
    isAnimationEnabled: boolean
    animationInstance: any
    intervalId: number
    weatherKey: any
    lat: any
    long: any

    constructor(props) {
        super(props);


        this.state = {
            employeesList: [],
            employee: '',
            shipNum: '',
            lotNum: '',
            itemList: [],
            barcodeScan: '',
            showLoading: false,
            result: '',
            shipScan: false,
            lotScan: false,
            lotList: [],
            itemScan: '',
            quantity: 1,
            previousLot: '',
            itemScanList: [],
            newScanList: [],
            previousItem: '',
            decrement: false,
            location: '',
            weatherInfo: [],
            verified: false,
            override: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleScan = this.handleScan.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.removeItems = this.removeItems.bind(this);
        this.override = this.override.bind(this);


    }

    getAnimationSettings = (originXA, originXB) => {
        return {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            zIndex: 0,
            decay: 0.92,
            particleCount: 150,
            origin: {
                x: randomInRange(originXA, originXB),
                y: Math.random() - 0.2
            }
        };
    };


    nextTickAnimation = () => {
        this.animationInstance &&
        this.animationInstance(this.getAnimationSettings(0.1, 0.3));
        this.animationInstance &&
        this.animationInstance(this.getAnimationSettings(0.7, 0.9));
        window.setTimeout(this.stopAnimation, 5000);
    };

    startAnimation = () => {
        if (!this.isAnimationEnabled) {
            this.isAnimationEnabled = true;
            this.intervalId = window.setInterval(this.nextTickAnimation, 400);
        }
    };

    stopAnimation = () => {
        this.isAnimationEnabled = false;
        this.animationInstance && this.animationInstance.reset();
        return this.intervalId && clearInterval(this.intervalId);
    };

    getInstance = (instance) => {
        this.animationInstance = instance;
    };

    getWeather(scan) {
        fetch(domain + (this.props.route ? this.props.route : "/api/packdaddy/queryWeather"), {
            method: "POST",
            body: JSON.stringify({
                shipNum: scan
            }),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json()).then(data => {
            this.setState({
                location: data[0].location,
                showLoading: false
            }, () => {
                console.log(JSON.stringify(this.state.location))
                fetch("https://api.openweathermap.org/data/2.5/forecast/daily?zip=" + this.state.location + "&units=imperial&cnt=3&appid=" + this.weatherKey + "")
                    .then(res => res.json())
                    .then(d => {
                        this.setState({
                            weatherInfo: d
                        }, () => {
                            console.log(this.state.weatherInfo);
                            if (d.message === "city not found") {
                                audioError.play();
                                Swal.fire(
                                    'Postal Code Error',
                                    'Could not find postal code in the system',
                                    'error'
                                )
                            }
                        })

                    }).catch(err => {
                    this.setState({
                        showLoading: false
                    })
                    console.log(err)
                    audioError.play();
                    Swal.fire(
                        'Postal Code Error',
                        'Could not fetch the weather forecast for this location',
                        'error'
                    )
                });
            });
        }).catch(err => {
            this.setState({showLoading: false})
            this.trigger = false;
            audioError.play();
            Swal.fire(
                'Postal Code Error',
                'Could not find postal code in the system',
                'error'
            )
            console.log("Error: " + err)
        });
        this.weatherKey = '0fbcce9011465a5b7bc46316fbbdcb0b';

    }


    handleSubmit(e) {
        console.log(e);
        this.setState({showLoading: true})
        let itemScanList = this.state.itemScanList.sort((a,b) => a.Product.localeCompare(b.Product))
        let itemList = this.state.itemList.sort((a,b) => a.Product.localeCompare(b.Product))

        console.log(JSON.stringify(itemScanList))
        console.log(JSON.stringify(itemList))

        let isSame = itemList.length === itemScanList.length && itemList.every((o, i) => Object.keys(o).length === Object.keys(itemScanList[i]).length && Object.keys(o).every(k => o[k] === itemScanList[i][k]));
        console.log(isSame);
        console.log(this.state.employee)
        console.log(this.state.employee.length)
        if (this.state.employee.length < 1) {
            this.setState({showLoading: false}, () => {
                audioError.play();
                Swal.fire(
                    'Employee Error!',
                    'Choose an employee name',
                    'error'
                )
            })

        } else {
            if (isSame && itemList.length > 0) {
                this.setState({showLoading: false, verified: true})
                this.startAnimation();
                audioFire.play();
                Swal.fire(
                    'Verification Success!',
                    'The pick list matches with the items',
                    'success'
                )
                fetch(domain + (this.props.route ? this.props.route : "/api/packdaddy/insertOrder"), {
                    method: "POST",
                    body: JSON.stringify({
                        shipNum: this.state.shipNum,
                        userCreate: this.state.employee,
                        itemList: this.state.itemList
                    }),
                    headers: {'Content-Type': 'application/json'}
                }).then(res => res.json()).then(data => {
                    if (data.success) {
                        console.log("Inserted into DB")
                        this.verifyClear();
                    }
                }).catch(err => {
                    audioError.play();
                    this.setState({showLoading: false})
                    console.log(err)
                })


            } else {
                Swal.fire(
                    'Verification Failed!',
                    'Please check the scanned items',
                    'error'
                )
                audioSad.play();
                this.setState({showLoading: false})
            }
        }
    }

    handleChange(event) {
        this.setState({employee: event.label}, () => {
            console.log(this.state.employee)
        });
    }

    handleClear(event) {
        this.setState({
            employee: '',
            shipNum: '',
            lotNum: '',
            itemList: [],
            barcodeScan: '',
            showLoading: false,
            result: '',
            shipScan: false,
            lotScan: false,
            lotList: [],
            itemScan: '',
            quantity: 1,
            previousLot: '',
            itemScanList: [],
            newScanList: [],
            previousItem: '',
            decrement: false,
            location: '',
            weatherInfo: [],
            verified: false
        })

    }

    verifyClear() {
        this.setState({
            shipNum: '',
            lotNum: '',
            itemList: [],
            barcodeScan: '',
            showLoading: false,
            result: '',
            shipScan: false,
            lotScan: false,
            lotList: [],
            itemScan: '',
            quantity: 1,
            previousLot: '',
            itemScanList: [],
            newScanList: [],
            previousItem: '',
            decrement: false,
            location: '',
            weatherInfo: [],
            verified: false
        })

    }

    pullItem() {
        let newList;
        fetch(domain + (this.props.route ? this.props.route : "/api/packdaddy/queryItem"), {
            method: "POST",
            body: JSON.stringify({
                lotNum: this.state.lotNum.length > 15 ? this.state.lotNum.substring(0, this.state.lotNum.indexOf(":")) : this.state.lotNum
            }),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json()).then(data => {
            console.log(data.itemOut)
            if (data.itemOut === "Error") {
                this.setState({showLoading: false}, () => {
                    audioError.play();
                    Swal.fire(
                        'Invalid Scan',
                        'Please scan a valid lot number related to the shipment',
                        'error'
                    )
                })
            } else {
                if (this.state.lotList.includes(this.state.lotNum)) {
                    newList = this.state.itemScanList
                        .map((item, index) => {
                            if (item.Lot === this.state.lotNum && !this.state.decrement) {

                                console.log(item.Product)
                                console.log(this.state.itemScan)
                                return {Product: item.Product, QTY: item.QTY + 1, Lot: item.Lot}
                            }
                            if (item.Lot === this.state.lotNum && this.state.decrement) {

                                console.log(item.Product)
                                console.log(this.state.itemScan)
                                return {Product: item.Product, QTY: item.QTY - 1, Lot: item.Lot}
                            } else {
                                return {Product: item.Product, QTY: item.QTY, Lot: item.Lot}
                            }
                        })

                    this.setState({
                        itemScanList: newList.map((item) => {
                            return {Product: item.Product, QTY: item.QTY, Lot: item.Lot}
                        }),
                        showLoading: false,
                        shipScan: true,
                        previousLot: this.state.lotNum
                    }, () => {
                        console.log("Meta: " + JSON.stringify(this.state.itemScanList))
                    })
                } else {
                    if (this.state.decrement) {
                        this.setState({showLoading: false})
                        audioError.play();
                        Swal.fire(
                            'Scan Out Error',
                            'You cannot scan out this item',
                            'error'
                        )
                    } else {
                        this.setState({
                            itemScan: data.itemOut,
                            showLoading: false,
                            shipScan: true,
                            previousLot: this.state.lotNum,
                            lotList: [...this.state.lotList, this.state.lotNum],
                            itemScanList: [...this.state.itemScanList, {
                                Product: data.itemOut,
                                QTY: 1,
                                Lot: this.state.lotNum
                            }]
                        }, () => {
                            console.log("Data: " + JSON.stringify(this.state.itemScanList))
                            console.log("Lot: " + JSON.stringify(this.state.lotList))
                        });
                    }
                }
                this.trigger = false;
                audioSuccess.play();
                toast.success("You have scanned: " + this.state.lotNum, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
        }).catch(err => {
            this.setState({showLoading: false}, () => {
                audioError.play();
                Swal.fire(
                    'Invalid Scan',
                    'Please scan a valid lot number related to the shipment',
                    'error'
                )
            })
            this.trigger = false;
            JSON.stringify(this.state.itemScanList)
            console.log("Error: " + err)
        });
    }

    fixLotNum(lot) {
        console.log(lot)
        let serial = "";
        if (lot.includes(":")) {
            serial = lot.split(':').pop();
            lot = lot.substring(0, lot.indexOf(":"))
        }

        if (lot.startsWith("L") && lot.length === 7) {
            if (serial.length > 0) {
                this.setState({lotNum: lot.substring(0, 1) + "00000000" + lot.substring(1) + ":" + serial}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            } else {
                this.setState({lotNum: lot.substring(0, 1) + "00000000" + lot.substring(1)}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            }
        } else if (lot.startsWith("F") && lot.length === 8) {
            if (serial.length > 0) {
                this.setState({lotNum: lot.substring(0, 4) + "0000000" + lot.substring(4) + ":" + serial}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            } else {
                this.setState({lotNum: lot.substring(0, 4) + "0000000" + lot.substring(4)}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            }
        } else if (lot.startsWith("A") && lot.length === 9 && lot.includes("F")) {
            if (serial.length > 0) {
                this.setState({lotNum: lot.substring(0, 5) + "000000" + lot.substring(5) + ":" + serial}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            } else {
                this.setState({lotNum: lot.substring(0, 5) + "000000" + lot.substring(5)}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            }

        } else if (lot.startsWith("B") && lot.length === 9 && lot.includes("F")) {
            if (serial.length > 0) {
                this.setState({lotNum: lot.substring(0, 5) + "000000" + lot.substring(5) + ":" + serial}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            } else {
                this.setState({lotNum: lot.substring(0, 5) + "000000" + lot.substring(5)}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            }

        } else if (lot.startsWith("A") && lot.length === 7 && lot.includes("L")) {
            if (serial.length > 0) {
                this.setState({lotNum: lot.substring(0, 2) + "0000000" + lot.substring(2) + ":" + serial}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            } else {
                this.setState({lotNum: lot.substring(0, 2) + "0000000" + lot.substring(2)}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            }


        } else if (lot.startsWith("A") && lot.length === 9) {
            if (serial.length > 0) {
                this.setState({lotNum: lot.substring(0, 3) + "000000" + lot.substring(3) + ":" + serial}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            } else {
                this.setState({lotNum: lot.substring(0, 3) + "000000" + lot.substring(3)}, () => {
                    console.log(this.state.lotNum)
                    this.pullItem();
                })
            }

        } else {
            this.setState({showLoading: false}, () => {
                audioError.play();
                Swal.fire(
                    'Invalid Lot Number',
                    'Please check the lot number',
                    'error'
                )
            })
        }
    }

    handleError(err) {
        console.error(err)
    }

    removeItems(e) {
        if (this.state.decrement) {
            Swal.fire(
                'Item Quantity',
                'You have chosen to increase the item quantity',
                'warning'
            )
            this.setState({decrement: e})
        } else {
            Swal.fire(
                'Item Quantity',
                'You have chosen to decrease the item quantity',
                'warning'
            )
            this.setState({decrement: e})
        }

    }

    override(e) {
        if (this.state.override) {
            Swal.fire(
                'Scan Duplicate Shipment',
                'You have turned off duplicate shipment check',
                'warning'
            )
            this.setState({override: e})
        } else {
            Swal.fire(
                'Scan Duplicate Shipment',
                'You have turned on duplicate shipment check',
                'warning'
            )
            this.setState({override: e})
        }

    }


    componentDidMount() {
        fetch(domain + "/api/packdaddy/employees").then(res => res.json())
            .then(data => {
                data = data.map(d => {
                    d.value = d.Username;
                    d.label = d.Username;
                    return d
                });
                this.setState({employeesList: data})
            });
    }

    handleScan(scan) {
        this.setState({showLoading: true});

        if (this.state.shipScan === false) {
            fetch(domain + (this.props.route ? this.props.route : "/api/packdaddy/checkOrder"), {
                method: "POST",
                body: JSON.stringify({
                    shipNum: scan,
                    override: this.state.override
                }),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json()).then(data => {
                if (data.scanned && this.state.override === false) {
                    this.setState({showLoading: false}, () => {
                        audioError.play();
                        Swal.fire(
                            'Shipment Error',
                            'This shipment has already been verified',
                            'error'
                        )
                    });
                } else if (data.result === "Error") {
                    this.setState({showLoading: false}, () => {
                        audioError.play();
                        Swal.fire(
                            'Shipment Error',
                            'Please check the shipment ID',
                            'error'
                        )
                    });
                } else {
                    fetch(domain + (this.props.route ? this.props.route : "/api/packdaddy/queryShip"), {
                        method: "POST",
                        body: JSON.stringify({
                            shipNum: scan
                        }),
                        headers: {'Content-Type': 'application/json'}
                    }).then(res => res.json()).then(data => {
                        if (data.shipOut === "Error") {
                            this.setState({showLoading: false}, () => {
                                audioError.play();
                                Swal.fire(
                                    'Invalid Scan',
                                    'Please scan a valid shipment ID',
                                    'error'
                                )
                            })
                        } else {
                            this.setState({
                                itemList: data.shipOut,
                                showLoading: false,
                                shipScan: true,
                                shipNum: scan
                            }, () => {
                                console.log("Data: " + JSON.stringify(this.state.itemList))
                                this.getWeather(scan);
                            });
                            this.trigger = false;
                            audioSuccess.play();
                            toast.success("You have scanned: " + scan, {
                                position: "top-center",
                                autoClose: 1000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }).catch(err => {
                        this.setState({showLoading: false})
                        this.trigger = false;
                        console.log("Error: " + err)
                    });
                }
            }).catch(err => {
                this.setState({showLoading: false})
                this.trigger = false;
                console.log("Error: " + err)
            });
        }
        if (this.state.shipScan === true) {
            this.fixLotNum(scan)
        }

    }


    render() {
        const customStyles = {
            option: provided => ({
                ...provided,
                color: 'black'
            }),
            control: provided => ({
                ...provided,
                color: 'black'
            }),
            singleValue: (provided) => ({
                ...provided,
                color: 'black'
            })
        }
        return (<React.Fragment>
                <Overlay showLoading={this.state.showLoading}/>
                <ToastContainer/>
                <div className={"container-fluid pt-3"}>
                    <div className={"row"}>
                        <div className="col-xl-12 col-lg-14 col-md-14 col-16 pb-2">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Pack Daddy</h4>
                                </div>
                                <ReactCanvasConfetti
                                    refConfetti={this.getInstance}
                                    style={{
                                        position: 'fixed',
                                        pointerEvents: 'none',
                                        width: '100%',
                                        height: "100%",
                                        top: 0,
                                        left: 0
                                    }}
                                />
                                <BarcodeReader
                                    onError={this.handleError}
                                    onScan={this.handleScan}
                                />
                                <div className={"card-body "}>
                                    <br/>
                                    <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                                        <div className='form-group'>
                                            <h6 style={{color: 'White', fontWeight: 600}}>Employee</h6>
                                            <Select
                                                placeholder={'Select Employee'}
                                                options={this.state.employeesList}
                                                value={{
                                                    label: this.state.employee
                                                }}
                                                styles={customStyles}
                                                onChange={this.handleChange.bind(this)}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: 'neutral80',
                                                        primary25: 'hotpink',
                                                        primary: 'black',
                                                    }
                                                })}/>
                                            <br/>
                                            <Switch height={15} width={34} checked={this.state.decrement}
                                                    onChange={this.removeItems}></Switch> &nbsp; <b>Scan Out</b>
                                            <br/> <br/>
                                            <Switch height={15} width={34} checked={this.state.override}
                                                    onChange={this.override}></Switch> &nbsp; <b>Override Shipment</b>
                                            <br/><br/>
                                        </div>
                                    </div>
                                    <br/><br/>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col s12 m6">
                                                <h6>CSI Packing List</h6>
                                                <div className="text-center" style={{fontSize: '12px'}}>
                                                    <table className={"table table-striped table-bordered"}>
                                                        <thead className="text-center">
                                                        <tr>
                                                            <th style={{fontSize: '12px'}}> {"Product"} </th>
                                                            <th style={{fontSize: '12px'}}> {"Lot"} </th>
                                                            <th style={{fontSize: '12px'}}> {"Quantity"} </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody className="text-center">
                                                        {this.state.itemList.map((item) => (
                                                                <tr>
                                                                    <td style={{fontSize: '12px'}}> {item.Product} </td>
                                                                    <td style={{fontSize: '12px'}}> {item.Lot} </td>
                                                                    <td style={{fontSize: '12px'}}> {item.QTY} </td>

                                                                </tr>
                                                            )
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                            <br/><br/>

                                            <div className="col s12 m6">
                                                <h6>Items Scanned</h6>
                                                <div className="text-center" style={{fontSize: '12px'}}>
                                                    <table className={"table table-striped table-bordered"}>
                                                        <thead className="text-center">
                                                        <tr>
                                                            <th style={{fontSize: '12px'}}> {"Product"} </th>
                                                            <th style={{fontSize: '12px'}}> {"Lot"} </th>
                                                            <th style={{fontSize: '12px'}}> {"Quantity"} </th>

                                                        </tr>
                                                        </thead>
                                                        <tbody className="text-center">
                                                        {this.state.itemScanList.map((item) => (
                                                                <tr>
                                                                    <td style={{fontSize: '12px'}}> {item.Product} </td>
                                                                    <td style={{fontSize: '12px'}}> {item.Lot} </td>
                                                                    <td style={{fontSize: '12px'}}> {item.QTY} </td>

                                                                </tr>
                                                            )
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <br/><br/>
                                        <div className="card-footer">
                                            {!this.state.verified ?
                                                <button className={"btn btn-outline-success"}
                                                        style={{float: 'left'}}
                                                        onClick={this.handleSubmit}>Verify
                                                </button> : <div></div>}
                                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            <button className={"btn btn-outline-info"}
                                                    onClick={() => {
                                                        WeatherPanel.display()
                                                    }}>Check Weather
                                            </button>
                                            <button className={"btn btn-outline-danger"}
                                                    style={{float: 'right'}}
                                                    onClick={this.handleClear}>Clear
                                            </button>
                                        </div>
                                        <br/><br/>
                                        {Object.keys(this.state.weatherInfo).length > 0 && !Object.values(this.state.weatherInfo).includes("404") ?
                                            <WeatherPanel data={this.state.weatherInfo}/> :
                                            <div></div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PackDaddy;