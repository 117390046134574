import React from "react";
import {Bar, ChartData, Line} from "react-chartjs-2";
import Chart, {ChartTooltipOptions, ChartXAxe, ChartYAxe} from "chart.js";

export interface LineProps {
    datasets:ChartData<ChartData<any>>[]
    labels:any[]
    chartYAxe?:ChartYAxe[]
    tooltips?: ChartTooltipOptions
    chartXAxe?:ChartXAxe[]
}
export class LineChart extends React.Component<LineProps, {}>{

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Line data={{
                datasets:this.props.datasets,
                labels:this.props.labels,
            }}
                 options={{
                     legend:{
                         labels:{
                             fontColor:"#fff"
                         }
                     },
                     tooltips:this.props.tooltips ? this.props.tooltips : {},
                     scales:{
                         xAxes:this.props.chartXAxe ? this.props.chartXAxe : [{
                             type:'time',
                             ticks:{fontColor:"#FFF"},
                             gridLines:{
                                 color:"#ffffe4"
                             },
                             distribution: 'linear'
                         }],
                          yAxes:this.props.chartYAxe ? this.props.chartYAxe : [{
                              ticks:{
                                  fontColor:"#ffffe4",
                              },
                              gridLines:{
                                  color:"#FFF"
                              }}]
                     },
                 }}/>
        )
    }
}