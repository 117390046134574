import React from "react";
import palette from "google-palette"
import {LineChart} from "../immyforce/LineChart";
import domain from "../../Domain";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Material from "../../types/Material";
import Chart from "chart.js";
import {toLocalDate} from "../../util/FormatUtil";
import moment from "moment";
export interface MaterialAnalysisState{
    items:any
    colors:[]
    filter:{product_family?:string[], selected_items?:string[], process?:string[]}
}
export class MaterialAnalysis extends React.Component<any, MaterialAnalysisState>{

    constructor(props) {
        super(props);
        let colors = palette('mpn65', 65);
        colors = colors.map(c => "#"+c);
        this.state = {items:null, colors:colors,filter:{product_family:[], selected_items:[], process:[]}};
    }



    buildLineChart(data, labels, title){
        return (
            <div className={"col-6 pt-2"}>
                <div className="card shadow-lg primary-black-bg white">
                    <div className="card-header green-border ">
                        <h4>{title}</h4>
                    </div>
                    <div className="card-body">
                        <LineChart datasets={data} labels={labels} chartYAxe={[{
                            ticks:{
                                fontColor:"#ffffe4",
                                callback: function(value, index, values) {
                                    // Convert the number to a string and splite the string every 3 charaters from the end
                                    // value = value.toString();
                                    const formatter = new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2
                                    })
                                    formatter.format(parseFloat(value as string));
                                    return '$ ' + value;
                                },
                            },
                            gridLines:{
                                color:"#FFF"
                            }}]}
                           chartXAxe={[{
                               type:'time',
                               ticks:{fontColor:"#FFF"},
                               gridLines:{
                                   color:"#ffffe4"
                               },
                               distribution: 'linear'
                           }]}
                        tooltips={{
                            callbacks:{
                                afterLabel(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                                    // @ts-ignore

                                    return "Lot: " + (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].obj.lot : "");
                                }
                            }
                        }}/></div></div></div>);
    }

    componentDidMount(): void {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('custseq')
        console.log(token)//123
        console.log(this.props.location.query)
        // C000001
        fetch(domain + "/api/materials",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => this.setState({
                items:data.items,
            }))
            .catch(e => alert("Failed to fetch locations"));
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        let lineData = [];
        let qty = [];
        let unit_cost = [];
        let lbr_cost = [];
        let matl_cost = [];
        let vovhd_cost = [];
        let itemSelection = [];
        let stackedBar = [];
        let products = [];
        console.log(this.state.filter)
        let selectedYear = "2020";
        let monthlyBreakdown = this.state.items;
        let selected_code = this.state.filter.process ? this.state.filter.process[0] : null
        products = monthlyBreakdown && monthlyBreakdown[selected_code] ? Object.keys(monthlyBreakdown[selected_code]) : []

        let monthlyLabels = [];
        for(let i = 1; i < 12; i ++){
            monthlyLabels.push(new Date("1-" + i + "-" + selectedYear))
        }

        if(this.state.items && this.state.filter.product_family && this.state.filter.product_family[0] !== ""){
            let selectedFamily = this.state.filter.product_family[0];
            let items = monthlyBreakdown[selected_code][selectedFamily] ? Object.keys(monthlyBreakdown[selected_code][selectedFamily]) : [];
            itemSelection = items;
            console.log(lineData)
            items.forEach((item:string, index:number) =>{
                if(!this.state.filter.selected_items || !this.state.filter.selected_items.includes(item))
                    return
                console.log(item)
                // let months = Object.keys(lineData[selectedFamily][item][selectedYear]);
                let materials = Object.values(monthlyBreakdown[selected_code][selectedFamily][item][selectedYear])
                let qty_data = {data:[], borderColor:this.state.colors[index], label:item, lineTension: 0,}
                let unit_cost_data = {data:[], borderColor:this.state.colors[index], label:item, lineTension: 0,}
                let unit_vovhd_data = {data:[], borderColor:this.state.colors[index], label:item, lineTension: 0,}
                let unit_matl_data = {data:[], borderColor:this.state.colors[index], label:item, lineTension: 0,}
                let unit_lbr_data = {data:[], borderColor:this.state.colors[index], label:item, lineTension: 0,}
                materials.forEach((mat:Material)=>{
                    let localTime = toLocalDate(mat.CreateDate)
                    qty_data.data.push({y: mat.qty , t:localTime, obj:mat, pointRadius:8, lineTension: 0,});
                    unit_cost_data.data.push({y:mat.cost, t:localTime, obj:mat, pointRadius:8, lineTension: 0,});
                    unit_vovhd_data.data.push({y:mat.vovhd_cost, t:localTime, obj:mat, pointRadius:8, lineTension: 0,});
                    unit_matl_data.data.push({y:mat.matl_cost, t:localTime, obj:mat, pointRadius:8, lineTension: 0,});
                    unit_lbr_data.data.push({y:mat.lbr_cost, t:localTime, obj:mat, pointRadius:8, lineTension: 0,});
                    return mat;
                })
                qty_data.data.sort((a,b)=> moment(a.t).unix() - moment(b.t).unix());
                unit_cost_data.data.sort((a,b)=> moment(a.t).unix() - moment(b.t).unix());
                unit_vovhd_data.data.sort((a,b)=> moment(a.t).unix() - moment(b.t).unix());
                unit_matl_data.data.sort((a,b)=> moment(a.t).unix() - moment(b.t).unix());
                unit_lbr_data.data.sort((a,b)=> moment(a.t).unix() - moment(b.t).unix());

                qty.push(qty_data)

                unit_cost.push(unit_cost_data);
                lbr_cost.push(unit_lbr_data);
                matl_cost.push(unit_matl_data);
                vovhd_cost.push(unit_vovhd_data);

                console.log(materials)
            })
        }
        console.log(qty)
        return(
            <React.Fragment>
                <div className="container-fluid secondary-slate-bg   pt-5">
                    <div className={"row"}>
                        <div className={"col-12 pb-2"}>
                            <div className="card shadow-lg primary-black-bg white">
                                <div className="card-header">
                                    <h4>Material Analysis</h4>
                                    <div className={"col-5 "}>
                                        <FilterCard
                                            fields={[
                                                {
                                                    key: "process",
                                                    label: "Product Code",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: [{label:"Subassembly", value:"SA"}, {label:"Finished", value:"finished"}],
                                                    isMapped:true
                                                },
                                                {
                                                    key: "product_family",
                                                    label: "Product Line",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: products,
                                                    isMapped:false
                                                },
                                                {
                                                    key: "selected_items",
                                                    label: "Items",
                                                    type: FIELD_TYPE.SELECT,
                                                    isMulti:true,
                                                    options: itemSelection,
                                                    noOpMessage:"Select a product family"
                                                }]} filterChanged={(e)=> this.setState({filter:e})}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-6 pt-2"}>
                            <div className="card shadow-lg primary-black-bg white">
                                <div className="card-header green-border ">
                                    <h4>Quantity Produced</h4>
                                </div>
                                <div className="card-body">
                                    <LineChart datasets={qty} labels={null} chartYAxe={[{
                                        ticks:{
                                            fontColor:"#ffffe4",
                                            callback: function(value, index, values) {
                                                return value.toString() + " units";
                                            },
                                        },
                                        gridLines:{
                                            color:"#FFF"
                                        }}]}
                                               tooltips={{callbacks:{
                                                       afterLabel(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
                                                           // @ts-ignore

                                                           return "Lot: " + (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].obj.lot : "");
                                                       }
                                                   }}} chartXAxe={[{
                                        type:'time',
                                        ticks:{fontColor:"#FFF"},
                                        gridLines:{
                                            color:"#ffffe4"
                                        },
                                        distribution: 'linear'
                                    }]}/>
                                </div>
                            </div>
                        </div>
                        {this.buildLineChart(unit_cost,monthlyLabels, "Total Unit Cost")}
                        {this.buildLineChart(matl_cost,monthlyLabels, "Material Unit Cost")}
                        {this.buildLineChart(vovhd_cost,monthlyLabels, "Variable Overhead Unit Cost")}
                        {this.buildLineChart(lbr_cost,monthlyLabels, "Labor Unit Cost")}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}