import Overlay from "../Overlay";
import React from "react";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import domain from "../../Domain";
import PasswordModal from "../modals/PasswordModal";
import cookie from 'react-cookie';
interface State{
    showLoading:boolean
    password?:string
}

export default class PasswordChange extends React.Component<any, State>{
    constructor(props) {
        super(props);
        this.state = {showLoading:false, password:""}
        this.submit = this.submit.bind(this);
    }

    submit(){
        this.setState({showLoading:true}, () =>{
            fetch(domain + "/api/vaccines/reset", {
                method:"POST",
                body:JSON.stringify({
                    password:cookie.load("password"),
                    GreeterPassword:this.state.password}),
                headers: {'Content-Type': 'application/json'}
            }).then(res =>res.json()).then(data => {
                if(data.success) {
                    alert("Password changed successfully.");
                }
                else if(!data.authenticated){
                    alert("Please sign in")
                    PasswordModal.display();
                }
                this.setState({showLoading:false})
            });
        })
    }

    render() {
        return(<React.Fragment>
            <Overlay showLoading={this.state.showLoading}/>
            <PasswordModal/>
            <div className={"container-fluid pt-3"}>
                <div className={"row"}>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-12 pb-2">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Password Reset</h4>
                            </div>
                            <div className={"card-body "}>
                                <FilterCard fields={[{label: "Password", key: "password", type: FIELD_TYPE.TEXT}]}

                                 filterChanged={(f) =>{this.setState(f)}}/>

                            </div>
                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={(e) =>this.submit()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}