import React from "react";
import Table from "../tables/Table";
import {formatMoney, parseDate} from "../../util/FormatUtil";
import SimpleTable from "../tables/SimpleTable";

class ExpenseModal extends React.Component<{data}, {}>{


    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log("Expense modal", this.props.data)
        return (
            <SimpleTable

                columns={[
                      {label:"Expense Type", key:"ExpenseTypeName"},
                      {label: "Transaction Date", key: "TransactionDate", formatFunc: parseDate},
                      {label: "Vendor", key: "VendorDescription"},
                      {label: "Event", key: "Custom1.Value"},
                      {label: "Department", key: "Custom2.Value"},
                      {label: "Amount", key: "PostedAmount", formatFunc:formatMoney},
                      {label:"Percentage", key:"",
                   rawFormat: (val => {
                       console.log(val)
                       let percentage = val.ItemizationsList[0].AllocationsList[0].Percentage
                       return <div>{percentage}</div>
                   })},
                      {label: "Payment Type", key: "PaymentTypeName"}



                    // {label: "Contact", isList: true, list_key: "contacts", modal_key: "contact", key: "contact.name"}
                ]}
                table_data={this.props.data}
                table_style={"table-striped TEXT-left m-0 p-0"}

            />
        )
    }
}

export default ExpenseModal;