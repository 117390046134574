import React from "react";
import domain from "../../Domain";
import Table from "../tables/Table";
import {formatMoney, getPageOptions, parseDate} from "../../util/FormatUtil";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import {DefaultState} from "../App";
import Overlay from "../Overlay";
import {SampleArchive} from "../../types/immylabs/SampleArchive";
import cookie from 'react-cookie';

interface SamplesState extends DefaultState{
    customers:[]
    table_data:[]
    filter:{}
    page_options:any[];
    selected_page:any
    selectedFirstname?
    selectedLastname?
    selectedDOB?
    selectedEmail?
    selectedPhone?
    selectedSample?

}
class Samples extends React.Component<any, SamplesState>{
    private pageRef: React.RefObject<unknown>;

    constructor(props) {
        super(props);
        this.state = {customers: [], table_data: [], filter:{}, page_options: getPageOptions(1), selected_page:"1"};
        this.pageRef = React.createRef();
        this.querySamples = this.querySamples.bind(this);
    }

    querySamples(page){
        let self = this;
        this.setState({show_loading: true}, ()=> {
            fetch(domain + (this.props.route ? this.props.route : "/api/samples?page=" + page), {
                method: 'POST',
                body: JSON.stringify(this.state.filter),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.table_data) {
                        self.setState({
                            table_data: data.table_data,
                            page_options: getPageOptions(data.num_pages)
                        })
                    } else if (!data.authenticated && data.redirect) {
                        alert("Please sign in")
                        window.location.replace('/api/microsoft/signin')
                    } else if (!data.authenticated && !data.redirect) {
                        alert("You do not have permission to view this.")
                    }
                    self.setState({show_loading: false})
                });
        });
    }
    private save() {
        fetch(domain + (this.props.route ? this.props.route : "/api/samples/edit"), {
            method: 'POST',
            body: JSON.stringify({selected:this.state.selectedSample,
                FirstName:this.state.selectedFirstname,
                LastName:this.state.selectedLastname,
                DOB:this.state.selectedDOB,
                PatientEmail:this.state.selectedEmail,
                PatientPhone:this.state.selectedPhone,
                password:cookie.load("password")
            }),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json()).then(data => {
            if(data.success){
                alert("Saved successfully");
                window['$']("#edit_form").modal('hide')
                this.querySamples(1);
            }else{
                alert("Failed to save.");
                console.log(data.error)
            }
        });
    }
    getEditForm(){
        return (
            <div className="modal fade form_modal" id={"edit_sample"} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{"Form select"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <div className="form-group row">
                                <label  className="col-sm-4 col-form-label">First Name</label>
                                <div className="col-sm-8 p-0 m-0">
                                    <input type={"search"} className="form-control"
                                           value={this.state.selectedFirstname}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({selectedFirstname:e.target.value})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-4 col-form-label">Last Name</label>
                                <div className="col-sm-8 p-0 m-0">
                                    <input type={"search"} className="form-control"
                                           value={this.state.selectedLastname}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({selectedLastname:e.target.value})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-4 col-form-label">DOB</label>
                                <div className="col-sm-8 p-0 m-0">
                                    <input type={"search"} className="form-control"
                                           value={this.state.selectedDOB}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({selectedDOB:e.target.value})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-4 col-form-label">Email</label>
                                <div className="col-sm-8 p-0 m-0">
                                    <input type={"search"} className="form-control"
                                           value={this.state.selectedEmail}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({selectedEmail:e.target.value})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-4 col-form-label">Phone</label>
                                <div className="col-sm-8 p-0 m-0">
                                    <input type={"search"} className="form-control"
                                           value={this.state.selectedPhone}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({selectedPhone:e.target.value})}/>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={(e) => this.save()}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>)
    }

    displayEditForm(){
        console.log("Display called")
        window["$"]("#edit_sample").modal('show');

    }

    componentDidMount(): void {
        let self = this;
        fetch(domain+ (this.props.route ? this.props.route : "/api/samples/customers")).then(res => res.json()).then(data =>{
            data = data.map(d =>{
                return {label:d.FacilityName, value:d}
            });
            self.setState({customers:data})
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                {this.getEditForm()}
            <Overlay showLoading={this.state.show_loading}/>
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-1 ">
                <div className={"row"}>
                    <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Sample Filtering</h4>
                            </div>
                            <div className={"card-body"}>
                                <FilterCard

                                    fields={[
                                        {key: "customers", label: "Customer", isMulti:true, type: FIELD_TYPE.SELECT, options:this.state.customers, isMapped: true},
                                        {key: "TestType", label: "Test", type: FIELD_TYPE.SELECT, options: [{label:"PCR", value:"2019 Novel Coronavirus (COVID-19) PCR Test"}, {label:"EIA", value:"COVID-19 Antibody EIA Test (Test Code: COV2Ab)"}], noOpMessage: "Select a Test", isMapped:true, isMulti:true},
                                        {key: "result", label: "Result", type: FIELD_TYPE.SELECT, options: [{label:"Positive", value:"Positive"},{label:"Negative", value:"Negative"},{label:"Not Detected", value:"Not Detected"}, {label:"Inconclusive", value:"Inconclusive"}], noOpMessage: "Select a Result", isMapped:true, isMulti:true},
                                        // {key: "gender", label: "Gender", type: FIELD_TYPE.SELECT, options: [{label:"Male", value:"Male"},{label:"Female", value:"Female"}], noOpMessage: "Select a Gender", isMapped:true, isMulti:true},
                                        // {key: "result_start_date", label: "Start Result Date", type: FIELD_TYPE.DATE},
                                        // {key: "result_end_date", label: "End Result Date", type: FIELD_TYPE.DATE},
                                        {key: "first_name", label: "First Name", type: FIELD_TYPE.TEXT},
                                        {key: "last_name", label: "Last Name", type: FIELD_TYPE.TEXT},
                                        {key: "email", label: "Email", type: FIELD_TYPE.TEXT},
                                        {key: "RequNum", label: "Conf. Code", type: FIELD_TYPE.TEXT},
                                        {key: "SpecimenUID", label: "Specimen ID", type: FIELD_TYPE.TEXT},
                                        {key: "CollectionDate", label: "CollectionDate", type: FIELD_TYPE.DATE},

                                    ]}
                                    filterChanged={(filter) => {
                                        // console.log("Filter changed", filter)
                                        this.setState({filter: filter})
                                    }
                                    }
                                />
                            </div>
                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={(e) =>this.querySamples(1)}>Apply
                                </button>
                                <button className={"btn btn-outline-danger float-right"}
                                        onClick={() => window.location.reload()}>Clear
                                </button>
                            </div>
                        </div>

                    </div>
            <div className="col-12 pb-2 pt-2">
            <div className={"card shadow-lg primary-black-bg white"}>
                <div className="card-header green-border ">
                    <h4>Samples
                        <div className=" align-middle float-right pages">
                            <Select
                                isSearchable={true}
                                placeholder={"1"}
                                noOptionsMessage={() => "Failed to Load"}
                                // inputValue={this.state.selected_page}
                                // ref={this.pageRef}
                                onChange={(e) =>{
                                    this.setState({selected_page:e.value},()=>
                                        this.querySamples(e.value))
                                }}
                                className={"state_select"}
                                options={this.state.page_options}
                            />
                        </div></h4>
                </div>
                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                    <Table columns={[
                        {label: "Name", key: "name", valueClick:(data:SampleArchive) => this.setState(
                                {
                                    selectedSample:data,
                                    selectedFirstname:data.PFirstName,
                                    selectedLastname:data.PLastName,
                                    selectedDOB:parseDate(data.PatientDOB as unknown as string),
                                    selectedEmail:data.PatientEmail,
                                    selectedPhone:data.PatientPhone
                                },
                                () => this.displayEditForm())},
                        {label: "Conf. Num", key: "RequNum",},
                        {label: "DOB", key: "PatientDOB", formatFunc:parseDate},
                        {label: "Email", key: "PatientEmail"},
                        {label: "Sample Origin", key: "customer.FacilityName", isOwnModalData:true, modal_data:true},
                        {label: "Collection Date", key: "CollectionDate", formatFunc:parseDate},
                        {label: "Specimen UID", key: "SpecimenUID",},
                        {label: "Result", key: "Result",},
                        // {label: "Physician Name", key: "PhysicianName",},
                        // {label: "Gender", key: "Gender",},
                        {label: "Test", key: "TestType", formatFunc: (data) => {
                            if(data === "2019 Novel Coronavirus (COVID-19) PCR Test") {
                                return "PCR"
                            } else if(data === "COVID-19 Antibody EIA Test (Test Code: COV2Ab)"){}
                                return "EIA"
                            }},
                        {label: "Location", key: "origin",},]}
                           table_data={this.state.table_data ? this.state.table_data : []}
                           load_modal={this.props.open_modal}/>
                </div>
            </div>
            </div>
                </div>
        </div>
            </React.Fragment>)
    }
}

export default Samples;