import React from "react";
import domain from "../../Domain";
import Table from "../tables/Table";
import {formatMoney, getPageOptions, parseDate} from "../../util/FormatUtil";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import {DefaultState} from "../App";
import Overlay from "../Overlay";
import ModalForm from "../modals/ModalForm";

import PodSelect from "./PodSelect";
import PasswordModal from "../modals/PasswordModal";
import cookie from 'react-cookie';
import Form from "../../types/immylabs/Form";

interface FormSearchState extends DefaultState{
    customers:[]
    table_data:[]
    filter:{}
    page_options:any[];
    selected_page:any
    selectedForm?
    selectedFirstname?
    selectedLastname?:string
    selectedDOB?:string
    selectedSpecimen?
    PodApptID?
}
class FormSearch extends React.Component<{route:string, open_modal?:any}, FormSearchState>{
    private pageRef: React.RefObject<unknown>;

    constructor(props) {
        super(props);
        this.state = {customers: [], table_data: [], filter:{}, page_options: getPageOptions(1), selected_page:"1"};
        this.pageRef = React.createRef();
        this.querySamples = this.querySamples.bind(this);
    }

    querySamples(page){
        let self = this;
        let filter = this.state.filter ? JSON.parse(JSON.stringify(this.state.filter)) : {};
        if(Object.keys(filter).length === 0 && filter.constructor === Object){
            alert("At least one filter is required.")
            return;
        }
        filter.PodApptID = this.state.PodApptID;
        filter.password = cookie.load("password");
        console.log(filter);
        this.setState({show_loading: true}, ()=> {
            fetch(domain + (this.props.route ? this.props.route : "/api/forms?page=" + page), {
                method: 'POST',
                body: JSON.stringify(filter),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.table_data) {
                        self.setState({
                            table_data: data.table_data,
                            page_options: getPageOptions(data.num_pages)
                        })
                    } else if (!data.authenticated) {
                        alert("Sign in");
                        PasswordModal.display();
                    }
                    self.setState({show_loading: false})
                });
        });
    }
    private save() {
        fetch(domain + (this.props.route ? this.props.route : "/api/forms/edit"), {
            method: 'POST',
            body: JSON.stringify({selected:this.state.selectedForm,
                FirstName:this.state.selectedFirstname,
                LastName:this.state.selectedLastname,
                DOB:this.state.selectedDOB,
                SpecimenID:this.state.selectedSpecimen,
                password:cookie.load("password")
            }),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json()).then(data => {
            if(data.success){
                alert("Saved successfully");
                window['$']("#edit_form").modal('hide')
                this.querySamples(1);
            }else{
                alert("Failed to save.");
                console.log(data.error)
            }
        });
    }

    componentDidMount(): void {
        let self = this;
       // this.querySamples(1);


        fetch(domain+ (this.props.route ? this.props.route : "/api/samples/customers")).then(res => res.json()).then(data =>{
            data = data.map(d =>{
                return {label:d.FacilityName, value:d}
            })
            self.setState({customers:data})
        })
    }
    getEditForm(){
        return (
            <div className="modal fade form_modal" id={"edit_form"} tabIndex={-1} role="dialog"
                       aria-labelledby="result_modal_label"
                       aria-hidden="true">
            <div className="modal-dialog modal-lg modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="result_modal_label">{"Form select"}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body ">
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">First Name</label>
                            <div className="col-sm-8 p-0 m-0">
                                <input type={"search"} className="form-control"
                                       value={this.state.selectedFirstname}
                                       autoComplete="off"
                                       onChange={(e) => this.setState({selectedFirstname:e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Last Name</label>
                            <div className="col-sm-8 p-0 m-0">
                                <input type={"search"} className="form-control"
                                       value={this.state.selectedLastname}
                                       autoComplete="off"
                                       onChange={(e) => this.setState({selectedLastname:e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">DOB</label>
                            <div className="col-sm-8 p-0 m-0">
                                <input type={"search"} className="form-control"
                                       value={this.state.selectedDOB}
                                       autoComplete="off"
                                       onChange={(e) => this.setState({selectedDOB:e.target.value})}/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Specimen ID</label>
                            <div className="col-sm-8 p-0 m-0">
                                <input type={"search"} className="form-control"
                                       value={this.state.selectedSpecimen}
                                       autoComplete="off"
                                       onChange={(e) => this.setState({selectedSpecimen:e.target.value})}/>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={(e) => this.save()}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>)
    }

    public static display(){
        console.log("Display called")
        window["$"]("#edit_form").modal('show');

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log("Filter", this.state.filter)
        return (
            <React.Fragment>
                <PasswordModal/>
                <Overlay showLoading={this.state.show_loading}/>
                {this.getEditForm()}
                <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-1 ">
                    <div className={"row"}>
                        <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Form Filtering</h4>
                                </div>
                                <div className={"card-body"}>
                                    <FilterCard

                                        fields={[
                                            {key: "FirstName", label: "First Name", type: FIELD_TYPE.TEXT},
                                            {key: "LastName", label: "Last Name", type: FIELD_TYPE.TEXT},
                                            {key: "CollectionDate", label: "Collection Date", type: FIELD_TYPE.DATE},
                                            {key: "SpecimenID", label: "Specimen ID", type: FIELD_TYPE.TEXT},
                                            {key: "ReqNum", label: "Confirmation Code", type: FIELD_TYPE.TEXT},

                                        ]}
                                        filterChanged={(filter) => {
                                            // console.log("Filter changed", filter)
                                            this.setState({filter: filter})
                                        }
                                        }
                                    />
                                    <PodSelect onChange={(e) => this.setState({PodApptID: e && e.pod ? e.pod[0] : null})}/>
                                </div>
                                <div className="card-footer green-border-top">
                                    <button className={"btn btn-outline-info"} onClick={(e) =>this.querySamples(1)}>Apply
                                    </button>
                                    <button className={"btn btn-outline-danger float-right"}
                                            onClick={() => window.location.reload()}>Clear
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 pb-2 pt-2">
                            <div className={"card shadow-lg primary-black-bg white"}>
                                <div className="card-header green-border ">
                                    <h4>Forms
                                        <div className=" align-middle float-right pages">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "Failed to Load"}
                                                // inputValue={this.state.selected_page}
                                                // ref={this.pageRef}
                                                onChange={(e) =>{
                                                    this.setState({selected_page:e.value},()=>
                                                        this.querySamples(e.value))
                                                }}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div></h4>
                                </div>
                                <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                    <Table columns={[
                                        {label: "Name", key: "Name", formatFunc: (str, data:Form)=>{
                                                return <a href={"#!"} onClick={() =>{
                                                    console.log(data);
                                                    this.setState(
                                                    {
                                                        selectedForm:data,
                                                        selectedFirstname:data.FirstName,
                                                        selectedLastname:data.LastName,
                                                        selectedDOB:parseDate(data.DOB as unknown as string),
                                                        selectedSpecimen:data.SpecimenID ? data.SpecimenID : ""
                                                    },
                                                    () => FormSearch.display())}}>{data["Name"]}</a>
                                            }},
                                        {label: "DOB", key: "DOB", formatFunc:parseDate},
                                        {label: "Collection Date", key: "CollectionDate", formatFunc:parseDate},
                                        {label: "Specimen ID", key: "SpecimenID"},
                                        {label: "Client Acct", key: "ClientAcct",},
                                        {label: "Conf. Code", key: "ReqNum",},
                                        {label: "Pod", key: "PodApptID",},
                                        // {label: "Physician Name", key: "PhysicianName",},
                                        {label: "Hosp. Patient ID", key: "HospPatientID",},
                                        // {label: "Gender", key: "Gender",},
                                        {label: "Test", key: "TestType", formatFunc: (data) => {
                                                if(data === "2019 Novel Coronavirus (COVID-19) PCR Test") {
                                                    return "PCR"
                                                } else if(data === "COVID-19 Antibody EIA Test (Test Code: COV2Ab)"){
                                                    return "EIA"
                                                }
                                                return ""
                                            }},
                                    ]}
                                           table_data={this.state.table_data ? this.state.table_data : []}
                                           load_modal={this.props.open_modal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }

}

export default FormSearch;