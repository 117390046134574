import React from "react";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import Select from "react-select";
import SimpleTable from "../tables/SimpleTable";
import {EquipmentRecord} from "../../types/EquipmentRecord";
import {getPageOptions, getTaskTeams, parseDate, toTrueFalse} from "../../util/FormatUtil";
import {EquipmentRecordModal} from "../modals/EquipmentRecordModal";
import {TrainingModuleModal} from "../modals/TrainingModuleModal";
import {Course} from "../../types/TrainingTypes";
import domain from "../../Domain";

interface ModuleState {
    showLoading
    selectedModule: Course
    modules: Course[]
}

export class TrainingModules extends React.Component<any, ModuleState> {

    constructor(props) {
        super(props);
        this.state = {modules: []} as ModuleState;
    }

    componentDidMount() {
        this.queryModules(1);
    }

    public queryModules(page) {
        this.setState({showLoading: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/training/allmodules"), {
                method: 'POST',
                body: JSON.stringify({page: page}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    this.setState({modules: data.modules})
                }).catch(() => {
                alert("Failed");
            }).finally(() => this.setState({showLoading: false}));
        })
    }

    onFilter() {

    }

    onSubmit(module: Course) {
        this.setState({showLoading: true}, () => {
            fetch(domain + (this.props.route ? this.props.route : "/api/training/module"), {
                method: 'POST',
                body: JSON.stringify({module: module}),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    if (data.success) {
                        alert("Saved");
                        this.queryModules(1);
                    } else {
                        alert("Failed to save.")
                    }
                }).catch(() => {
                alert("Failed");
            }).finally(() => this.setState({showLoading: false}));
        })
    }

    getFilter() {
        return (
            <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <h4>Module Filtering</h4>
                    </div>
                    <div className={"card-body"}>
                        <FilterCard
                            fields={[
                                // {key: "customers", label: "Customer", isMulti:true, type: FIELD_TYPE.SEARCH_RESULT, result_types:["bill_to", "ship_to"]},
                                // {
                                //     key: "products",
                                //     label: "Product Line",
                                //     type: FIELD_TYPE.SELECT,
                                //     isMulti:true,
                                //     options: []
                                // },
                                // {
                                //     key: "items",
                                //     label: "Item",
                                //     type: FIELD_TYPE.SELECT,
                                //     options: [],
                                //     isMulti:true,
                                //     noOpMessage: "Select a Product"
                                // },
                                // {
                                //     key: "sales_reps",
                                //     label: "Sales Rep.",
                                //     type: FIELD_TYPE.SELECT,
                                //     isMulti:true,
                                //     options: this.state.sales_reps,
                                //     isMapped: true
                                // },
                                // {key: "start_date", label: "Start Shipped Date", type: FIELD_TYPE.DATE, default:this.state.start_date},
                                // {key: "end_date", label: "End Shipped Date", type: FIELD_TYPE.DATE, default:this.state.end_date},
                                // {
                                //     key: "countries",
                                //     label: "Country",
                                //     isMulti:true,
                                //     type: FIELD_TYPE.SELECT,
                                //     options: []
                                // },
                                // {
                                //     key: "states",
                                //     label: "State",
                                //     type: FIELD_TYPE.SELECT,
                                //     options: [],
                                //     isMapped: true,
                                //     isMulti:true,
                                //     noOpMessage: "Select a Country"
                                // },{
                                //     key: "lot",
                                //     label: "Lot #",
                                //     type: FIELD_TYPE.TEXT,
                                // },{
                                //     key: "co_num",
                                //     label: "Order Number",
                                //     type: FIELD_TYPE.TEXT,
                                // }
                            ]}
                            filterChanged={(filter) => {
                                console.log("Filter changed", filter)
                                // @ts-ignore
                                this.setState({filter: filter})
                            }}
                        />
                        {/*<div className="form-group row">*/}
                        {/*    <label className="col-sm-4 col-form-label">Shortcuts</label>*/}
                        {/*    <div className="col-sm-8 p-0 m-0">*/}
                        {/*        <Select*/}
                        {/*            isSearchable={false}*/}
                        {/*            placeholder={"None"}*/}
                        {/*            noOptionsMessage={()=> "Failed to load."}*/}
                        {/*            isMulti={false}*/}
                        {/*            onChange={(e)=> null}*/}
                        {/*            className={"state_select"}*/}
                        {/*            options={this.state.fyOptions}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                    <div className="card-footer green-border-top">
                        <button className={"btn btn-outline-info"} onClick={(e) => this.onFilter()}>Apply
                        </button>
                        <button className={"btn btn-outline-danger float-right"}
                                onClick={() => window.location.reload()}>Clear
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (<React.Fragment>
            <Overlay showLoading={this.state.showLoading}/>
            <TrainingModuleModal onSubmit={(e) => this.onSubmit(e)} module={this.state.selectedModule}/>
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                <div className={"row"}>
                    {/*{this.getFilter()}*/}
                    <div className={"col-12  m-0 tableFixHead"}>
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <div className="row">
                                    <div className={"col-2"}>
                                        <h4 className="d-inline-block">Training Modules</h4>
                                    </div>
                                    <div className={"col-2"}>
                                        <button className={"btn btn-outline-primary"} onClick={() =>
                                            this.setState({selectedModule: {} as Course}, () => {
                                                TrainingModuleModal.display()
                                            })
                                        }>Create Module
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                <SimpleTable columns={[
                                    {label: "Course #", key: "course_num"},
                                    {label: "Description", key: "description"},
                                    // {
                                    //     label: "", key: "", rawFormat: (val) => <React.Fragment>
                                    //         <button className={"btn btn-outline-primary"}
                                    //                 onClick={() => this.setState({selectedModule: val}, () => TrainingModuleModal.display())}>Edit
                                    //         </button>
                                    //     </React.Fragment>
                                    // }
                                ]} table_data={this.state.modules}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>);
    }
}