import React from "react";
import Table from "../tables/Table";
import {ModalBodyProps} from "./Modals";
import {formatAddress, formatMoney} from "../../util/FormatUtil";
import ComplaintModal from "./ComplaintModal";

export default class ShipToModal extends React.Component<ModalBodyProps, {data}>{

    constructor(props) {
        super(props);
        this.state ={data:{}}
    }


    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let data = this.props.data;
        data['bill_to'].type = "bill_to"
        console.log(data)
        return (
            <React.Fragment>
                <ComplaintModal data={this.state.data}/>
            <Table
                isHorizontal={true}
                columns={[
                    {label: "Customer Number", key: "cust_num"},
                    {label: "Customer Seq.", key: "cust_seq"},
                    {label: "Address", raw: formatAddress(data)},
                    {label: "Bill To", key: "bill_to.name",  modal_data: data.bill_to},
                    {label: "Corporate Customer", key: "corp_cust",  modal_data: data.corp_cust},
                ]}
                load_modal={this.props.load_modal}
                modal_cb={this.props.modal_cb}
                table_data={data}
                isMap={true}
                table_style={"table-striped text-left m-0 p-0"}
                header_style={"fw-700"}
                append={
                    <React.Fragment>
                        <th>Contacts</th>
                        <td className={"p-0 m-0"}>
                            <Table
                                columns={[
                                    {label: "Name", key: "contact.name", modal_key:"contact"},
                                    {label: "Title", key: "contact.job_title"},
                                    {label: "Email", key: "contact.email"},
                                ]}
                                load_modal={this.props.load_modal}
                                modal_cb={this.props.modal_cb}
                                table_data={data.contacts}
                                table_style={"table-striped text-left m-0 p-0 no-border"}
                                header_style={"fw-700"}
                            />
                        </td>
                        <tr>
                            <th scope="row">Complaints</th>
                            <td className={"p-0 m-0"}>
                                <Table
                                    columns={[
                                        {label: "CC Num", key: "ccr_num", isOwnModalData:true, modal_key:"ccr_num"},
                                        {label: "Product Line", key: "product_line"},
                                        {label: "Originator", key: "sales.name"},
                                    ]}
                                    load_modal={(data) =>{this.setState({data:data}, () =>ComplaintModal.display())}}
                                    modal_cb={this.props.modal_cb}
                                    table_data={data.complaints}
                                    table_style={"table-striped text-left m-0 p-0 no-border"}
                                    header_style={"fw-700"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Interactions</th>
                            <td className={"p-0 m-0"}>
                                <Table
                                    columns={[
                                        {label: "interaction_id", key: "interaction_id"},
                                    ]}
                                    load_modal={(data) =>{this.setState({data:data})}}
                                    modal_cb={this.props.load_modal}
                                    table_data={data.interactions}
                                    table_style={"table-striped text-left m-0 p-0 no-border"}
                                    header_style={"fw-700"}
                                />
                            </td>
                        </tr>
                    </React.Fragment>
                }
            />
            </React.Fragment>
        )
    }
}