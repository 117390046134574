import React from 'react';
import {formatMoney, parseDate} from "../../util/FormatUtil";
interface TableProps{
    table_data:any
    isHorizontal?
    columns
    load_modal?
    modal_cb?
    header_style?
    table_style?
    isMap?
    append?
}

interface TableState{

}

export default class Table extends React.Component<TableProps, TableState> {
    constructor(props) {
        super(props)
        this.getModalLink = this.getModalLink.bind(this);
    }

    getValue(values, key) {
        if(!key){return ""}
        let keys = key.split('.');
        let val = values;
        keys.forEach(function (k, index) {
            val = val ? val[keys[index]] : ""
        });
        return val;
    }

    getTableBody() {
        let isHorizontal = this.props.isHorizontal;
        return (this.props.table_data ? this.props.table_data.map((val, index) => {
            return (<tr>{
                    this.props.columns.map((col) => {
                        let disString = this.buildDisplayString(col, val)
                        disString = (col.modal_key || col.modal_data) ? (this.getModalLink(disString, col.isOwnModalData ? val : val[col.modal_key])) : disString;

                        if (isHorizontal ) {
                            return (<React.Fragment>
                                <td className={col.content_class}>{col.label}</td>
                                <td className={col.content_class}>{disString}</td>
                            </React.Fragment>)
                        }else{
                            let row = <td className={col.content_class}>{disString}</td>;
                            return (col.valueClick ? this.wrapValClick(row, col, val) : row)
                        }
                    })
                }</tr>
            )
        }) : null);
    }

    wrapValClick(html,col, val){
        return <a href={"#!"} onClick={() => col.valueClick(val)}> {html}</a>
    }
    
    buildDisplayString(col, obj){
        let dis_string = col.raw ? col.raw : this.getValue(obj, col.key)
        return col.formatFunc ? col.formatFunc(dis_string, obj) : dis_string
    }

    getModalLink(display_string, modal_data) {
        return (<a href="#!" onClick={() => this.props.load_modal(modal_data, null, this.props.modal_cb)}>{display_string}</a>)
    }

    buildList(col) {
        let data = this.props.table_data[col.list_key];

        if (this.props.isHorizontal) {
            return (
                <tr>
                    <th scope="row">{col.label}</th>
                    <td>{data ? (data.map((obj) => {
                            let dis_string = this.buildDisplayString(col, obj)
                            return <React.Fragment>{col.modal_key ? this.getModalLink(dis_string, obj[col.modal_key]) : dis_string} <br/></React.Fragment>
                        }
                    )) : ""}
                    </td><br/>
                </tr>
            )
        }
    }

    getMappedBody() {
        let isHorizontal = this.props.isHorizontal;
        let getModalLink = this.getModalLink;


        return (this.props.columns.map((col, index) => {
            if (isHorizontal) {
                if (col.isList) {
                    return this.buildList(col)
                }
                let disString = this.buildDisplayString(col, this.props.table_data);

                disString = (col.modal_key || col.modal_data) ? (this.getModalLink(disString, this.getValue(this.props.table_data, col.modal_key))) : disString;

                if(col.isConditional && disString === "")
                    return null;

                return (<React.Fragment>

                    <tr className={col.isBreakPoint ? "bordered" : null}>
                        <td className={this.props.header_style}>{col.label}</td>
                        <td className={col.content_class}>{col.modal_data ? getModalLink(disString, col.modal_data) : disString}</td>
                        {col.extraCol ? col.extraCol.map((val, index)=>{
                            return(
                                <td className={val.content_class}>{this.buildDisplayString(val, this.props.table_data)}</td>
                            )
                        }) : null}
                    </tr>
                </React.Fragment>)
            }

        }));
    }

    render() {
        let isHorizontal = this.props.isHorizontal;
        let isMap = this.props.isMap;

        return (
            <table
                className={"table " + (this.props.table_style ? this.props.table_style : "table-dark table-striped p-0 m-0")}>
                {!isHorizontal ? (
                    <thead>
                    <tr>
                        {this.props.columns.map((col, index) => {
                            return (<th scope="col" onClick={col.onClick ? col.onClick : null}>{col.label}</th>)
                        })}
                    </tr>
                    </thead>) : null
                }
                <tbody>
                {!isMap ? this.getTableBody() : this.getMappedBody()}
                {this.props.append ? this.props.append : null}
                </tbody>
            </table>
        )
    }
}