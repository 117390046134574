import React from "react";
import domain from "../../Domain";
import ModalForm from "./ModalForm";
import {FIELD_TYPE} from "../FilterCard";
import {OppTaskState} from "./OppTaskFormModal";
import {getSelectObjFromVal} from "../../util/FormatUtil";


interface EditOppTaskProps{
    ID:string
    data:any
    callback?:() =>void
}

export default class EditOppTaskFormModal extends React.Component<EditOppTaskProps, OppTaskState>{

    constructor(props) {
        super(props);
        this.state = {ID: "", task_types: []};
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        fetch(domain + "/api/opportunities/tasks/info").then(res => res.json()).then(data => this.setState({
            task_types:data.statuses,
        })).catch(e => console.log("Failed to fetch opp info"));
    }

    onSubmit(opp){
        console.log(this.props.data)
        let data = JSON.parse(JSON.stringify(this.props.data));
        opp.opp_task_num = data.opp_task_num;
        opp.opp_task_type =  opp.opp_task_type ? opp.opp_task_type.value : null
        if(!opp.status) {
            alert("Must have a status selected");
            return
        }
        opp.status = opp.status ? opp.status[0] : null
        let self = this;
        fetch(domain + "/api/opportunities/tasks/edit", {
            method: 'POST',
            body: JSON.stringify(opp),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>{
            if(response.status === 200){
                let id = this.props.ID;
                alert("Success");
                // @ts-ignore
                window.$("#" + id).modal('hide');
                if(this.props.callback)
                    this.props.callback();
            }else{
                alert("Failed to edit task.")
            }
        })
    }

    render() {
        let status_options = [{label:"Open", value:"O"}, {label:"Closed", value:"C"}]
        let task_types = [{label:"Email" , value:"E"}, {label:"Phone", value:"P"}, {label:"Site Visit", value:"S"}]
        let data = this.props.data ? this.props.data : {};
        console.log(this.props.data)
        return (
            <ModalForm
                key={this.props.data ? this.props.data.name : "contactForm"}
                fields={[
                    {key: "description", label: "Description", type: FIELD_TYPE.TEXT, default:this.props.data ? this.props.data.description : ""},
                    {key: "opp_task_type", label: "Task Type",  type: FIELD_TYPE.SELECT, isMapped:true, default:this.props.data ? getSelectObjFromVal(data['opp_task_type'], task_types) : null, options:task_types},
                    {key: "status", label: "Status",  type: FIELD_TYPE.SELECT, isMapped:true, options:status_options, default:this.props.data  ? getSelectObjFromVal(this.props.data.status, status_options) : null},
                    {key: "due_date", label: "Due Date",  type: FIELD_TYPE.DATE, default:data["due_date"] ? data["due_date"].slice(0,10) : null},
                    {key: "complete_date", label: "Complete Date",  type: FIELD_TYPE.DATE, default:data["complete_date"] ? data["complete_date"].slice(0,10) : null},
                ]}
                ID={this.props.ID}
                title={"Opportunity Task Edit"}
                onSubmit={this.onSubmit}
            />)
    }
}