import React from "react";
import domain from "../../Domain";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import {parseDate} from "../../util/FormatUtil";
import Select from "react-select";

interface VideoState {
    showLoading: boolean

    filter: {
        lot: string
    }
    item?
}

interface VideoProps {

}

export class VideoJet extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            item: {},
            lot: "",
            lotArray: [],
            jobError: '',
            job: '',
            itemArray: [],
            itemChosen: '',
            expDate: ''

        }
    }

    componentDidMount() {

        /*        fetch(domain + (this.props.route ? this.props.route : "/api/wiplabel/lot")).then(res => res.json())
                    .then(data => {
                        const options = data.map(d => ({
                            "value": d.lot,
                            "label": d.lot
                        }))
                        if(options != null){
                            this.setState({lotArray: options})
                        }
                    });*/
    }

    private sendJob() {
        if (!this.state.item || !this.state.item.lot) {
            return alert("No job selected.");
        }
        fetch(domain + (this.props.route ? this.props.route : "/api/label/job"), {
            method: 'POST',
            body: JSON.stringify({lot: this.state.lot, item: this.state.itemChosen, expDate: this.state.expDate}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(data => {
                alert("Saved");

            });
    }

    private lookupJob() {

        let regEx = /^\d{4}-\d{2}-\d{2}$/;
        let d = new Date(this.state.expDate);
        let dNum = d.getTime();
        if (!this.state.expDate.match(regEx)) {
            alert("Enter date in YYYY-MM-DD")
        } else if (!dNum && dNum !== 0) {
            alert("Invalid Date")
        } else {
            this.setState({expDate: d.toISOString().slice(0, 10)}, () => {
                console.log(this.state.expDate);
                fetch(domain + (this.props.route ? this.props.route : "/api/label/lookup"), {
                    method: 'POST',
                    body: JSON.stringify({lot: this.state.lot, item: this.state.itemChosen, expDate: this.state.expDate}),
                    headers: {'Content-Type': 'application/json'}
                }).then(res => res.json())
                    .then(data => {
                        console.log(data);
                        this.setState({item: data})
                    });
            })
        }

        console.log(this.state.lot)
    }

    handleExpChange = event => {
        this.setState({expDate: event.target.value})
    };


    handleJobChange = event => {

        this.setState({job: event.target.value}, () => {
            //event.preventDefault();
            this.validateJob();

        });

    };
    validateJob = () => {
        const {job} = this.state;
        //alert(job);
        this.setState({
            jobError:
                job.length > 1 ? null : 'Name must be longer than 1 characters'
        });

        fetch(domain + (this.props.route ? this.props.route : "/api/wiplabel"), {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({job: this.state.job})
        })
            .then(res => res.json())
            .then(data => {
                const options = data.map(d => ({
                    "value": d.lot,
                    "label": d.lot
                }))
                console.log(options);
                if (options != null) {
                    this.setState({lotArray: options});
                }
            }).catch(e => alert("Please input a job number!"));

        fetch(domain + (this.props.route ? this.props.route : "/api/wiplabel/item"), {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({job: this.state.job})
        })
            .then(res => res.json())
            .then(data => {
                const optionsItem = data.map(d => ({
                    "value": d.item,
                    "label": d.item
                }))
                console.log(optionsItem);
                if (optionsItem != null) {
                    this.setState({itemArray: optionsItem});
                }
            })


    }

    handleChange(event) {
        this.setState({lot: event.label}, () => {
            console.log("LOT: ", this.state.lot)
        });

    }

    handleItemChange(event) {
        this.setState({itemChosen: event.label}, () => {
            console.log("ITEM: ", this.state.itemChosen)
        });

    }

    render() {
        const styles = {
            container: base => ({
                ...base,
                flex: 1
            })
        };
        return (<React.Fragment>
            <Overlay showLoading={this.state.showLoading}/>
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-5">
                <div className={"row"}>
                    <div className="col-xl-6 col-lg-6 col-md-6  col-12">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Lot Lookup</h4>
                            </div>
                            <div className={"card-body"}>
                                <input
                                    name='job'
                                    className={`form-control ${this.state.jobError ? 'is-invalid' : ''}`}
                                    id='job'
                                    placeholder='Enter Job#'
                                    style={{
                                        width: "100%"
                                    }}
                                    value={this.state.job}
                                    onChange={this.handleJobChange}

                                />
                                <br/>
                                Lot
                                <Select
                                    placeholder={'Choose a Lot'}
                                    noOptionsMessage={() => "Failed to Load"}
                                    options={this.state.lotArray}
                                    value={{
                                        label: this.state.lot
                                    }}

                                    className={"state_select"}
                                    onChange={this.handleChange.bind(this)}

                                />
                                <br/>
                                Item
                                <Select
                                    placeholder={'Choose an Item'}
                                    noOptionsMessage={() => "Failed to Load"}
                                    options={this.state.itemArray}
                                    value={{
                                        label: this.state.itemChosen
                                    }}

                                    className={"state_select"}
                                    onChange={this.handleItemChange.bind(this)}

                                />
                                <br/>
                                Exp Date
                                <input
                                    name='exp'
                                    className={`form-control ${this.state.ExpError ? 'is-invalid' : ''}`}
                                    id='exp'
                                    placeholder='Exp Date (YYYY-DD-MM)'
                                    style={{
                                        width: "100%"
                                    }}
                                    value={this.state.expDate}
                                    onChange={this.handleExpChange}

                                />
                                <br/>

                            </div>
                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={(e) => this.lookupJob()}>
                                    Lookup
                                </button>

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6  col-12">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Job Info</h4>
                            </div>
                            <div className={"card-body"}>
                                <p>Item: {this.state.item.item}</p>
                                <p>Lot: {this.state.item.lot}</p>
                                <p>Exp. Date: {parseDate(this.state.item.exp_date)}</p>
                                <p>Template: {this.state.item.template}</p>
                            </div>
                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={(e) => this.sendJob()}>Send Job
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    }
}