import React from "react";
import {buildDisplayString, getModalLink, getValue} from "./TableUtil";
import {Column} from "./TableBase";

interface HorizontalTableState{

}


interface HorizontalTableProps{
    columns:Column[]
    table_style?:string
    header_style?:string
    table_data:[] | {}
    append?:any
}


export class HorizontalTable extends React.Component<HorizontalTableProps, HorizontalTableState>{

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <table className={"table " + (this.props.table_style ? this.props.table_style : "table-dark table-striped p-0 m-0")}>

                    <tbody>
                    {this.getBody()}
                    {this.props.append ? this.props.append : null}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    private getBody() {

        return (this.props.columns.map((col:Column) => {
            let disString = buildDisplayString(col, this.props.table_data, this.props);
            disString = (col.modal_key || col.modal_data) ? (getModalLink(disString, getValue(this.props.table_data, col.modal_key), this.props)) : disString;
            return (<React.Fragment>

                <tr className={col.isBreakPoint ? "bordered" : null}>
                    <td className={this.props.header_style}>{col.label}</td>
                    <td className={col.content_class}>{col.modal_data ? getModalLink(disString, col.modal_data, this.props) : disString}</td>
                    {col.extraCol ? col.extraCol.map((val, index)=>{
                        return(
                            <td className={val.content_class}>{buildDisplayString(val, this.props.table_data, this.props)}</td>
                        )
                    }) : null}
                </tr>
            </React.Fragment>)

        }));
    }
}