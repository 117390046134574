import React from "react";
import {DefaultState} from "../App";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import Table from "../tables/Table";
import {formatMoney, formatUSDate, getPageOptions, parseDate, trim} from "../../util/FormatUtil";
import domain from "../../Domain";
interface VoucherState extends DefaultState{
    filter:any
    selected_page:string
    table_data:any[];
    page_options:any[];
    unique_desc:string[];
}

class Vouchers extends React.Component<any, VoucherState> {
    private pageRef: React.RefObject<unknown>;
    constructor(props) {
        super(props);
        this.state = {
            table_data: [], filter:{}, page_options: getPageOptions(1), selected_page:"1",unique_desc:[]

        }
        this.onExport = this.onExport.bind(this)
    }

    componentDidMount(): void {
        this.queryVouchers(1)
    }

    private queryVouchers(page: number) {
        let self = this;
        this.setState({show_loading: true}, ()=> {
            fetch(domain + (this.props.route ? this.props.route : "/api/vouchers?page=" + page), {
                method: 'POST',
                body: JSON.stringify(this.state.filter),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json())
                .then(data => {
                    console.log(data)
                    if (data.table_data) {
                        self.setState({
                            table_data: data.table_data,
                            page_options: getPageOptions(data.num_pages),
                            unique_desc:data.unique_desc
                        })
                    } else if (!data.authenticated && data.redirect) {
                        alert("Please sign in")
                        window.location.replace('/api/microsoft/signin')
                    } else if (!data.authenticated && !data.redirect) {
                        alert("You do not have permission to view this.")
                    }
                    self.setState({show_loading: false})
                });

        });
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            <Overlay showLoading={this.state.show_loading}/>
            <div className="container-fluid secondary-slate-bg min-vh-100 white  pt-1 ">
                <div className={"row"}>
                    <div className="col-xl-4 col-lg-6 col-md-6  col-12">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">
                                <h4>Voucher Filtering</h4>
                            </div>
                            <div className={"card-body"}>
                                <FilterCard

                                    fields={[
                                         {key: "voucher", label: "Voucher", type: FIELD_TYPE.TEXT},
                                        {key: "invoice", label: "Invoice", type: FIELD_TYPE.TEXT},
                                        {key: "amount", label: "Amount", type: FIELD_TYPE.TEXT},
                                        {key: "acct_desc", label: "Account Desc.", type: FIELD_TYPE.SELECT, options:this.state.unique_desc, isMapped:false, isMulti:true},
                                        {key: "bank_code", label: "Bank Code", type: FIELD_TYPE.TEXT},
                                        {key: "reference", label: "Reference", type: FIELD_TYPE.TEXT},
                                        {key: "trans_start_date", label: "Start Trans. Date", type: FIELD_TYPE.DATE},
                                        {key: "trans_end_date", label: "End Trans. Date", type: FIELD_TYPE.DATE},
                                        {key: "acctunit1", label: "Acct. Unit 1", type: FIELD_TYPE.TEXT},
                                        {key: "acctunit2", label: "Acct. Unit 2", type: FIELD_TYPE.TEXT},
                                        {key: "acctunit3", label: "Acct. Unit 3", type: FIELD_TYPE.TEXT},
                                        {key: "acctunit4", label: "Acct. Unit 4", type: FIELD_TYPE.TEXT},
                                        // {key: "last_name", label: "Last Name", type: FIELD_TYPE.TEXT},

                                    ]}
                                    filterChanged={(filter) => {
                                        // console.log("Filter changed", filter)
                                        this.setState({filter: filter})
                                    }
                                    }
                                />
                            </div>
                            <div className="card-footer green-border-top">
                                <button className={"btn btn-outline-info"} onClick={(e) =>this.queryVouchers(1)}>Apply
                                </button>
                                <button className={"btn btn-outline-danger float-right"}
                                        onClick={() => window.location.reload()}>Clear
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 pb-2 tableFixHead pt-2">
                        <div className={"card shadow-lg primary-black-bg white"}>
                            <div className="card-header green-border ">

                                <h4 className="d-inline-block">Vouchers</h4>
                                <button className={"btn btn-outline-info ml-3 d-inline-block"}
                                        onClick={this.onExport}>Export to Excel
                                </button>
                                <div className=" align-middle float-right pages">
                                    <Select
                                        isSearchable={true}
                                        placeholder={"1"}
                                        noOptionsMessage={() => "Failed to Load"}
                                        // inputValue={this.state.selected_page}
                                        // ref={this.pageRef}
                                        onChange={(e) =>{
                                            this.setState({selected_page:e.value},()=>
                                                this.queryVouchers(e.value))
                                        }}
                                        className={"state_select"}
                                        options={this.state.page_options}
                                    />
                                </div>

                            </div>
                            <div className={"card-body table-responsive-lg  p-0 m-0"}>
                                <Table columns={[
                                    {label: "Voucher", key: "Voucher", formatFunc: trim},
                                    {label: "Invoice", key: "Invoice",},
                                    {label: "Name", key: "Name"},
                                    {label: "Trans Date", key: "Trans Date", formatFunc:(str, obj) =>{
                                        return parseDate(str)}},
                                    {label: "Reference", key: "Reference",},
                                    {label: "Amount", key: "Amount", formatFunc: formatMoney},
                                    {label: "Account Description", key: "Account Description"},
                                    {label: "Bank Code", key: "Bank Code"},
                                    {label: "Account Unit 1", key: "Account Unit 1"},
                                    {label: "Account Unit 2", key: "Account Unit 2"},
                                    {label: "Account Unit 3", key: "Account Unit 3"},
                                    {label: "Account Unit 4", key: "Account Unit 4"},
                                ]}
                                       table_data={this.state.table_data ? this.state.table_data : []}
                                       load_modal={this.props.open_modal}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>);
    }

    private onExport() {
        let xhttp = new XMLHttpRequest();
        this.setState({show_loading: true}, function () {
            xhttp.onreadystatechange = () => {
                let a;
                if (xhttp.readyState === 4) {
                    this.setState({show_loading: false});
                }
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // Trick for making downloadable link
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    // Give filename you wish to download
                    a.download = "vouchers.xlsx";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                }

            };
            // Post data to URL which handles post request
            xhttp.open("POST", domain + "/api/vouchers/export");
            xhttp.setRequestHeader("Content-Type", "application/json");
            // You should set responseType as blob for binary responses
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(this.state.filter));
        })
    }
}
export default Vouchers;