import React from "react";
import Select from "react-select";
import {parseDate} from "../../util/FormatUtil";
import moment from "moment";
import domain from "../../Domain";
import {EquipmentRecord} from "../../types/EquipmentRecord";
import {TrainingModules} from "../immytrack/TrainingModules";
import {Course, TrainingType} from "../../types/TrainingTypes";
import {Training} from "../immytrack/Training";
import {Employee} from "../../types/Employee";

interface EditProps{
    onSubmit
    training:TrainingType
    employees:{ label:string, value:string} []
    modules:Course[]
}

export class TrainingModal extends React.Component<EditProps, any>{
    constructor(props) {
        super(props);
        this.state = {}
        this.getAdjustedField = this.getAdjustedField.bind(this);
    }

    getTrueFalseWrap(value){
        return {label:value ? "True" : "False", value: !!value}
    }

    getSelectWrap(value){
        return value ? {label:value, value:value} : null;
    }




    componentWillReceiveProps(nextProps: Readonly<EditProps>, nextContext: any) {
        let keys = Object.keys(this.state);
        let obj = JSON.parse(JSON.stringify(this.state));
        keys.forEach(k => obj[k] = '');
        console.log(nextProps)
        let equipRecord:TrainingType = nextProps.training ? JSON.parse(JSON.stringify(nextProps.training)) : {};

        this.setState(obj, () => this.setState(equipRecord));


    }

    getReadOnly(key:string, display:string){
        return this.getAdjustedField(key,display,true)
    }

    getMappedLots(arr){
        return arr.map(a => ({label:a.Lot, value:a.Lot}));
    }

    getStateObj(key, val){
        let obj = {};
        obj[key] = val;
        return obj;
    }

    getField(key:string, display:string){
        return this.getAdjustedField(key,display,false);
    }

    getSelectField(key:string, label:string, options, isMulti){
        return(
            <div className="form-group row">
                <label className={"col-sm-2 col-form-label"}>{label}</label>
                <div className={"col-sm-8 p-0 m-0 "}>

                    <Select
                        onChange={(e)=> {
                            this.setState(this.getStateObj(key, e))}}
                        className={"state_select"}
                        isMulti={isMulti}
                        value={this.state[key]}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    getTextArea(key, display){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <textarea cols={3} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }
    getAdjustedField(key, display, readOnly){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <input type={"search"} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       readOnly={readOnly}
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }

    submitForm(){
        if(!this.state.courses || !this.state.employees || !this.state.start_date){
            return alert("Please complete Employees, Modules, and Start Date")
        }
        let obj = JSON.parse(JSON.stringify(this.state));
        obj.employees = obj.employees.map(e => e.value);
        obj.courses = obj.courses.map(e => e.value);
        obj.instructor = obj.instructor ? obj.instructor.value : null;
        console.log(this.state);
        TrainingModal.hide();
        this.props.onSubmit(JSON.parse(JSON.stringify(obj)));
    }

    buildDate(key, display){
        return(<div className="form-group row">
            <label className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type="date" name="start" max="3000-12-31"
                       value={this.state[key]}
                       min="1000-01-01" className="form-control datepicker" onChange={(e)=>  this.setState(this.getStateObj(key, e.target.value))}/>
            </div></div>)
    }


    public static display() {
        window["$"]("#" + TrainingModal.ID).modal('show');
        console.log("display")
    }
    public static hide() {
        window["$"]("#" + TrainingModal.ID).modal('hide');
    }
    public static readonly ID = "trainingCreate"

    render() {
        let mods = this.props.modules.map(m =>{
            return ({
                label:m.description + " - " + m.course_num,
                value: m.course_num
            })
        })
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={TrainingModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">{"Training Module"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                {this.getSelectField("employees", "Employees", this.props.employees, true)}
                                {this.getSelectField("courses", "Courses", mods, true)}
                                {this.getSelectField("instructor", "Instructor", this.props.employees, false)}
                                {this.buildDate("start_date", "Start")}
                                {this.buildDate("end_date", "Completed")}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={(e) =>
                                    this.submitForm()}>
                                    Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}