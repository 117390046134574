import * as React from "react";
import domain from "../../Domain"
import ContactForm from "./ContactFormModal";
import OppForm from "./OppForm"
import ComplaintModal from "./ComplaintModal";
import ExpenseModal from "./ExpenseModal";
import PasswordModal from "./PasswordModal";
import SampleModal from "./SampleModal";
import OpportunityModal from "./OpportunityModal";
import BackOrderModal from "./BackOrderModal";
import OrderModal from "./OrderModal";
import BillToModal from "./BillToModal";
import ShipToModal from "./ShipToModal";
import ContactModal from "./ContactModal";

export interface ModalBodyProps{
    load_modal
    modal_cb
    data:any
}




class Modal_Header extends React.Component<{data:any, type, title}, {}> {
    buildModalButton(modal_id, name){
        return(<button type="button" className={"btn btn-outline-primary float-right"} onClick={(e) =>{
            window['$']("#" + modal_id).modal('show')
        }}>
            {name}
        </button>)
    }

    getHeader(type){
        switch (type) {
            case "bill_to":
            case "ship_to":
                return(<React.Fragment>
                    <button type="button" className={"btn btn-outline-primary float-right"} onClick={(e) =>{

                   ContactForm.display();
                }}>
                    Add Contact
                </button>
                        <a href={"/customer?custnum=" + this.props.data.cust_num + "&custseq=" + this.props.data.cust_seq}><button  type="button" className={"btn btn-outline-primary float-right"}>Stats</button></a>
                </React.Fragment>
                );
            case "contact":
                return(this.buildModalButton("contact_form", "Edit Contact"))
            default:
                return null;
        }
    }

    render() {
        let type = this.props.type;
        return (
            <div className="modal-header">
                <div className={"col-6"}>
                    <h5 className="modal-title" id="result_modal_label">{this.props.title}</h5>
                </div>
                <div className={"col-5"}>
                    {this.getHeader(type)}
                </div>
                <div className={"col-1"}>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        )
    }
}


interface ModalState{
    data?:any
    selected_data?:any
}

interface ModalProps{
    load_result_from_result?,
    selected_data,
    id?,
    queryFromModal?
}

export default class Result_Modal extends React.Component<ModalProps, ModalState> {
    constructor(props) {
        super(props);
        if(!this.state){
            this.state = {}
        }
        this.callbackState = this.callbackState.bind(this)
        this.queryFromModal = this.queryFromModal.bind(this)
    }

    queryFromModal(data, type?, cb?) {
        let self = this;
        console.log(data);

        if((typeof data === "string"))
            return;
        if(!data.type)
            data.type = type;
        let main_order = null
        if(data.type === "order")
            main_order = data.main_order ? data.main_order : data;
        fetch(domain + "/api/global/cache", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': "application/json"
            }
        }).then(res => res.json())
            .then(data => {
                data.main_order = main_order;
                cb ? cb(data) : self.setState({data:data})
            })
            .catch(console.log)
    }

    callbackState(state){
        this.setState({data:state});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({data:nextProps.selected_data})
    }

    render() {
        let data = !this.state.data ? this.props.selected_data :  this.state.data;
        let body = <p>Missing Data</p>;
        let title = "Missing Data";
        if(this.state.data)
            for(let i = 1; i < 5; i++){
                data['addr##' + i] = data['addr##' + i] ? data['addr##' + i] : data['addr'+i];
            }
        if (data) {
            if (data.type === "contact") body = <ContactModal load_modal={this.queryFromModal}
                                                             modal_cb={this.props.load_result_from_result}
                                                             data={data}/>;
            if (data.type === "ship_to") body = <ShipToModal load_modal={this.queryFromModal}
                                                             modal_cb={this.props.load_result_from_result}
                                                             data={data}/>;
            if (data.type === "bill_to") body =<BillToModal load_modal={this.queryFromModal}
                                                           modal_cb={this.props.load_result_from_result}
                                                           data={data}/>;
            if (data.type === "order") body = <OrderModal load_modal={this.queryFromModal}
                                                                modal_cb={this.props.load_result_from_result}
                                                                data={data}/>;
            if (data.type === "opportunity") body = <OpportunityModal load_modal={this.queryFromModal}
                                                                    modal_cb={this.props.load_result_from_result}
                                                                    data={data}/>;
            if (data.type === "back_order") body = <BackOrderModal  load_modal={this.queryFromModal}
                                                                    modal_cb={this.props.load_result_from_result}
                                                                    data={data}/>

            if(data.type === "sample") body = <SampleModal data={data}/>;
            if(data.type === "complaint") body = <ComplaintModal data={data}/>;
            console.log(data.type)

            if(data.type === "expense") body = <ExpenseModal data={data}/>;
            title = data.name
        }

        return (
            <React.Fragment>
            <div className="modal fade" id={this.props.id ? this.props.id : "result_modal"} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <Modal_Header title={title} data={data} type={data ? data.type : null}/>

                        <div className="modal-body m-0 p-0">
                            <div className={"row m-0 p-0"}>
                                <div className={"col-12 m-0 p-0"}>
                                    {body}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
                <ContactForm
                    key={this.state.selected_data}
                    ID={"contact_form"} customer_data={this.state.data}
                    completeCallback={this.callbackState}
                />
                <PasswordModal load_modal={this.props.queryFromModal}
                               modal_cb={this.props.load_result_from_result} data={null} />
            </React.Fragment>
        )
    }
}