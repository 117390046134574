import * as React from "react";
import Select from "react-select";
import domain from "../../Domain";

interface QSState{
    searchVal?
    results?
    selected?
    options?
    showDropdown?
}
interface QSProps{
    route?
    resultTypes?
    isMulti?
    default?
    onChange
}
class QuerySearch extends React.Component<QSProps, QSState> {
    private typingTimer: any;
    constructor(props: Readonly<QSProps>) {
        super(props);
        this.state = {
            results: [],
            options: [],
            selected:[],
            
        };
        this.onInputChange = this.onInputChange.bind(this)
    }

    onInputChange(e) {
        this.setState({searchVal: e,}, () =>{
            if (e !== "") {
                this.typingTimer = setTimeout(() => {
                    fetch(domain + (this.props.route ? this.props.route : "/api/global/search"), {
                        method: 'POST',
                        body: JSON.stringify({term: e, result_types: this.props.resultTypes ? this.props.resultTypes : []}),
                        headers: {'Content-Type': 'application/json'}
                    }).then(res => res.json())
                        .then(data => {
                            if (e === this.state.searchVal)
                                console.log("OPTIONS:", data)
                                this.setState({
                                    options: data.map(d => {
                                        return {label: d.name, value: d}
                                    }),
                                    showDropdown: true,
                                    searchVal: e
                                })
                        });
                }, 0)

            }
        });
    }

    render() {

        let data = {};
        return (
            <div className={"search_wrapper width-inherit " + (this.state.showDropdown ? 'show' : '')}>
                <Select
                    //isSearchable={true}
                    placeholder={"All"}
                    noOptionsMessage={() => data['noOpMessage'] ? data['noOpMessage'] : "Begin typing :)"}
                    isMulti={this.props.isMulti}
                    onChange={(e)=>{
                        this.setState({selected:e}, ()=>{
                            console.log("change", e)
                            if(this.props.onChange) this.props.onChange(e)
                        })}}
                    defaultValue={this.props.default ? {label:this.props.default ? this.props.default.name : "", value:this.props.default ? this.props.default : {}} : null}

                    onInputChange={this.onInputChange}
                    className={"state_select"}
                    options={this.state.options}
                    formatOptionLabel={(e) => {
                        return(<React.Fragment key={e.value ? e.value.name : ""}>
                            <div className="row p-0 m-0">
                                <div className="col-12 p-0 m-0">
                                    <img className="p-0 m-0"
                                         src={this.getImageFromType(e.value.type)} width={25}
                                         height={25}/>
                                    <span className={'pl-2'}>{e.label}</span>
                                </div>
                            </div>
                        </React.Fragment>)
                    }}
                />
            </div>)
    }


    getImageFromType(type) {
        let types = {
            "bill_to": "/images/bill_to_colored.png",
            "ship_to": "/images/ship_to_colored.png",
            "contact": "/images/contact_colored.png",
            "opportunity": "/images/opportunity.png",
            "order": "/images/order.png",
            "back_order": "/images/open_box.png"
        };
        return types[type];
    }
}

export default QuerySearch
