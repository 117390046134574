import {ChartColor} from "chart.js";
import React from "react";
import domain from "../../Domain";
import {formatMoney, numberWithCommas, parseDate} from "../../util/FormatUtil";
import {CustPrice} from "../../types/CustPrice";
import Select from "react-select";
import {ReactSelect} from "../FilterCard";
import Table from "../tables/Table";

interface CustomerPricingProps{
    cust_num:string
}

interface CustomerPricingState{
    pricing:CustPrice[]
}
export default class CustomerPricing extends React.Component<CustomerPricingProps, CustomerPricingState>{

    constructor(props) {
        super(props);
        this.state = {pricing:[]}
    }

    componentDidMount() {
        fetch(domain+"/api/pricing/customer", {
            method: 'POST',
            body: JSON.stringify({cust_num:this.props.cust_num}),
            headers: {
                'Content-Type': 'application/json'
            }}).then(res => res.json())
            .then(data => this.setState({pricing:data}))
            .catch(console.log)
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            <div className={"col-12 pt-3 m-0 tableFixHead min-vh-100"}>
                <div className={"card shadow-lg primary-black-bg white"}>
                    <div className="card-header green-border ">
                        <h4 className="d-inline-block">Customer Pricing</h4>
                    </div>
                    <div className={"card-body table-responsive-lg  p-0 m-0"}>
                        <Table columns={[
                            {label: "Item", key: "item",},
                            {label: "Price", key: "cont_price", formatFunc: formatMoney},
                            {label: "Effective Date", key: "effect_date", formatFunc: parseDate},
                        ]}
                               table_data={this.state.pricing}
                               load_modal={null}/>
                    </div>
                </div>
            </div>

        </React.Fragment>)
    }
}