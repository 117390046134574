import React from "react";
import Table from "../tables/Table";
import {parseDate} from "../../util/FormatUtil";
import {ModalBodyProps} from "./Modals";
import {HorizontalTable} from "../tables/HorizontalTable";

export default class SampleModal extends React.Component<{data}, {}>{

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log("Rendering complaint", this.props.data)
        return (
            <HorizontalTable
             //   isHorizontal={true}
                columns={[
                    {label: "Facility", key: "customer.FacilityName"},
                    {label: "Sample ID", key: "SampleID"},
                    {label: "Patient ID", key: "PatientID"},
                    {label: "Sample Type", key: "SampleType"},
                    {label: "Collection Date", key: "CollectionDate", formatFunc:parseDate},
                    {label: "Date Received", key: "DateReceived", formatFunc:parseDate},
                    {label:"Created Date", key:"CreatedDate", formatFunc:parseDate},
                    {label:"IMMYUID", key:"IMMYUID"},
                    {label:"Result", key:"Result"},
                    {label:"Reason", key:"Reason"},
                    {label:"CT Value", key:"CTValue"},
                    {label:"Specimen UID", key:"SpecimenUID"},
                    {label:"Test Result Date", key:"TestResultDate", formatFunc:parseDate},
                    {label:"Patient Name", key:"name"},
                    {label:"Patient DOB", key:"PatientDOB", formatFunc:parseDate},
                    {label:"Physician Name", key:"PhysicianName"},
                    {label:"HospPatientID", key:"HospPatientID"},
                    {label:"Gender", key:"Gender"},

                    {label: "Test Type", key: "TestType"},
                    {label: "Invoice Num", key: "InvoiceNum"},
                    {label: "Invoice Date", key: "InvoiceDate", formatFunc:parseDate},
                    {label: "Client Acct", key: "ClientAcct"},
                    {label: "Batch Num", key: "BatchNum"},
                    {label: "Processing Date", key: "ProcessingDate", formatFunc:parseDate},
                    {label: "Price", key: "Price"},

                    {label: "Well Slot", key: "WellSlot"},
                    // {label: "Contact", isList: true, list_key: "contacts", modal_key: "contact", key: "contact.name"}
                ]}
                table_data={this.props.data}
             //   isMap={true}
                table_style={"table-striped TEXT-left m-0 p-0"}
                header_style={"fw-700"}
            />
        )
    }
}