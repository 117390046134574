import React from "react";
import domain from "../../Domain";
import {FIELD_TYPE} from "../FilterCard";
import ModalForm from "./ModalForm";
import {formatMultiselect} from "../../util/FormatUtil";
import {EditOppProps, EditOppState} from "./OppEditFormModal";



class OppForm extends React.Component<EditOppProps, EditOppState>{

    constructor(props) {
        super(props);
        this.state = {items: [], loss_reason: [], sales_team: [], statuses: [], won_reason: []};
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        fetch(domain + "/api/opportunities/info").then(res => res.json()).then(data => this.setState({
            statuses:data.statuses,
            sales_team:data.sales_team,
            won_reason:data.won_reason,
            loss_reason:data.loss_reason,
            items:data.items.map(i=>i.Product),
            })).catch(e => console.log("Failed to fetch opp info"));
    }

    onSubmit(opper){
        let opp = JSON.parse(JSON.stringify(opper))
        if(!opp.opp_status || !opp.customer || !opp.contact  || !opp.slsman){
            alert("Must have a status, customer, contact, and sales person")
            return
        }
        console.log(opp)
        opp.opp_status = opp.opp_status[0];
        opp.customer = opp.customer.value;
        opp.contact = opp.contact.value;
        opp.slsman = opp.slsman[0];
        // opp.opp_won_reason =  opp.opp_won_reason ? opp.opp_won_reason[0] : null;
        // opp.opp_lost_reason =  opp.opp_lost_reason ? opp.opp_lost_reason[0] : null;
        console.log(opp)
        // if(!filter_data){
        //     filter_data = {};
        // }
        // let data = this.props.customer_data ? this.props.customer_data : {};
        // console.log("submitting contact, " , filter_data);
        // filter_data.state = data.state ? data.state : null;
        // filter_data.country = data.country ? data.country : null;
        //
        fetch(domain + "/api/opportunities/put", {
            method: 'POST',
            body: JSON.stringify(opp),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>{
            if(response.status === 200){
                let id = this.props.ID;
                //@ts-ignore
                window.$("#" + id).modal('hide');
                alert("Successful!")
                if(this.props.callback)
                    this.props.callback();
            }else{
                alert("Failed to upload opportunity.")
            }
        })
    }

    render() {
        console.log(this.state.items)
        let statuses = this.state.statuses ? formatMultiselect(this.state.statuses, "description", "opp_status") : null;

        let sales_team_all = this.state.sales_team ? formatMultiselect(this.state.sales_team, "name", "vend_num") : null;
        let sales_team_active = this.state.sales_team ? formatMultiselect(this.state.sales_team.filter(s => s.Status !== 2), "name", "vend_num") : null;
        let won_reason = this.state.won_reason ? formatMultiselect(this.state.won_reason, "description", "reason_code") : null;
        let loss_reason = this.state.loss_reason ? formatMultiselect(this.state.loss_reason, "description", "reason_code") : null;

        return (
            <ModalForm
                key={this.props.data ? this.props.data.name : "oppForm"}
                fields={[
                    {key: "opp_status", label: "Status", type: FIELD_TYPE.SELECT, options:statuses, isMapped:true},
                    {key: "description", label: "Description", type: FIELD_TYPE.TEXT, },
                    {key: "item", label: "Item", type: FIELD_TYPE.SELECT, options:this.state.items},
                    {key: "item_qty", label: "Item Qty.", type: FIELD_TYPE.TEXT},
                    {key: "customer", label: "Customer",  type: FIELD_TYPE.SEARCH_RESULT , result_types:["bill_to", "ship_to"]},
                    {key: "contact", label: "Contact",type: FIELD_TYPE.SEARCH_RESULT,  result_types:["contact"]},
                    {key: "slsman", label: "Sales Rep.",  type: FIELD_TYPE.SELECT, options:sales_team_active, isMapped:true},
                    {key: "projected_close_date", label: "Projected Close", type: FIELD_TYPE.DATE},
                    {key: "opp_won_reason", label: "Won Reason",  type: FIELD_TYPE.SELECT, options:won_reason, isMapped:true, isMulti:false},
                    {key: "opp_lost_reason", label: "Loss Reason", type: FIELD_TYPE.SELECT, options:loss_reason, isMapped:true, isMulti:false},
                    {key: "close_date", label: "Closed Date", type: FIELD_TYPE.DATE},
                ]}
                ID={this.props.ID}
                title={"Opportunity Form"}
                onSubmit={this.onSubmit}
            />)
    }
}

export default OppForm;