import React from "react";
import Table from "../tables/Table";
import {formatMoney, numberWithCommas, parseDate} from "../../util/FormatUtil";
import {ModalBodyProps} from "./Modals";

export default class BackOrderModal extends React.Component<ModalBodyProps, {}>{

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <Table
                isHorizontal={true}
                columns={[
                    {label: "Customer Number", key: "cust_num"},
                    {label: "Customer Seq.", key: "cust_seq"},
                    {label: "Customer", key:"customer.name", modal_data:this.props.data.customer},
                    {label: "Sch. Fulfillment", raw:this.props.data["Sch. Fulfillment"], formatFunc: parseDate},
                    {label: "Price", key: "Price", formatFunc:formatMoney},
                    {label: "Status", key: "Status"},
                    {label: "Reason", key: "Reason"},
                ]}
                load_modal={this.props.load_modal}
                modal_cb={this.props.modal_cb}
                table_data={this.props.data}
                isMap={true}
                table_style={"table-striped TEXT-left m-0 p-0"}
                header_style={"fw-700"}
                append={<React.Fragment>
                    <th>Items</th>
                    <td className={"p-0 m-0"}>
                        <Table
                            columns={[
                                {label: "Product", key: "Product"},
                                {label: "Qty.", key: "QTY", formatFunc: numberWithCommas},
                                {label: "Unit Price", key: "Unit Price", formatFunc: formatMoney},
                                {label: "Sellable", key: "Sellable", formatFunc: numberWithCommas},
                                {label: "Status", key: "Status"},
                            ]}
                            load_modal={this.props.load_modal}
                            modal_cb={this.props.modal_cb}
                            table_data={this.props.data.items}
                            table_style={"table-striped TEXT-left m-0 p-0 no-border"}
                            header_style={"fw-700"}
                        />
                    </td>
                </React.Fragment>
                }
                />
        )
    }
}