import React from "react";
import Table from "../tables/Table";
import {ModalBodyProps} from "./Modals";
import {formatAddress, formatMoney, parseDate} from "../../util/FormatUtil";
import ComplaintModal from "./ComplaintModal";

export default class BillToModal extends React.Component<ModalBodyProps, {data}>{
    constructor(props) {
        super(props);
        this.state={data:{}}
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let data = this.props.data;
        let address = formatAddress(data);
        console.log(data)
        return (
            <React.Fragment>
            <ComplaintModal data={this.state.data}/>
            <table className="table table-striped text-left m-0 p-0">
                <tbody>
                <tr>
                    <th scope="row">Customer Number</th>
                    <td>{data['cust_num']}</td>
                </tr>
                <tr>
                    <th scope="row">Customer Seq</th>
                    <td>{data.cust_seq}</td>
                </tr>
                <tr>
                    <th scope="row">Address</th>
                    <td>{address}</td>
                </tr>
                <tr>
                    <th scope="row">Credit Limit</th>
                    <td>{formatMoney(data["credit_limit"])}</td>
                </tr>
                <tr>
                    <th scope="row">Order Credit Limit</th>
                    <td>{formatMoney(data["order_credit_limit"])}</td>
                </tr>
                <tr>
                    <th scope="row">Corporate Customer</th>
                    <td><a href={"#!"} onClick={() => {
                        this.props.load_modal({cust_num: data["corp_cust"], cust_seq: 0}, "bill_to")
                    }}>{data["corp_cust"]}</a></td>
                </tr>
                <tr>
                    <th scope="row">Website</th>
                    <td><p><a target="_blank" href={data["internet_url"]}>{data["internet_url"]}</a></p></td>
                </tr>
                <tr>
                    <th scope="row">Ship To Addresses</th>
                    <td>{data["ship_tos"].map((ship_to, index) => (
                        <React.Fragment><a href={"#!"} onClick={() => {
                            this.props.load_modal(ship_to, "ship_to")
                        }}>{ship_to.name}</a> <br/></React.Fragment>
                    ))}</td>
                </tr>
                <th>Contacts</th>
                <td className={"p-0 m-0"}>
                    <Table
                        columns={[
                            {label: "Name", key: "contact.name", modal_key:"contact"},
                            {label: "Title", key: "contact.job_title"},
                            {label: "Email", key: "contact.email"},
                        ]}
                        load_modal={this.props.load_modal}
                        modal_cb={this.props.modal_cb}
                        table_data={data.contacts}
                        table_style={"table-striped text-left m-0 p-0 no-border"}
                        header_style={"fw-700"}
                    />
                </td>
                <tr>
                    <th scope="row">Complaints</th>
                    <td className={"p-0 m-0"}>
                        <Table
                            columns={[
                                {label: "CC Num", key: "ccr_num", isOwnModalData:true, modal_key:"ccr_num"},
                                {label: "Product Line", key: "product_line"},
                                {label: "Originator", key: "sales.name"},
                            ]}
                            load_modal={(data) =>{this.setState({data:data}, () => ComplaintModal.display())}}
                            modal_cb={this.props.load_modal}
                            table_data={data.complaints}
                            table_style={"table-striped text-left m-0 p-0 no-border"}
                            header_style={"fw-700"}
                        />
                    </td>
                </tr>
                <tr>
                    <th scope="row">Interactions</th>
                    <td className={"p-0 m-0"}>
                        <Table
                            columns={[
                                {label: "Sales Person", key: "slsman"},
                                {label: "Description", key: "description"},
                                {label: "Topic", key: "topic"},
                                {label: "Contact", key: "contact_data"},
                                {label: "Phone", key: "phone"},
                                {label: "Interaction Date", key: "interaction_date", formatFunc:parseDate},
                                {label: "Follow-up Date", key: "follow_date",  formatFunc:parseDate},

                            ]}
                            load_modal={(data) =>{this.setState({data:data})}}
                            modal_cb={this.props.load_modal}
                            table_data={data.interactions}
                            table_style={"table-striped text-left m-0 p-0 no-border"}
                            header_style={"fw-700"}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            </React.Fragment>)
    }
}