import React from 'react';
import {formatMoney, parseDate} from "../../util/FormatUtil";
import {buildDisplayString} from "./TableUtil";
import TableBase, {SimpleTableComponentProps} from "./TableBase";
import SimpleHeader from "./SimpleHeader";
import SimpleBody from "./SimpleBody";
interface SimpleTableState{

}


interface SimpleTableProps extends SimpleTableComponentProps{
    prependRows?:string
    appendRows?:any
    table_style?:string
}

class SimpleTable extends React.Component<SimpleTableProps, SimpleTableState> {

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
         return (
             <TableBase table_style={this.props.table_style ? this.props.table_style : null}>
                 <SimpleHeader columns={this.props.columns}/>
                 <SimpleBody appendRows={this.props.appendRows} rowBuilder={this.props.rowBuilder} columns={this.props.columns} table_data={this.props.table_data}/>
             </TableBase>
         );
    }
}
export default SimpleTable;