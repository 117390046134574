import React from "react";
import Select from "react-select";
import {parseDate} from "../../util/FormatUtil";
import moment from "moment";
import domain from "../../Domain";
import {EquipmentRecord} from "../../types/EquipmentRecord";
import {TrainingModules} from "../immytrack/TrainingModules";
import {Course, TrainingType} from "../../types/TrainingTypes";
import {Training} from "../immytrack/Training";
import {Employee} from "../../types/Employee";

interface EditProps{
    onSubmit
    training:TrainingType
    employees: {label:string, value:string } []
    verification: {label:string, value: string } []
}

export class TrainingEditModal extends React.Component<EditProps, any>{
    constructor(props) {
        super(props);
        this.state = {Uf_IMYConseqError: false}
        this.getAdjustedField = this.getAdjustedField.bind(this);

    }

    getTrueFalseWrap(value){
        return {label:value ? "True" : "False", value: !!value}
    }

    getSelectWrap(value){
        return value ? {label:value, value:value} : null;
    }


    componentWillReceiveProps(nextProps: Readonly<EditProps>, nextContext: any) {
        let keys = Object.keys(this.state);
        let obj = JSON.parse(JSON.stringify(this.state));

        keys.forEach(k => obj[k] = '');
        console.log(nextProps)
        let equipRecord:TrainingType = nextProps.training ? JSON.parse(JSON.stringify(nextProps.training)) : {};
        console.log(equipRecord)
        if(equipRecord) {
            equipRecord.start_date = equipRecord.start_date ? parseDate(equipRecord.start_date, true) : null;
            equipRecord.end_date = equipRecord.end_date ? parseDate(equipRecord.end_date, true) : null;
            console.log(this.props.employees)
            console.log(this.props.verification)

            if(equipRecord.Uf_IMYValidation_Method){
                console.log(equipRecord.Uf_IMYValidation_Method)
                let ver = this.props.verification.find(e => e.value === equipRecord.Uf_IMYValidation_Method.trim());
                if(ver)
                    equipRecord.Uf_IMYValidation_Method = ver;
                console.log(equipRecord.Uf_IMYValidation_Method)
            }

            if(equipRecord.instructor){
                console.log(equipRecord.instructor)
                let emp = this.props.employees.find(e => e.value === equipRecord.instructor.trim());
                if(emp)
                    equipRecord.instructor = emp;
                console.log(equipRecord.instructor)
            }
            console.log(equipRecord.Uf_IMYValidation_Method);
        }
        if(equipRecord.Uf_IMYConseqError === 1) {
            equipRecord.Uf_IMYConseqError = [{id: "1", value: "1", label: "Yes"}]
        }
        else{
            equipRecord.Uf_IMYConseqError = [{id: "2", value: "0", label: "No"}]
        }


        this.setState(obj, () => this.setState(equipRecord));


    }

    getReadOnly(key:string, display:string){
        return this.getAdjustedField(key,display,true)
    }

    getMappedLots(arr){
        return arr.map(a => ({label:a.Lot, value:a.Lot}));
    }

    getStateObj(key, val){
        let obj = {};
        obj[key] = val;
        console.log(obj)
        return obj;
    }

    getField(key:string, display:string){
        return this.getAdjustedField(key,display,false);
    }

    getSelectField(key:string, label:string, options, isMulti){
        console.log(this.state[key])
        return(
            <div className="form-group row">
                <label className={"col-sm-2 col-form-label"}>{label}</label>
                <div className={"col-sm-8 p-0 m-0 "}>

                    <Select
                        onChange={(e)=> {
                            this.setState(this.getStateObj(key, e))}}
                        className={"state_select"}
                        isMulti={isMulti}
                        value={this.state[key]}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    getVerificationField(key:string, label:string, options, isMulti){

        return(
            <div className="form-group row">
                <label className={"col-sm-2 col-form-label"}>{label}</label>
                <div className={"col-sm-8 p-0 m-0 "}>

                    <Select
                        onChange={(e)=> {
                            this.setState(this.getStateObj(key, e))}}
                        className={"state_select"}
                        isMulti={isMulti}
                        value={this.state[key]}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    getNotes(key, display){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <textarea cols={3} className="form-control"
                          value={this.state[key]}
                          autoComplete="off"
                          onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }

    getConseqError(key:string, label:string, options, isMulti){
        return(
            <div className="form-group row">
                <label className={"col-sm-2 col-form-label"}>{label}</label>
                <div className={"col-sm-8 p-0 m-0 "}>

                    <Select
                        onChange={(e)=> {
                            this.setState(this.getStateObj(key, e))}}
                        className={"state_select"}
                        isMulti={isMulti}
                        value={this.state[key]}
                        options={options}
                    />
                </div>
            </div>
        )
    }

    getTextArea(key, display){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <textarea cols={3} className="form-control"
                          value={this.state[key]}
                          autoComplete="off"
                          onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }
    getAdjustedField(key, display, readOnly){
        return (<div className="form-group row">
            <label  className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8 p-0 m-0">
                <input type={"search"} className="form-control"
                       value={this.state[key]}
                       autoComplete="off"
                       readOnly={readOnly}
                       onChange={(e) => this.setState(this.getStateObj(key, e.target.value))}/>
            </div>
        </div>)
    }

    submitForm(){
        if(!this.state.start_date){
            return alert("Please complete Start Date")
        }
        let obj = JSON.parse(JSON.stringify(this.state));
        obj.instructor = obj.instructor ? obj.instructor.value : null;
        obj.Uf_IMYValidation_Method = obj.Uf_IMYValidation_Method ? obj.Uf_IMYValidation_Method.value : null;
        console.log(this.state);
        TrainingEditModal.hide();
        this.props.onSubmit(JSON.parse(JSON.stringify(obj)));
    }

    buildDate(key, display){
        return(<div className="form-group row">
            <label className="col-sm-2 col-form-label">{display}</label>
            <div className="col-sm-8  p-0 m-0">
                <input type="date" name="start" max="3000-12-31"
                       value={this.state[key]}
                       min="1000-01-01" className="form-control datepicker" onChange={(e)=>  this.setState(this.getStateObj(key, e.target.value))}/>
            </div></div>)
    }


    public static display() {
        window["$"]("#" + TrainingEditModal.ID).modal('show');
        console.log("display")
    }
    public static hide() {
        window["$"]("#" + TrainingEditModal.ID).modal('hide');
    }
    public static readonly ID = "trainingEdit"

    render() {

        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={TrainingEditModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">{"Training Record"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                {this.getSelectField("instructor", "Instructor", this.props.employees, false)}
                                {this.getVerificationField("Uf_IMYValidation_Method", "Verification of Effectiveness Method", this.props.verification, false)}
                                {this.buildDate("start_date", "Start")}
                                {this.buildDate("end_date", "Completed")}
                                {this.getNotes("Uf_IMYMiscNotes", "Notes")}
                                {this.getConseqError("Uf_IMYConseqError", "Does the employee understand the importance, relevancy, and consequences of error?", [
                                    { id: "1", value: "1", label: "Yes" },
                                    { id: "2", value: "0", label: "No" }], false)}



                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={(e) =>
                                    this.submitForm()}>
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}