
import React from "react";
import domain from "../../Domain";
import FilterCard, {FIELD_TYPE} from "../FilterCard";

class PodSelect extends React.Component<{onChange:any, multi?, active?, vaccineOnly?}, {pods?:any[], selectedPod?:any}> {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        fetch(domain + '/api/forms/pods' + (this.props.active ? "?active=" + this.props.active : "") + (this.props.vaccineOnly ? "?vaccine=" + this.props.vaccineOnly : "")).then(res => res.json()).then(data => {
             // + this.props.active ? "?active=" + this.props.active : ""
            if (data.authenticated === false) {
                console.log("not auth")
                return;
            }
            this.setState({pods: data})
        });
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
                <FilterCard fields={[{
                    label: "Pod",
                    key: "pod",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.pods,
                    isMapped: true,
                    isMulti: true
                }]} filterChanged={(e) => this.setState({selectedPod: e.pod}, () => this.props.onChange(e))}/>
            </React.Fragment>
        )
    }
}
export default PodSelect