import React from "react";
import domain from "../../Domain";
import {FIELD_TYPE} from "../FilterCard";
import ModalForm from "./ModalForm";



interface ContactFormState{

}
interface ContactFormProps{
    customer_data?
    ID
    completeCallback
}

class ContactForm extends React.Component<ContactFormProps, ContactFormState>{
    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
    }

    public static display(){
        window['$']("#contact_form").modal('show')
    }

    componentDidMount() {
        fetch(domain + "/api/location/all").then(res => res.json())
            .then(data => this.setState({
                location_filters: {
                    states: data.states,
                    countries: data.countries
                },
            }))
            .catch(e => console.log(e));
    }

    onSubmit(contact){
        if(!contact){
            contact = {};
        }
        let data = this.props.customer_data ? this.props.customer_data : {};
        contact.state = data.state ? data.state : null;
        contact.country = data.country ? data.country : null;
        if(!contact.lname || contact.lname.trim() === ""){
            alert("Last name is required.")
            return;
        }
        if(!contact.fname || contact.fname.trim() === ""){
            alert("First name is required.")
            return;
        }
        if(!contact.job_title || contact.job_title.trim() === ""){
            alert("Job title is required.")
            return;
        }
        fetch(domain + "/api/contact", {
            method: 'POST',
            body: JSON.stringify({previous_form:this.props.customer_data, contact_data:contact}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>{
            if(response.status === 200){
                let id = this.props.ID;
                window['$']("#" + id).modal('hide');
                if(this.props.customer_data.type === "bill_to" || this.props.customer_data.type === "ship_to")
                    this.props.completeCallback({...this.props.customer_data, ...contact});

                alert("Added contact. It can take several minutes before it will appear.")
            }else{
                console.error(response)
                alert("Failed to upload contact.")
            }
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({customer_data:nextProps.customer_data})
    }

    render() {
        let data = this.props.customer_data ? this.props.customer_data : {};
        return (
            <ModalForm
                key={this.props.customer_data ? this.props.customer_data.name : "contactForm"}
                fields={[
                    {key: "fname", label: "First Name", type: FIELD_TYPE.TEXT, default:data["fname"]},
                    {key: "lname", label: "Last Name", type: FIELD_TYPE.TEXT, default:data["lname"]},
                    {key: "job_title", label: "Job title",  type: FIELD_TYPE.TEXT, default:data['job_title']},
                    {key: "addr1", label: "Address",type: FIELD_TYPE.TEXT, default:data["addr##1"]},
                    {key: "addr2", label: "Address 2",  type: FIELD_TYPE.TEXT, default:data["addr##2"]},
                    {key: "addr3", label: "Address 3",  type: FIELD_TYPE.TEXT, default:data["addr##3"]},
                    {key: "addr4", label: "Address 4", type: FIELD_TYPE.TEXT, default:data["addr##4"]},
                    {key: "city", label: "City", type: FIELD_TYPE.TEXT, default:data["city"]},
                    // {key: "state", label: "State", type: FIELD_TYPE.TEXT},
                    {key: "zip", label: "Zip",  type: FIELD_TYPE.TEXT, default:data.zip},
                    // {key: "country", label: "Country",  type: FIELD_TYPE.TEXT},
                    {key: "office_phone", label: "Office #", type: FIELD_TYPE.TEXT, default:data["office_phone"]},
                    {key: "mobile_phone", label: "Mobile #",  type: FIELD_TYPE.TEXT, default:data['mobile_phone']},
                    {key: "fax_num", label: "Fax #",  type: FIELD_TYPE.TEXT, default:data['fax_num']},
                    {key: "email", label: "Email",  type: FIELD_TYPE.TEXT, default:data.email},

                ]}
                ID={this.props.ID}
                title={"Contact Form"}
                onSubmit={this.onSubmit}
            />)
    }
}

export default ContactForm;